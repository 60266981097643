import React from 'react';
import '../../containers/MarketPlace/index.css';
import '../../containers/SingleAsset/index.css';
import ImageSkeletonLoader from '../SkeletonLoader/SingleAsset/ImageSkeletonLoader';
import CardSkeletonLoader from '../SkeletonLoader/SingleAsset/CardSkeletonLoader';

const NFT = () => {
    return (
        <div className="single_asset scroll_bar">
            <ImageSkeletonLoader/>
            <CardSkeletonLoader/>
        </div>
    );
};

export default NFT;
