import { combineReducers } from 'redux';
import {
    BURN_NFT_S_CLAIM_LIST_SET,
    CAMPAIGNS_FLIX_DROP_FETCH_ERROR,
    CAMPAIGNS_FLIX_DROP_FETCH_IN_PROGRESS,
    CAMPAIGNS_FLIX_DROP_FETCH_SUCCESS,
    CLAIM_ALL_DIALOG_HIDE,
    CLAIM_ALL_DIALOG_SHOW,
    CLAIM_ALL_NFT_S_LIST_SET,
    CLAIM_ALL_NFT_S_LISTING_FETCH_ERROR,
    CLAIM_ALL_NFT_S_LISTING_FETCH_IN_PROGRESS,
    CLAIM_ALL_NFT_S_LISTING_FETCH_SUCCESS,
    CLAIM_DIALOG_HIDE,
    CLAIM_DIALOG_SHOW,
    DE_LIST_NFT_S_CLAIM_SET,
    EMPTY_CAMPAIGN_DETAILS_SINGLE_PAGE_SET,
    ITC_ALL_CAMPAIGNS_FETCH_ERROR,
    ITC_ALL_CAMPAIGNS_FETCH_IN_PROGRESS,
    ITC_ALL_CAMPAIGNS_FETCH_SUCCESS,
    ITC_ALL_LIST_ERROR,
    ITC_ALL_LIST_IN_PROGRESS,
    ITC_ALL_LIST_SUCCESS,
    ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_ERROR,
    ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_IN_PROGRESS,
    ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_SUCCESS,
    ITC_CAMPAIGN_DETAILS_FETCH_ERROR,
    ITC_CAMPAIGN_DETAILS_FETCH_IN_PROGRESS,
    ITC_CAMPAIGN_DETAILS_FETCH_SUCCESS,
    ITC_CAMPAIGN_NFT_S_LISTING_FETCH_ERROR,
    ITC_CAMPAIGN_NFT_S_LISTING_FETCH_IN_PROGRESS,
    ITC_CAMPAIGN_NFT_S_LISTING_FETCH_SUCCESS,
    ITC_CAMPAIGN_STATS_FETCH_ERROR,
    ITC_CAMPAIGN_STATS_FETCH_IN_PROGRESS,
    ITC_CAMPAIGN_STATS_FETCH_SUCCESS,
    ITC_CAMPAIGN_VIEW_SET,
    ITC_CAMPAIGNS_LISTING_FETCH_ERROR,
    ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS,
    ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS,
    ITC_CAMPAIGNS_TAB_SET,
    ITC_CREATED_LIST_ERROR,
    ITC_CREATED_LIST_IN_PROGRESS,
    ITC_CREATED_LIST_SUCCESS,
    ITC_CURATED_LIST_ERROR,
    ITC_CURATED_LIST_IN_PROGRESS,
    ITC_CURATED_LIST_SUCCESS,
    ITC_DEPOSIT_AMOUNT_SET,
    ITC_DEPOSIT_DIALOG_HIDE,
    ITC_DEPOSIT_DIALOG_SHOW,
    ITC_DEPOSIT_TOKEN_SET,
    ITC_ELIGIBLE_CAMPAIGNS_FETCH_ERROR,
    ITC_ELIGIBLE_CAMPAIGNS_FETCH_IN_PROGRESS,
    ITC_ELIGIBLE_CAMPAIGNS_FETCH_SUCCESS,
    ITC_ELIGIBLE_LIST_ERROR,
    ITC_ELIGIBLE_LIST_IN_PROGRESS,
    ITC_ELIGIBLE_LIST_SUCCESS,
    ITC_FLIX_DROP_LIST_ERROR,
    ITC_FLIX_DROP_LIST_IN_PROGRESS,
    ITC_FLIX_DROP_LIST_SUCCESS,
    ITC_MY_CAMPAIGNS_FETCH_ERROR,
    ITC_MY_CAMPAIGNS_FETCH_IN_PROGRESS,
    ITC_MY_CAMPAIGNS_FETCH_SUCCESS,
    ITC_SINGLE_CAMPAIGN_TABS_SET,
    ITC_STATS_FETCH_ERROR,
    ITC_STATS_FETCH_IN_PROGRESS,
    ITC_STATS_FETCH_SUCCESS,
    ITC_STOP_DIALOG_HIDE,
    ITC_STOP_DIALOG_SHOW,
    STREAM_PAY_CLAIMABLE_TOKENS_FETCH_IN_PROGRESS,
    STREAM_PAY_CLAIMABLE_TOKENS_FETCH_SUCCESS,
    STREAM_PAY_CLAIMABLE_TOKENS_FETCH_ERROR,
} from '../constants/itcModule';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TABLE_LIMIT } from '../config';
import { DISCONNECT_SET } from '../constants/account';

const claimDialog = (state = {
    open: false,
    value: {},
    success: false,
    hash: '',
    streamPayID: null,
}, action) => {
    switch (action.type) {
    case CLAIM_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
            success: action.success,
            hash: action.hash,
            streamPayID: action.streamPayID,
        };
    case CLAIM_DIALOG_HIDE:
        return {
            open: false,
            value: {},
            success: false,
            hash: '',
            streamPayID: null,
        };

    default:
        return state;
    }
};

const burnClaimList = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case BURN_NFT_S_CLAIM_LIST_SET:
        return {
            value: action.value,
        };
    case CLAIM_DIALOG_HIDE:
        return {
            value: [],
        };

    default:
        return state;
    }
};

const itcListings = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case ITC_CAMPAIGNS_LISTING_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcNFTsListings = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGN_NFT_S_LISTING_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGN_NFT_S_LISTING_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    }
    case ITC_CAMPAIGN_NFT_S_LISTING_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case EMPTY_CAMPAIGN_DETAILS_SINGLE_PAGE_SET:
        return {
            ...state,
            result: [],
        };
    default:
        return state;
    }
};

const itcCampaign = (state = {
    result: {},
    inProgress: false,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGN_DETAILS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGN_DETAILS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
        };
    }
    case ITC_CAMPAIGN_DETAILS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case EMPTY_CAMPAIGN_DETAILS_SINGLE_PAGE_SET: {
        return {
            ...state,
            result: {},
        };
    }
    default:
        return state;
    }
};

const itcCampaignView = (state = {
    value: 'grid',
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGN_VIEW_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const itcCampaignStats = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGN_STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGN_STATS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case ITC_CAMPAIGN_STATS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case EMPTY_CAMPAIGN_DETAILS_SINGLE_PAGE_SET:
        return {
            ...state,
            result: [],
        };
    default:
        return state;
    }
};

const itcCampaignsTab = (state = {
    value: 'curated',
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGNS_TAB_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const itcMyCampaigns = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_MY_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_MY_CAMPAIGNS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case ITC_MY_CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcEligibleCampaigns = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_ELIGIBLE_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_ELIGIBLE_CAMPAIGNS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case ITC_ELIGIBLE_CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcAllCampaigns = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_ALL_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_ALL_CAMPAIGNS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case ITC_ALL_CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcDepositDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_DEPOSIT_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case ITC_DEPOSIT_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const itcStopDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_STOP_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case ITC_STOP_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };
    default:
        return state;
    }
};

const itcDepositAmount = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ITC_DEPOSIT_AMOUNT_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const itcDepositToken = (state = {
    value: '',
}, action) => {
    switch (action.type) {
    case ITC_DEPOSIT_TOKEN_SET:
        return {
            value: action.value,
        };

    default:
        return state;
    }
};

const itcStats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case ITC_STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            value: {},
        };
    case ITC_STATS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case ITC_STATS_FETCH_ERROR:
        return {
            ...state,
            value: {},
        };
    default:
        return state;
    }
};

const itcSingleCampaignTab = (state = {
    value: 'eligible',
}, action) => {
    switch (action.type) {
    case ITC_SINGLE_CAMPAIGN_TABS_SET:
        return {
            value: action.value,
        };
    default:
        return state;
    }
};

const itcClaimedNFTs = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLAIM_DIALOG_HIDE:
        return {
            ...state,
            result: [],
        };
    default:
        return state;
    }
};

const deListClaimList = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case DE_LIST_NFT_S_CLAIM_SET:
        return {
            value: action.value,
        };
    case CLAIM_DIALOG_HIDE:
        return {
            value: [],
        };

    default:
        return state;
    }
};

const campaignsFLIXDrops = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case CAMPAIGNS_FLIX_DROP_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGNS_FLIX_DROP_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case CAMPAIGNS_FLIX_DROP_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const claimAllDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CLAIM_ALL_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case CLAIM_ALL_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

const claimAllList = (state = {
    value: [],
}, action) => {
    switch (action.type) {
    case CLAIM_ALL_NFT_S_LIST_SET:
        return {
            value: action.value,
        };
    case CLAIM_ALL_DIALOG_HIDE:
        return {
            value: [],
        };

    default:
        return state;
    }
};

const claimAllNFTsListings = (state = {
    inProgress: false,
    result: [],
    value: {},
}, action) => {
    switch (action.type) {
    case CLAIM_ALL_NFT_S_LISTING_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            result: [],
        };
    case CLAIM_ALL_NFT_S_LISTING_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            value: action.result,
        };
    }
    case CLAIM_ALL_NFT_S_LISTING_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            result: [],
        };
    case DISCONNECT_SET:
        return {
            ...state,
            result: [],
            value: {},
        };
    default:
        return state;
    }
};

// ITC Listings
const itcFlixDropList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_TABLE_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_FLIX_DROP_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_FLIX_DROP_LIST_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_FLIX_DROP_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcCuratedList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_TABLE_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CURATED_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CURATED_LIST_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_CURATED_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const itcEligibleList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_TABLE_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_ELIGIBLE_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_ELIGIBLE_LIST_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_ELIGIBLE_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};
const itcCreatedList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_TABLE_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_CREATED_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_CREATED_LIST_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_CREATED_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};
const itcAllList = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_TABLE_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ITC_ALL_LIST_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ITC_ALL_LIST_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case ITC_ALL_LIST_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const streamPayClaimableTokens = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case STREAM_PAY_CLAIMABLE_TOKENS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STREAM_PAY_CLAIMABLE_TOKENS_FETCH_SUCCESS:
        return {
            ...state,
            inProgress: false,
            value: action.value,
        };
    case STREAM_PAY_CLAIMABLE_TOKENS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    claimDialog,
    burnClaimList,
    itcListings,
    itcNFTsListings,
    itcCampaign,
    itcCampaignView,
    itcCampaignStats,
    itcCampaignsTab,

    itcMyCampaigns,
    itcEligibleCampaigns,
    itcAllCampaigns,
    itcDepositDialog,
    itcStopDialog,
    itcDepositAmount,
    itcDepositToken,

    itcStats,
    itcSingleCampaignTab,
    itcClaimedNFTs,
    deListClaimList,

    campaignsFLIXDrops,
    claimAllDialog,
    claimAllList,
    claimAllNFTsListings,

    itcFlixDropList,
    itcCuratedList,
    itcEligibleList,
    itcCreatedList,
    itcAllList,

    streamPayClaimableTokens,
});
