import React from 'react';
import NavBar from './NavBar';
import * as PropTypes from 'prop-types';
import MarketPlace from './MarketPlace';
import Collections from './Collections';
import MyAccount from './MyAccount';
import SingleCollection from './SingleCollection';
import NFT from './NFT';
import Home from './Home';
import ITCCampaigns from './ITCCampaigns';
import withRouter from '../WithRouter';

const RouterSkeletonLoader = (props) => {
    let pathname = props.router && props.router.location && props.router.location.pathname &&
        props.router.location.pathname.split('/');
    const pathname2 = pathname && pathname.length && pathname[2];
    const pathname3 = pathname && pathname.length && pathname[3];
    pathname = pathname && pathname.length && pathname[1];

    return (
        <>
            <NavBar/>
            <div className="content_div">
                {pathname === 'nfts'
                    ? <MarketPlace/>
                    : pathname === 'collections'
                        ? <Collections/>
                        : pathname === 'account'
                            ? <MyAccount/>
                            : pathname === 'c' && pathname3
                                ? <NFT/>
                                : pathname === 'c'
                                    ? <SingleCollection/>
                                    : pathname === 'campaigns' && (pathname2 === 'all' || pathname2 === 'eligible' ||
                                        pathname2 === 'created' || (pathname2 && pathname2.toLowerCase()) === 'flixdrop')
                                        ? <ITCCampaigns/>
                                        : pathname === 'home' || pathname === ''
                                            ? <Home/>
                                            : null}
            </div>
        </>
    );
};

RouterSkeletonLoader.propTypes = {
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

export default withRouter(RouterSkeletonLoader);
