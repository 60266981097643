import {
    STATS_URL,
    urlBidsAuction,
    urlFeaturedCollections,
    urlLaunchpadCollections,
    urlLiveLaunchpadCollections,
    urlRecentAuction,
    urlTopCampaigns,
    urlTopListings,
    urlUpcomingAuction,
    flixFridayBannerUrl,
    STATS_MARKET_URL,
} from '../constants/url';
import Axios from 'axios';
import {
    BIDS_AUCTIONS_FETCH_ERROR,
    BIDS_AUCTIONS_FETCH_IN_PROGRESS,
    BIDS_AUCTIONS_FETCH_SUCCESS,
    FEATURED_COLLECTIONS_FETCH_ERROR,
    FEATURED_COLLECTIONS_FETCH_IN_PROGRESS,
    FEATURED_COLLECTIONS_FETCH_SUCCESS,
    FETCH_LAUNCHPAD_COLLECTIONS_ERROR,
    FETCH_LAUNCHPAD_COLLECTIONS_IN_PROGRESS,
    FETCH_LAUNCHPAD_COLLECTIONS_SUCCESS,
    FETCH_STATS_ERROR,
    FETCH_STATS_IN_PROGRESS,
    FETCH_STATS_SUCCESS,
    FETCH_MARKET_STATS_ERROR,
    FETCH_MARKET_STATS_SUCCESS,
    FETCH_MARKET_STATS_IN_PROGRESS,
    HOME_AUCTIONS_TAB_SET,
    LAUNCHPAD_COLLECTION_SEARCH_SET,
    LAUNCHPAD_TAB_SET,
    LIVE_LAUNCHPAD_COLLECTIONS_FETCH_ERROR,
    LIVE_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS,
    LIVE_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS,
    RECENT_AUCTIONS_FETCH_ERROR,
    RECENT_AUCTIONS_FETCH_IN_PROGRESS,
    RECENT_AUCTIONS_FETCH_SUCCESS,
    TOP_CAMPAIGNS_FETCH_ERROR,
    TOP_CAMPAIGNS_FETCH_IN_PROGRESS,
    TOP_CAMPAIGNS_FETCH_SUCCESS,
    TOP_LISTINGS_FETCH_ERROR,
    TOP_LISTINGS_FETCH_IN_PROGRESS,
    TOP_LISTINGS_FETCH_SUCCESS,
    UN_VERIFIED_COLLECTION_SEARCH_SET,
    UPCOMING_AUCTIONS_FETCH_ERROR,
    UPCOMING_AUCTIONS_FETCH_IN_PROGRESS,
    UPCOMING_AUCTIONS_FETCH_SUCCESS,
    UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_ERROR,
    UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS,
    UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS,
    FLIX_FRIDAY_BANNER_FETCH_IN_PROGRESS,
    FLIX_FRIDAY_BANNER_FETCH_SUCCESS,
    FLIX_FRIDAY_BANNER_FETCH_ERROR,
} from '../constants/home';

export const setLaunchpadTab = (value) => {
    return {
        type: LAUNCHPAD_TAB_SET,
        value,
    };
};

const fetchFeaturedCollectionsInProgress = () => {
    return {
        type: FEATURED_COLLECTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchFeaturedCollectionsSuccess = (result) => {
    return {
        type: FEATURED_COLLECTIONS_FETCH_SUCCESS,
        result,
    };
};

const fetchFeaturedCollectionsError = (message) => {
    return {
        type: FEATURED_COLLECTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchFeaturedCollections = () => (dispatch) => {
    dispatch(fetchFeaturedCollectionsInProgress());

    const URL = urlFeaturedCollections();
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        dispatch(fetchFeaturedCollectionsSuccess(res.data && res.data.result && res.data.result.list));
    }).catch((error) => {
        dispatch(fetchFeaturedCollectionsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchLaunchpadCollectionsInProgress = () => {
    return {
        type: FETCH_LAUNCHPAD_COLLECTIONS_IN_PROGRESS,
    };
};

const fetchLaunchpadCollectionsSuccess = (value, skip, limit, total) => {
    return {
        type: FETCH_LAUNCHPAD_COLLECTIONS_SUCCESS,
        value,
        skip,
        limit,
        total,
    };
};

const fetchLaunchpadCollectionsError = (message) => {
    return {
        type: FETCH_LAUNCHPAD_COLLECTIONS_ERROR,
        message,
    };
};

export const fetchLaunchpadCollections = (address, skip, limit, searchKey) => (dispatch) => {
    dispatch(fetchLaunchpadCollectionsInProgress());

    const url = urlLaunchpadCollections(address, skip, limit, searchKey);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchLaunchpadCollectionsSuccess(res.data && res.data.result && res.data.result.list,
                skip, limit, res && res.data && res.data.result && res.data.result.count));
        })
        .catch((error) => {
            dispatch(fetchLaunchpadCollectionsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

export const setSearchLaunchpadCollection = (value) => {
    return {
        type: LAUNCHPAD_COLLECTION_SEARCH_SET,
        value,
    };
};

export const setSearchUnVerifiedCollection = (value) => {
    return {
        type: UN_VERIFIED_COLLECTION_SEARCH_SET,
        value,
    };
};

const fetchStatsSuccess = (result) => {
    return {
        type: FETCH_STATS_SUCCESS,
        result,
    };
};

const fetchStatsError = (message) => {
    return {
        type: FETCH_STATS_ERROR,
        message,
    };
};

const fetchStatsInProgress = () => {
    return {
        type: FETCH_STATS_IN_PROGRESS,
    };
};

export const fetchStats = () => (dispatch) => {
    dispatch(fetchStatsInProgress());

    Axios.get(STATS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchStatsSuccess(res.data && res.data.result));
        }
    }).catch((error) => {
        dispatch(fetchStatsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchMarketStatsInProgress = () => {
    return {
        type: FETCH_MARKET_STATS_IN_PROGRESS,
    };
};

const fetchMarketStatsSuccess = (result) => {
    return {
        type: FETCH_MARKET_STATS_SUCCESS,
        result,
    };
};

const fetchMarketStatsError = (message) => {
    return {
        type: FETCH_MARKET_STATS_ERROR,
        message,
    };
};

export const fetchMarketStats = () => (dispatch) => {
    dispatch(fetchMarketStatsInProgress());

    Axios.get(STATS_MARKET_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchMarketStatsSuccess(res.data && res.data.result));
        }
    }).catch((error) => {
        dispatch(fetchMarketStatsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

export const setHomeAuctionsTab = (value) => {
    return {
        type: HOME_AUCTIONS_TAB_SET,
        value,
    };
};

const fetchRecentAuctionsInProgress = () => {
    return {
        type: RECENT_AUCTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchRecentAuctionsSuccess = (result) => {
    return {
        type: RECENT_AUCTIONS_FETCH_SUCCESS,
        result,
    };
};

const fetchRecentAuctionsError = (message) => {
    return {
        type: RECENT_AUCTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchRecentAuctions = () => (dispatch) => {
    dispatch(fetchRecentAuctionsInProgress());

    const URL = urlRecentAuction();
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        dispatch(fetchRecentAuctionsSuccess(res.data && res.data.result && res.data.result.list));
    }).catch((error) => {
        dispatch(fetchRecentAuctionsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchBidsAuctionsInProgress = () => {
    return {
        type: BIDS_AUCTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchBidsAuctionsSuccess = (result) => {
    return {
        type: BIDS_AUCTIONS_FETCH_SUCCESS,
        result,
    };
};

const fetchBidsAuctionsError = (message) => {
    return {
        type: BIDS_AUCTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchBidsAuctions = () => (dispatch) => {
    dispatch(fetchBidsAuctionsInProgress());

    const URL = urlBidsAuction();
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        dispatch(fetchBidsAuctionsSuccess(res.data && res.data.result && res.data.result.list));
    }).catch((error) => {
        dispatch(fetchBidsAuctionsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchUpcomingAuctionsInProgress = () => {
    return {
        type: UPCOMING_AUCTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchUpcomingAuctionsSuccess = (result) => {
    return {
        type: UPCOMING_AUCTIONS_FETCH_SUCCESS,
        result,
    };
};

const fetchUpcomingAuctionsError = (message) => {
    return {
        type: UPCOMING_AUCTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchUpcomingAuctions = () => (dispatch) => {
    dispatch(fetchUpcomingAuctionsInProgress());

    const URL = urlUpcomingAuction();
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        dispatch(fetchUpcomingAuctionsSuccess(res.data && res.data.result && res.data.result.list));
    }).catch((error) => {
        dispatch(fetchUpcomingAuctionsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchTopListingsInProgress = () => {
    return {
        type: TOP_LISTINGS_FETCH_IN_PROGRESS,
    };
};

const fetchTopListingsSuccess = (result) => {
    return {
        type: TOP_LISTINGS_FETCH_SUCCESS,
        result,
    };
};

const fetchTopListingsError = (message) => {
    return {
        type: TOP_LISTINGS_FETCH_ERROR,
        message,
    };
};

export const fetchTopListings = () => (dispatch) => {
    dispatch(fetchTopListingsInProgress());

    const URL = urlTopListings();
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        dispatch(fetchTopListingsSuccess(res.data && res.data.result && res.data.result.list));
    }).catch((error) => {
        dispatch(fetchTopListingsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchTopCampaignsInProgress = () => {
    return {
        type: TOP_CAMPAIGNS_FETCH_IN_PROGRESS,
    };
};

const fetchTopCampaignsSuccess = (result) => {
    return {
        type: TOP_CAMPAIGNS_FETCH_SUCCESS,
        result,
    };
};

const fetchTopCampaignsError = (message) => {
    return {
        type: TOP_CAMPAIGNS_FETCH_ERROR,
        message,
    };
};

export const fetchTopCampaigns = () => (dispatch) => {
    dispatch(fetchTopCampaignsInProgress());

    const URL = urlTopCampaigns();
    Axios.get(URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        dispatch(fetchTopCampaignsSuccess(res.data && res.data.result && res.data.result.list));
    }).catch((error) => {
        dispatch(fetchTopCampaignsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchLiveLaunchpadCollectionsInProgress = () => {
    return {
        type: LIVE_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchLiveLaunchpadCollectionsSuccess = (value, total) => {
    return {
        type: LIVE_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS,
        value,
        total,
    };
};

const fetchLiveLaunchpadCollectionsError = (message) => {
    return {
        type: LIVE_LAUNCHPAD_COLLECTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchLiveLaunchpadCollections = () => (dispatch) => {
    dispatch(fetchLiveLaunchpadCollectionsInProgress());

    const url = urlLiveLaunchpadCollections();
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchLiveLaunchpadCollectionsSuccess(res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count));
        })
        .catch((error) => {
            dispatch(fetchLiveLaunchpadCollectionsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchUpcomingLaunchpadCollectionsInProgress = () => {
    return {
        type: UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS,
    };
};

const fetchUpcomingLaunchpadCollectionsSuccess = (value, total) => {
    return {
        type: UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS,
        value,
        total,
    };
};

const fetchUpcomingLaunchpadCollectionsError = (message) => {
    return {
        type: UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_ERROR,
        message,
    };
};

export const fetchUpcomingLaunchpadCollections = () => (dispatch) => {
    dispatch(fetchUpcomingLaunchpadCollectionsInProgress());

    const url = urlLiveLaunchpadCollections();
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchUpcomingLaunchpadCollectionsSuccess(res.data && res.data.result && res.data.result.list,
                res && res.data && res.data.result && res.data.result.count));
        })
        .catch((error) => {
            dispatch(fetchUpcomingLaunchpadCollectionsError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchFlixFridayBannersInProgress = () => {
    return {
        type: FLIX_FRIDAY_BANNER_FETCH_IN_PROGRESS,
    };
};

const fetchFlixFridayBannersuccess = (value, types, statuses) => {
    return {
        type: FLIX_FRIDAY_BANNER_FETCH_SUCCESS,
        value,
        types,
        statuses,
        variant: 'success',
    };
};

const fetchFlixFridayBannersError = (message) => {
    return {
        type: FLIX_FRIDAY_BANNER_FETCH_ERROR,
        message,
        variant: 'error',
    };
};

export const fetchFlixFridayBanners = (types, statuses) => (dispatch) => {
    dispatch(fetchFlixFridayBannersInProgress());

    const url = flixFridayBannerUrl(types, statuses);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Authorization: 'Bearer ' + localStorage.getItem('authorizationToken_nucleusConsole'),
        },
    })
        .then((res) => {
            dispatch(fetchFlixFridayBannersuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchFlixFridayBannersError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
