export const FETCH_LAUNCHPAD_COLLECTIONS_IN_PROGRESS = 'FETCH_LAUNCHPAD_COLLECTIONS_IN_PROGRESS';
export const FETCH_LAUNCHPAD_COLLECTIONS_SUCCESS = 'FETCH_LAUNCHPAD_COLLECTIONS_SUCCESS';
export const FETCH_LAUNCHPAD_COLLECTIONS_ERROR = 'FETCH_LAUNCHPAD_COLLECTIONS_ERROR';

export const LAUNCHPAD_COLLECTION_SEARCH_SET = 'LAUNCHPAD_COLLECTION_SEARCH_SET';
export const UN_VERIFIED_COLLECTION_SEARCH_SET = 'UN_VERIFIED_COLLECTION_SEARCH_SET';

export const FETCH_STATS_IN_PROGRESS = 'FETCH_STATS_IN_PROGRESS';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_ERROR = 'FETCH_STATS_ERROR';

export const FETCH_MARKET_STATS_IN_PROGRESS = 'FETCH_MARKET_STATS_IN_PROGRESS';
export const FETCH_MARKET_STATS_SUCCESS = 'FETCH_MARKET_STATS_SUCCESS';
export const FETCH_MARKET_STATS_ERROR = 'FETCH_MARKET_STATS_ERROR';

export const HOME_AUCTIONS_TAB_SET = 'HOME_AUCTIONS_TAB_SET';

export const RECENT_AUCTIONS_FETCH_IN_PROGRESS = 'RECENT_AUCTIONS_FETCH_IN_PROGRESS';
export const RECENT_AUCTIONS_FETCH_SUCCESS = 'RECENT_AUCTIONS_FETCH_SUCCESS';
export const RECENT_AUCTIONS_FETCH_ERROR = 'RECENT_AUCTIONS_FETCH_ERROR';

export const BIDS_AUCTIONS_FETCH_IN_PROGRESS = 'BIDS_AUCTIONS_FETCH_IN_PROGRESS';
export const BIDS_AUCTIONS_FETCH_SUCCESS = 'BIDS_AUCTIONS_FETCH_SUCCESS';
export const BIDS_AUCTIONS_FETCH_ERROR = 'BIDS_AUCTIONS_FETCH_ERROR';

export const UPCOMING_AUCTIONS_FETCH_IN_PROGRESS = 'UPCOMING_AUCTIONS_FETCH_IN_PROGRESS';
export const UPCOMING_AUCTIONS_FETCH_SUCCESS = 'UPCOMING_AUCTIONS_FETCH_SUCCESS';
export const UPCOMING_AUCTIONS_FETCH_ERROR = 'UPCOMING_AUCTIONS_FETCH_ERROR';

export const TOP_LISTINGS_FETCH_IN_PROGRESS = 'TOP_LISTINGS_FETCH_IN_PROGRESS';
export const TOP_LISTINGS_FETCH_SUCCESS = 'TOP_LISTINGS_FETCH_SUCCESS';
export const TOP_LISTINGS_FETCH_ERROR = 'TOP_LISTINGS_FETCH_ERROR';

export const FEATURED_COLLECTIONS_FETCH_IN_PROGRESS = 'FEATURED_COLLECTIONS_FETCH_IN_PROGRESS';
export const FEATURED_COLLECTIONS_FETCH_SUCCESS = 'FEATURED_COLLECTIONS_FETCH_SUCCESS';
export const FEATURED_COLLECTIONS_FETCH_ERROR = 'FEATURED_COLLECTIONS_FETCH_ERROR';

export const TOP_CAMPAIGNS_FETCH_IN_PROGRESS = 'TOP_CAMPAIGNS_FETCH_IN_PROGRESS';
export const TOP_CAMPAIGNS_FETCH_SUCCESS = 'TOP_CAMPAIGNS_FETCH_SUCCESS';
export const TOP_CAMPAIGNS_FETCH_ERROR = 'TOP_CAMPAIGNS_FETCH_ERROR';

export const LAUNCHPAD_TAB_SET = 'LAUNCHPAD_TAB_SET';

export const LIVE_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS = 'LIVE_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS';
export const LIVE_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS = 'LIVE_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS';
export const LIVE_LAUNCHPAD_COLLECTIONS_FETCH_ERROR = 'LIVE_LAUNCHPAD_COLLECTIONS_FETCH_ERROR';

export const UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS = 'UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS';
export const UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS = 'UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS';
export const UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_ERROR = 'UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_ERROR';

export const FLIX_FRIDAY_BANNER_FETCH_IN_PROGRESS = 'FLIX_FRIDAY_BANNER_FETCH_IN_PROGRESS';
export const FLIX_FRIDAY_BANNER_FETCH_SUCCESS = 'FLIX_FRIDAY_BANNER_FETCH_SUCCESS';
export const FLIX_FRIDAY_BANNER_FETCH_ERROR = 'FLIX_FRIDAY_BANNER_FETCH_ERROR';
