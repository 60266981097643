import React from 'react';
import './index.css';
import { ButtonBase } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

const ITCCardSkeletonLoader = () => {
    return (
        <ButtonBase className="grid_view">
            <div className="image_section">
                <Skeleton
                    animation="wave"
                    className="list_section2 skeleton_loader"
                    variant="rectangular"/>
            </div>
            <div className="details_section">
                <div className="section1">
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={30}
                        width="100%"/>
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={20}
                        width="60%"/>
                </div>
                <div className="section2" style={{ margin: '2px 0' }}>
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={60}
                        width="30%"/>
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={60}
                        width="30%"/>
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={60}
                        width="30%"/>
                </div>
                <div className="actions_section">
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={50}
                        width="100%"/>
                </div>
            </div>
        </ButtonBase>
    );
};

export default ITCCardSkeletonLoader;
