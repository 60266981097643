import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import logo from '../../assets/logo.svg';
import ProfilePopover from './ProfilePopover';
import Tabs from './Tabs';
import { Button } from '@material-ui/core';
import { STUDIO_URL } from '../../config';
import ExpansionButton from './ExpansionButton';
import ClassNames from 'classnames';
import Icon from '../../components/Icon';
import { connect } from 'react-redux';
import { hideSideBar, setPriceMode } from '../../actions/navbar';
import AppsPopover from './AppsPopover';
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '../../constants/seo';
import { Helmet } from 'react-helmet';
import ConnectDialog from './ConnectDialog';
import variables from '../../utils/variables';
import withRouter from '../../components/WithRouter';

const NavBar = (props) => {
    const handleCreate = () => {
        window.open(STUDIO_URL);
        if (props.show) {
            props.hideSideBar();
        }
    };

    const handleClose = () => {
        const path = props.router && props.router.location &&
            props.router.location.pathname && props.router.location.pathname.split('/')[1];
        const nftPath = props.router && props.router.location &&
            props.router.location.pathname && props.router.location.pathname.split('/')[3];
        if ((path === 'account') || (path === 'c' && (!nftPath)) || (path === 'marketplace')) {
            document.body.style.overflow = null;
        }

        props.hideSideBar();
    };

    const handleHome = (event) => {
        event.preventDefault();
        props.router.navigate('/home');
    };

    const handlePriceTab = (value) => {
        props.setPriceMode(value);
        localStorage.setItem('of_market_currency', value);
    };

    return (
        <div className="navbar_class">
            <div className="nav_bar">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{DEFAULT_TITLE}</title>
                    <meta content={DEFAULT_DESCRIPTION} name="description"/>
                </Helmet>
                <Button
                    className="logo_button"
                    href="/home"
                    onClick={handleHome}>
                    <img alt="logo" height="64" src={logo} width="150"/>
                </Button>
                <div className="expansion_div">
                    <AppsPopover/>
                    <ExpansionButton/>
                </div>
                <div className={ClassNames('right_nav', props.show ? 'show' : '')}>
                    <div className="back_button" onClick={props.hideSideBar}>
                        <IconButton
                            onClick={handleClose}>
                            <Icon className="close" icon="close"/>
                        </IconButton>
                    </div>
                    <Tabs router={props.router}/>
                    <AppsPopover/>
                    <Button
                        className="create_button"
                        onClick={handleCreate}>
                        Create
                    </Button>
                    <div className="price_mode">
                        <Button
                            className={props.priceMode === 'crypto' ? 'active_mode' : ''}
                            onClick={() => handlePriceTab('crypto')}>
                            {variables[props.lang].token}
                        </Button>
                        {/* <CustomWidthTooltip title="Coming Soon"> */}
                        <Button
                            className={props.priceMode === 'usd' ? 'active_mode' : ''}
                            onClick={() => handlePriceTab('usd')}>
                            {variables[props.lang].usd}
                        </Button>
                        {/* </CustomWidthTooltip> */}
                    </div>
                    <ProfilePopover/>
                </div>
                <ConnectDialog/>
            </div>
            <ConnectDialog/>
        </div>
    );
};

NavBar.propTypes = {
    hideSideBar: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    priceMode: PropTypes.string.isRequired,
    setPriceMode: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    hideTabs: PropTypes.bool,
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
        navigate: PropTypes.func.isRequired,
        params: PropTypes.shape({
            campaignName: PropTypes.string,
            campaignID: PropTypes.string,
        }).isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        show: state.navbar.show,
        lang: state.language,
        priceMode: state.navbar.priceMode,
    };
};

const actionToProps = {
    hideSideBar,
    setPriceMode,
};

export default withRouter(connect(stateToProps, actionToProps)(NavBar));
