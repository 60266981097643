import React from 'react';
import SkeletonLoader from '../SkeletonLoader';
import '../../containers/MarketPlace/index.css';
import '../../containers/MyAccount/index.css';
import Skeleton from '@mui/material/Skeleton';
import MyAccountSkeletonLoader from '../SkeletonLoader/MyAccount';

const MyAccount = () => {
    return (
        <div className="my_account">
            <MyAccountSkeletonLoader/>
            <div className="horizontal_tabs" style={{ background: '#131618' }}>
                <Skeleton
                    animation="wave"
                    className="skeleton_loader"
                    height={63}
                    style={{ margin: 'auto' }}
                    width="90%"/>
            </div>
            <div className="explore_main">
                <div className="explore scroll_bar" style={{ overflow: 'unset' }}>
                    <div className="explore_header">
                        <Skeleton
                            animation="wave"
                            className="skeleton_loader"
                            height={28}
                            width="30%"/>
                    </div>
                    <div className="owner_nfts lists">
                        <SkeletonLoader type="nft-card"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
