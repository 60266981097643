/* eslint-disable */
import Long from "long";
import * as _m0 from "protobufjs/minimal";
import {Coin} from "../../cosmos/base/v1beta1/coin";
import {Duration} from "../../google/protobuf/duration";
import {Timestamp} from "../../google/protobuf/timestamp";
import {
    ClaimType,
    claimTypeFromJSON,
    claimTypeToJSON,
    Distribution,
    InteractionType,
    interactionTypeFromJSON,
    interactionTypeToJSON,
    NFTDetails,
} from "./itc";

export const protobufPackage = "OmniFlix.itc.v1";

export interface MsgCreateCampaign {
    name: string;
    description: string;
    interaction: InteractionType;
    claimType: ClaimType;
    nftDenomId: string;
    tokensPerClaim: Coin | undefined;
    maxAllowedClaims: number;
    deposit: Coin | undefined;
    nftMintDetails: NFTDetails | undefined;
    startTime: Date | undefined;
    duration: Duration | undefined;
    distribution: Distribution | undefined;
    creator: string;
    creationFee: Coin | undefined;
}

export interface MsgCreateCampaignResponse {
}

export interface MsgCancelCampaign {
    campaignId: number;
    creator: string;
}

export interface MsgCancelCampaignResponse {
}

export interface MsgClaim {
    campaignId: number;
    nftId: string;
    interaction: InteractionType;
    claimer: string;
}

export interface MsgClaimResponse {
}

export interface MsgDepositCampaign {
    campaignId: number;
    amount: Coin | undefined;
    depositor: string;
}

export interface MsgDepositCampaignResponse {
}

function createBaseMsgCreateCampaign(): MsgCreateCampaign {
    return {
        name: "",
        description: "",
        interaction: 0,
        claimType: 0,
        nftDenomId: "",
        tokensPerClaim: undefined,
        maxAllowedClaims: 0,
        deposit: undefined,
        nftMintDetails: undefined,
        startTime: undefined,
        duration: undefined,
        distribution: undefined,
        creator: "",
        creationFee: undefined,
    };
}

export const MsgCreateCampaign = {
    encode(message: MsgCreateCampaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.name !== "") {
            writer.uint32(10).string(message.name);
        }
        if (message.description !== "") {
            writer.uint32(18).string(message.description);
        }
        if (message.interaction !== 0) {
            writer.uint32(24).int32(message.interaction);
        }
        if (message.claimType !== 0) {
            writer.uint32(32).int32(message.claimType);
        }
        if (message.nftDenomId !== "") {
            writer.uint32(42).string(message.nftDenomId);
        }
        if (message.tokensPerClaim !== undefined) {
            Coin.encode(message.tokensPerClaim, writer.uint32(50).fork()).ldelim();
        }
        if (message.maxAllowedClaims !== 0) {
            writer.uint32(56).uint64(message.maxAllowedClaims);
        }
        if (message.deposit !== undefined) {
            Coin.encode(message.deposit, writer.uint32(66).fork()).ldelim();
        }
        if (message.nftMintDetails !== undefined) {
            NFTDetails.encode(message.nftMintDetails, writer.uint32(74).fork()).ldelim();
        }
        if (message.startTime !== undefined) {
            Timestamp.encode(toTimestamp(message.startTime), writer.uint32(82).fork()).ldelim();
        }
        if (message.duration !== undefined) {
            Duration.encode(message.duration, writer.uint32(90).fork()).ldelim();
        }
        if (message.distribution !== undefined) {
            Distribution.encode(message.distribution, writer.uint32(98).fork()).ldelim();
        }
        if (message.creator !== "") {
            writer.uint32(106).string(message.creator);
        }
        if (message.creationFee !== undefined) {
            Coin.encode(message.creationFee, writer.uint32(114).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgCreateCampaign {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }

                    message.name = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.description = reader.string();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }

                    message.interaction = reader.int32() as any;
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }

                    message.claimType = reader.int32() as any;
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }

                    message.nftDenomId = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }

                    message.tokensPerClaim = Coin.decode(reader, reader.uint32());
                    continue;
                case 7:
                    if (tag !== 56) {
                        break;
                    }

                    message.maxAllowedClaims = longToNumber(reader.uint64() as Long);
                    continue;
                case 8:
                    if (tag !== 66) {
                        break;
                    }

                    message.deposit = Coin.decode(reader, reader.uint32());
                    continue;
                case 9:
                    if (tag !== 74) {
                        break;
                    }

                    message.nftMintDetails = NFTDetails.decode(reader, reader.uint32());
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }

                    message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 11:
                    if (tag !== 90) {
                        break;
                    }

                    message.duration = Duration.decode(reader, reader.uint32());
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }

                    message.distribution = Distribution.decode(reader, reader.uint32());
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }

                    message.creator = reader.string();
                    continue;
                case 14:
                    if (tag !== 114) {
                        break;
                    }

                    message.creationFee = Coin.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): MsgCreateCampaign {
        return {
            name: isSet(object.name) ? String(object.name) : "",
            description: isSet(object.description) ? String(object.description) : "",
            interaction: isSet(object.interaction) ? interactionTypeFromJSON(object.interaction) : 0,
            claimType: isSet(object.claimType) ? claimTypeFromJSON(object.claimType) : 0,
            nftDenomId: isSet(object.nftDenomId) ? String(object.nftDenomId) : "",
            tokensPerClaim: isSet(object.tokensPerClaim) ? Coin.fromJSON(object.tokensPerClaim) : undefined,
            maxAllowedClaims: isSet(object.maxAllowedClaims) ? Number(object.maxAllowedClaims) : 0,
            deposit: isSet(object.deposit) ? Coin.fromJSON(object.deposit) : undefined,
            nftMintDetails: isSet(object.nftMintDetails) ? NFTDetails.fromJSON(object.nftMintDetails) : undefined,
            startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
            duration: isSet(object.duration) ? Duration.fromJSON(object.duration) : undefined,
            distribution: isSet(object.distribution) ? Distribution.fromJSON(object.distribution) : undefined,
            creator: isSet(object.creator) ? String(object.creator) : "",
            creationFee: isSet(object.creationFee) ? Coin.fromJSON(object.creationFee) : undefined,
        };
    },

    toJSON(message: MsgCreateCampaign): unknown {
        const obj: any = {};
        message.name !== undefined && (obj.name = message.name);
        message.description !== undefined && (obj.description = message.description);
        message.interaction !== undefined && (obj.interaction = interactionTypeToJSON(message.interaction));
        message.claimType !== undefined && (obj.claimType = claimTypeToJSON(message.claimType));
        message.nftDenomId !== undefined && (obj.nftDenomId = message.nftDenomId);
        message.tokensPerClaim !== undefined &&
        (obj.tokensPerClaim = message.tokensPerClaim ? Coin.toJSON(message.tokensPerClaim) : undefined);
        message.maxAllowedClaims !== undefined && (obj.maxAllowedClaims = Math.round(message.maxAllowedClaims));
        message.deposit !== undefined && (obj.deposit = message.deposit ? Coin.toJSON(message.deposit) : undefined);
        message.nftMintDetails !== undefined &&
        (obj.nftMintDetails = message.nftMintDetails ? NFTDetails.toJSON(message.nftMintDetails) : undefined);
        message.startTime !== undefined && (obj.startTime = message.startTime.toISOString());
        message.duration !== undefined && (obj.duration = message.duration ? Duration.toJSON(message.duration) : undefined);
        message.distribution !== undefined &&
        (obj.distribution = message.distribution ? Distribution.toJSON(message.distribution) : undefined);
        message.creator !== undefined && (obj.creator = message.creator);
        message.creationFee !== undefined &&
        (obj.creationFee = message.creationFee ? Coin.toJSON(message.creationFee) : undefined);
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgCreateCampaign>, I>>(base?: I): MsgCreateCampaign {
        return MsgCreateCampaign.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgCreateCampaign>, I>>(object: I): MsgCreateCampaign {
        const message = createBaseMsgCreateCampaign();
        message.name = object.name ?? "";
        message.description = object.description ?? "";
        message.interaction = object.interaction ?? 0;
        message.claimType = object.claimType ?? 0;
        message.nftDenomId = object.nftDenomId ?? "";
        message.tokensPerClaim = (object.tokensPerClaim !== undefined && object.tokensPerClaim !== null)
            ? Coin.fromPartial(object.tokensPerClaim)
            : undefined;
        message.maxAllowedClaims = object.maxAllowedClaims ?? 0;
        message.deposit = (object.deposit !== undefined && object.deposit !== null)
            ? Coin.fromPartial(object.deposit)
            : undefined;
        message.nftMintDetails = (object.nftMintDetails !== undefined && object.nftMintDetails !== null)
            ? NFTDetails.fromPartial(object.nftMintDetails)
            : undefined;
        message.startTime = object.startTime ?? undefined;
        message.duration = (object.duration !== undefined && object.duration !== null)
            ? Duration.fromPartial(object.duration)
            : undefined;
        message.distribution = (object.distribution !== undefined && object.distribution !== null)
            ? Distribution.fromPartial(object.distribution)
            : undefined;
        message.creator = object.creator ?? "";
        message.creationFee = (object.creationFee !== undefined && object.creationFee !== null)
            ? Coin.fromPartial(object.creationFee)
            : undefined;
        return message;
    },
};

function createBaseMsgCreateCampaignResponse(): MsgCreateCampaignResponse {
    return {};
}

export const MsgCreateCampaignResponse = {
    encode(_: MsgCreateCampaignResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgCreateCampaignResponse {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCreateCampaignResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(_: any): MsgCreateCampaignResponse {
        return {};
    },

    toJSON(_: MsgCreateCampaignResponse): unknown {
        const obj: any = {};
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgCreateCampaignResponse>, I>>(base?: I): MsgCreateCampaignResponse {
        return MsgCreateCampaignResponse.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgCreateCampaignResponse>, I>>(_: I): MsgCreateCampaignResponse {
        const message = createBaseMsgCreateCampaignResponse();
        return message;
    },
};

function createBaseMsgCancelCampaign(): MsgCancelCampaign {
    return {campaignId: 0, creator: ""};
}

export const MsgCancelCampaign = {
    encode(message: MsgCancelCampaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.campaignId !== 0) {
            writer.uint32(8).uint64(message.campaignId);
        }
        if (message.creator !== "") {
            writer.uint32(18).string(message.creator);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgCancelCampaign {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }

                    message.campaignId = longToNumber(reader.uint64() as Long);
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.creator = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): MsgCancelCampaign {
        return {
            campaignId: isSet(object.campaignId) ? Number(object.campaignId) : 0,
            creator: isSet(object.creator) ? String(object.creator) : "",
        };
    },

    toJSON(message: MsgCancelCampaign): unknown {
        const obj: any = {};
        message.campaignId !== undefined && (obj.campaignId = Math.round(message.campaignId));
        message.creator !== undefined && (obj.creator = message.creator);
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgCancelCampaign>, I>>(base?: I): MsgCancelCampaign {
        return MsgCancelCampaign.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgCancelCampaign>, I>>(object: I): MsgCancelCampaign {
        const message = createBaseMsgCancelCampaign();
        message.campaignId = object.campaignId ?? 0;
        message.creator = object.creator ?? "";
        return message;
    },
};

function createBaseMsgCancelCampaignResponse(): MsgCancelCampaignResponse {
    return {};
}

export const MsgCancelCampaignResponse = {
    encode(_: MsgCancelCampaignResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgCancelCampaignResponse {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgCancelCampaignResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(_: any): MsgCancelCampaignResponse {
        return {};
    },

    toJSON(_: MsgCancelCampaignResponse): unknown {
        const obj: any = {};
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgCancelCampaignResponse>, I>>(base?: I): MsgCancelCampaignResponse {
        return MsgCancelCampaignResponse.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgCancelCampaignResponse>, I>>(_: I): MsgCancelCampaignResponse {
        const message = createBaseMsgCancelCampaignResponse();
        return message;
    },
};

function createBaseMsgClaim(): MsgClaim {
    return {campaignId: 0, nftId: "", interaction: 0, claimer: ""};
}

export const MsgClaim = {
    encode(message: MsgClaim, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.campaignId !== 0) {
            writer.uint32(8).uint64(message.campaignId);
        }
        if (message.nftId !== "") {
            writer.uint32(18).string(message.nftId);
        }
        if (message.interaction !== 0) {
            writer.uint32(24).int32(message.interaction);
        }
        if (message.claimer !== "") {
            writer.uint32(34).string(message.claimer);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgClaim {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }

                    message.campaignId = longToNumber(reader.uint64() as Long);
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.nftId = reader.string();
                    continue;
                case 3:
                    if (tag !== 24) {
                        break;
                    }

                    message.interaction = reader.int32() as any;
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }

                    message.claimer = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): MsgClaim {
        return {
            campaignId: isSet(object.campaignId) ? Number(object.campaignId) : 0,
            nftId: isSet(object.nftId) ? String(object.nftId) : "",
            interaction: isSet(object.interaction) ? interactionTypeFromJSON(object.interaction) : 0,
            claimer: isSet(object.claimer) ? String(object.claimer) : "",
        };
    },

    toJSON(message: MsgClaim): unknown {
        const obj: any = {};
        message.campaignId !== undefined && (obj.campaignId = Math.round(message.campaignId));
        message.nftId !== undefined && (obj.nftId = message.nftId);
        message.interaction !== undefined && (obj.interaction = interactionTypeToJSON(message.interaction));
        message.claimer !== undefined && (obj.claimer = message.claimer);
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgClaim>, I>>(base?: I): MsgClaim {
        return MsgClaim.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgClaim>, I>>(object: I): MsgClaim {
        const message = createBaseMsgClaim();
        message.campaignId = object.campaignId ?? 0;
        message.nftId = object.nftId ?? "";
        message.interaction = object.interaction ?? 0;
        message.claimer = object.claimer ?? "";
        return message;
    },
};

function createBaseMsgClaimResponse(): MsgClaimResponse {
    return {};
}

export const MsgClaimResponse = {
    encode(_: MsgClaimResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgClaimResponse {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgClaimResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(_: any): MsgClaimResponse {
        return {};
    },

    toJSON(_: MsgClaimResponse): unknown {
        const obj: any = {};
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgClaimResponse>, I>>(base?: I): MsgClaimResponse {
        return MsgClaimResponse.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgClaimResponse>, I>>(_: I): MsgClaimResponse {
        const message = createBaseMsgClaimResponse();
        return message;
    },
};

function createBaseMsgDepositCampaign(): MsgDepositCampaign {
    return {campaignId: 0, amount: undefined, depositor: ""};
}

export const MsgDepositCampaign = {
    encode(message: MsgDepositCampaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.campaignId !== 0) {
            writer.uint32(8).uint64(message.campaignId);
        }
        if (message.amount !== undefined) {
            Coin.encode(message.amount, writer.uint32(18).fork()).ldelim();
        }
        if (message.depositor !== "") {
            writer.uint32(26).string(message.depositor);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgDepositCampaign {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDepositCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }

                    message.campaignId = longToNumber(reader.uint64() as Long);
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.amount = Coin.decode(reader, reader.uint32());
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }

                    message.depositor = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): MsgDepositCampaign {
        return {
            campaignId: isSet(object.campaignId) ? Number(object.campaignId) : 0,
            amount: isSet(object.amount) ? Coin.fromJSON(object.amount) : undefined,
            depositor: isSet(object.depositor) ? String(object.depositor) : "",
        };
    },

    toJSON(message: MsgDepositCampaign): unknown {
        const obj: any = {};
        message.campaignId !== undefined && (obj.campaignId = Math.round(message.campaignId));
        message.amount !== undefined && (obj.amount = message.amount ? Coin.toJSON(message.amount) : undefined);
        message.depositor !== undefined && (obj.depositor = message.depositor);
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgDepositCampaign>, I>>(base?: I): MsgDepositCampaign {
        return MsgDepositCampaign.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgDepositCampaign>, I>>(object: I): MsgDepositCampaign {
        const message = createBaseMsgDepositCampaign();
        message.campaignId = object.campaignId ?? 0;
        message.amount = (object.amount !== undefined && object.amount !== null)
            ? Coin.fromPartial(object.amount)
            : undefined;
        message.depositor = object.depositor ?? "";
        return message;
    },
};

function createBaseMsgDepositCampaignResponse(): MsgDepositCampaignResponse {
    return {};
}

export const MsgDepositCampaignResponse = {
    encode(_: MsgDepositCampaignResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): MsgDepositCampaignResponse {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseMsgDepositCampaignResponse();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(_: any): MsgDepositCampaignResponse {
        return {};
    },

    toJSON(_: MsgDepositCampaignResponse): unknown {
        const obj: any = {};
        return obj;
    },

    create<I extends Exact<DeepPartial<MsgDepositCampaignResponse>, I>>(base?: I): MsgDepositCampaignResponse {
        return MsgDepositCampaignResponse.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<MsgDepositCampaignResponse>, I>>(_: I): MsgDepositCampaignResponse {
        const message = createBaseMsgDepositCampaignResponse();
        return message;
    },
};

export interface Msg {
    CreateCampaign(request: MsgCreateCampaign): Promise<MsgCreateCampaignResponse>;

    CancelCampaign(request: MsgCancelCampaign): Promise<MsgCancelCampaignResponse>;

    Claim(request: MsgClaim): Promise<MsgClaimResponse>;

    DepositCampaign(request: MsgDepositCampaign): Promise<MsgDepositCampaignResponse>;
}

export class MsgClientImpl implements Msg {
    private readonly rpc: Rpc;
    private readonly service: string;

    constructor(rpc: Rpc, opts?: { service?: string }) {
        this.service = opts?.service || "OmniFlix.itc.v1.Msg";
        this.rpc = rpc;
        this.CreateCampaign = this.CreateCampaign.bind(this);
        this.CancelCampaign = this.CancelCampaign.bind(this);
        this.Claim = this.Claim.bind(this);
        this.DepositCampaign = this.DepositCampaign.bind(this);
    }

    CreateCampaign(request: MsgCreateCampaign): Promise<MsgCreateCampaignResponse> {
        const data = MsgCreateCampaign.encode(request).finish();
        const promise = this.rpc.request(this.service, "CreateCampaign", data);
        return promise.then((data) => MsgCreateCampaignResponse.decode(_m0.Reader.create(data)));
    }

    CancelCampaign(request: MsgCancelCampaign): Promise<MsgCancelCampaignResponse> {
        const data = MsgCancelCampaign.encode(request).finish();
        const promise = this.rpc.request(this.service, "CancelCampaign", data);
        return promise.then((data) => MsgCancelCampaignResponse.decode(_m0.Reader.create(data)));
    }

    Claim(request: MsgClaim): Promise<MsgClaimResponse> {
        const data = MsgClaim.encode(request).finish();
        const promise = this.rpc.request(this.service, "Claim", data);
        return promise.then((data) => MsgClaimResponse.decode(_m0.Reader.create(data)));
    }

    DepositCampaign(request: MsgDepositCampaign): Promise<MsgDepositCampaignResponse> {
        const data = MsgDepositCampaign.encode(request).finish();
        const promise = this.rpc.request(this.service, "DepositCampaign", data);
        return promise.then((data) => MsgDepositCampaignResponse.decode(_m0.Reader.create(data)));
    }
}

interface Rpc {
    request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
    : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
        : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
            : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
    : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
    const newDate = new Date(date);
    const seconds = newDate.getTime() / 1_000;
    const nanos = (newDate.getTime() % 1_000) * 1_000_000;
    return {seconds, nanos};
}

function fromTimestamp(t: Timestamp): Date {
    let millis = (t.seconds || 0) * 1_000;
    millis += (t.nanos || 0) / 1_000_000;
    return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
    if (o instanceof Date) {
        return o;
    } else if (typeof o === "string") {
        return new Date(o);
    } else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}

function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}

function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
