import {
    IBC_BALANCE_LIST_FETCH_ERROR,
    IBC_BALANCE_LIST_FETCH_IN_PROGRESS,
    IBC_BALANCE_LIST_FETCH_SUCCESS,
} from '../constants/faucet';
import Axios from 'axios';
import { urlFetchIBCBalance } from '../constants/restURL';

const fetchIBCBalanceListInProgress = () => {
    return {
        type: IBC_BALANCE_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchIBCBalanceListSuccess = (value) => {
    return {
        type: IBC_BALANCE_LIST_FETCH_SUCCESS,
        value,
    };
};

const fetchIBCBalanceListError = (message) => {
    return {
        type: IBC_BALANCE_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchIBCBalanceList = (ibcUrl, address) => (dispatch) => {
    dispatch(fetchIBCBalanceListInProgress());

    const url = urlFetchIBCBalance(ibcUrl, address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
            Connection: 'keep-alive',
        },
    })
        .then((res) => {
            dispatch(fetchIBCBalanceListSuccess(res.data && res.data.result));
        })
        .catch((error) => {
            dispatch(fetchIBCBalanceListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
