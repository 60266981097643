import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import variables from '../../../utils/variables';
import { Button } from '@mui/material';
import './index.css';
import { ReactComponent as ExploreMore } from '../../../assets/right-arrow.svg';
import {
    fetchLiveLaunchpadCollections,
    fetchUpcomingLaunchpadCollections,
    setLaunchpadTab,
} from '../../../actions/home';
import { setCollectionTab } from '../../../actions/collections';
import withRouter from '../../../components/WithRouter';

class Tabs extends React.Component {
    constructor (props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleExplore = this.handleExplore.bind(this);
    }

    handleClick (value) {
        if (value === this.props.tabValue) {
            return;
        }

        this.props.setLaunchpadTab(value);
        if (value === 'live' && this.props.live && !this.props.live.length) {
            this.props.fetchLiveLaunchpadCollections(this.props.address);
        }
        if (value === 'upcoming' && this.props.upcoming && !this.props.upcoming.length) {
            this.props.fetchUpcomingLaunchpadCollections(this.props.address);
        }
    }

    handleExplore () {
        this.props.setCollectionTab('launchpad');
        this.props.router.navigate('/collections');
    }

    render () {
        return (
            <div className="launchpad_header">
                <div className="launchpad_tabs">
                    <Button
                        className={this.props.tabValue === 'live' ? 'active_tab' : ''}
                        onClick={() => this.handleClick('live')}>
                        <p>{variables[this.props.lang].live}</p>
                    </Button>
                    <Button
                        className={this.props.tabValue === 'upcoming' ? 'active_tab' : ''}
                        onClick={() => this.handleClick('upcoming')}>
                        <p>{variables[this.props.lang].upcoming}</p>
                    </Button>
                </div>
                <div className="explore_more">
                    <Button onClick={this.handleExplore}>
                        <p>{variables[this.props.lang]['explore_more']}</p>
                        <ExploreMore/>
                    </Button>
                </div>
            </div>
        );
    }
}

Tabs.propTypes = {
    address: PropTypes.string.isRequired,
    fetchLiveLaunchpadCollections: PropTypes.func.isRequired,
    fetchUpcomingLaunchpadCollections: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    live: PropTypes.array.isRequired,
    liveInProgress: PropTypes.bool.isRequired,
    setCollectionTab: PropTypes.func.isRequired,
    setLaunchpadTab: PropTypes.func.isRequired,
    tabValue: PropTypes.string.isRequired,
    upcoming: PropTypes.array.isRequired,
    upcomingInProgress: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        lang: state.language,
        tabValue: state.home.launchpadTab.value,
        live: state.home.liveLaunchpad.result,
        liveInProgress: state.home.liveLaunchpad.inProgress,
        upcoming: state.home.upcomingLaunchpad.result,
        upcomingInProgress: state.home.upcomingLaunchpad.inProgress,
    };
};

const actionsToProps = {
    setLaunchpadTab,
    setCollectionTab,
    fetchLiveLaunchpadCollections,
    fetchUpcomingLaunchpadCollections,
};

export default withRouter(connect(stateToProps, actionsToProps)(Tabs));
