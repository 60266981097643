import React from 'react';
import './index.css';
import variables from '../../../utils/variables';
import { Button } from '@mui/material';
import { ReactComponent as ExploreLink } from '../../../assets/share-link.svg';
import { ReactComponent as Explore } from '../../../assets/home/explore.svg';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { STUDIO_URL } from '../../../config';
import { hideSideBar } from '../../../actions/navbar';
import withRouter from '../../../components/WithRouter';

const LeftSection = (props) => {
    const handleCreate = () => {
        window.open(STUDIO_URL);
        if (props.show) {
            props.hideSideBar();
        }
    };

    return (
        <div className="left_section">
            <h2>{variables[props.lang]['welcome_to_of_market']}</h2>
            <div className="actions">
                <Button className="explore_button" onClick={() => props.router.navigate('/marketplace/collectNow')}>
                    <p>{variables[props.lang]['explore_marketplace']}</p>
                    <Explore/>
                </Button>
                <Button className="create_button" onClick={handleCreate}>
                    <p>{variables[props.lang].create}</p>
                    <ExploreLink/>
                </Button>
            </div>
        </div>
    );
};

LeftSection.propTypes = {
    hideSideBar: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        show: state.navbar.show,
    };
};

const actionToProps = {
    hideSideBar,
};

export default withRouter(connect(stateToProps, actionToProps)(LeftSection));
