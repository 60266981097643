export const CLAIM_DIALOG_SHOW = 'CLAIM_DIALOG_SHOW';
export const CLAIM_DIALOG_HIDE = 'CLAIM_DIALOG_HIDE';
export const BURN_NFT_S_CLAIM_LIST_SET = 'BURN_NFT_S_CLAIM_LIST_SET';
export const ITC_CAMPAIGN_VIEW_SET = 'ITC_CAMPAIGN_VIEW_SET';
export const ITC_CAMPAIGNS_TAB_SET = 'ITC_CAMPAIGNS_TAB_SET';
export const ITC_DEPOSIT_AMOUNT_SET = 'ITC_DEPOSIT_AMOUNT_SET';
export const ITC_DEPOSIT_TOKEN_SET = 'ITC_DEPOSIT_TOKEN_SET';

export const ITC_DEPOSIT_DIALOG_SHOW = 'ITC_DEPOSIT_DIALOG_SHOW';
export const ITC_DEPOSIT_DIALOG_HIDE = 'ITC_DEPOSIT_DIALOG_HIDE';
export const ITC_STOP_DIALOG_SHOW = 'ITC_STOP_DIALOG_SHOW';
export const ITC_STOP_DIALOG_HIDE = 'ITC_STOP_DIALOG_HIDE';

export const ITC_MY_CAMPAIGNS_FETCH_SUCCESS = 'ITC_MY_CAMPAIGNS_FETCH_SUCCESS';
export const ITC_MY_CAMPAIGNS_FETCH_IN_PROGRESS = 'ITC_MY_CAMPAIGNS_FETCH_IN_PROGRESS';
export const ITC_MY_CAMPAIGNS_FETCH_ERROR = 'ITC_MY_CAMPAIGNS_FETCH_ERROR';

export const ITC_ELIGIBLE_CAMPAIGNS_FETCH_IN_PROGRESS = 'ITC_ELIGIBLE_CAMPAIGNS_FETCH_IN_PROGRESS';
export const ITC_ELIGIBLE_CAMPAIGNS_FETCH_SUCCESS = 'ITC_ELIGIBLE_CAMPAIGNS_FETCH_SUCCESS';
export const ITC_ELIGIBLE_CAMPAIGNS_FETCH_ERROR = 'ITC_ELIGIBLE_CAMPAIGNS_FETCH_ERROR';

export const ITC_ALL_CAMPAIGNS_FETCH_IN_PROGRESS = 'ITC_ALL_CAMPAIGNS_FETCH_IN_PROGRESS';
export const ITC_ALL_CAMPAIGNS_FETCH_SUCCESS = 'ITC_ALL_CAMPAIGNS_FETCH_SUCCESS';
export const ITC_ALL_CAMPAIGNS_FETCH_ERROR = 'ITC_ALL_CAMPAIGNS_FETCH_ERROR';

export const ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS = 'ITC_CAMPAIGNS_LISTING_FETCH_SUCCESS';
export const ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS = 'ITC_CAMPAIGNS_LISTING_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGNS_LISTING_FETCH_ERROR = 'ITC_CAMPAIGNS_LISTING_FETCH_ERROR';

export const ITC_CAMPAIGN_NFT_S_LISTING_FETCH_SUCCESS = 'ITC_CAMPAIGN_NFT_S_LISTING_FETCH_SUCCESS';
export const ITC_CAMPAIGN_NFT_S_LISTING_FETCH_IN_PROGRESS = 'ITC_CAMPAIGN_NFT_S_LISTING_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGN_NFT_S_LISTING_FETCH_ERROR = 'ITC_CAMPAIGN_NFT_S_LISTING_FETCH_ERROR';

export const ITC_CAMPAIGN_DETAILS_FETCH_SUCCESS = 'ITC_CAMPAIGN_DETAILS_FETCH_SUCCESS';
export const ITC_CAMPAIGN_DETAILS_FETCH_IN_PROGRESS = 'ITC_CAMPAIGN_DETAILS_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGN_DETAILS_FETCH_ERROR = 'ITC_CAMPAIGN_DETAILS_FETCH_ERROR';

export const EMPTY_CAMPAIGN_DETAILS_SINGLE_PAGE_SET = 'EMPTY_CAMPAIGN_DETAILS_SINGLE_PAGE_SET';

export const ITC_CAMPAIGN_STATS_FETCH_SUCCESS = 'ITC_CAMPAIGN_STATS_FETCH_SUCCESS';
export const ITC_CAMPAIGN_STATS_FETCH_IN_PROGRESS = 'ITC_CAMPAIGN_STATS_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGN_STATS_FETCH_ERROR = 'ITC_CAMPAIGN_STATS_FETCH_ERROR';

export const ITC_STATS_FETCH_SUCCESS = 'ITC_STATS_FETCH_SUCCESS';
export const ITC_STATS_FETCH_IN_PROGRESS = 'ITC_STATS_FETCH_IN_PROGRESS';
export const ITC_STATS_FETCH_ERROR = 'ITC_STATS_FETCH_ERROR';

export const ITC_SINGLE_CAMPAIGN_TABS_SET = 'ITC_SINGLE_CAMPAIGN_TABS_SET';

export const ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_SUCCESS = 'ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_SUCCESS';
export const ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_IN_PROGRESS = 'ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_IN_PROGRESS';
export const ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_ERROR = 'ITC_CAMPAIGN_CLAIMED_NFT_S_FETCH_ERROR';

export const DE_LIST_NFT_S_CLAIM_SET = 'DE_LIST_NFT_S_CLAIM_SET';

// FLIX DROP

export const CAMPAIGNS_FLIX_DROP_FETCH_SUCCESS = 'CAMPAIGNS_FLIX_DROP_FETCH_SUCCESS';
export const CAMPAIGNS_FLIX_DROP_FETCH_IN_PROGRESS = 'CAMPAIGNS_FLIX_DROP_FETCH_IN_PROGRESS';
export const CAMPAIGNS_FLIX_DROP_FETCH_ERROR = 'CAMPAIGNS_FLIX_DROP_FETCH_ERROR';

export const CLAIM_ALL_DIALOG_SHOW = 'CLAIM_ALL_DIALOG_SHOW';
export const CLAIM_ALL_DIALOG_HIDE = 'CLAIM_ALL_DIALOG_HIDE';
export const CLAIM_ALL_NFT_S_LIST_SET = 'CLAIM_ALL_NFT_S_LIST_SET';

export const CLAIM_ALL_NFT_S_LISTING_FETCH_SUCCESS = 'CLAIM_ALL_NFT_S_LISTING_FETCH_SUCCESS';
export const CLAIM_ALL_NFT_S_LISTING_FETCH_IN_PROGRESS = 'CLAIM_ALL_NFT_S_LISTING_FETCH_IN_PROGRESS';
export const CLAIM_ALL_NFT_S_LISTING_FETCH_ERROR = 'CLAIM_ALL_NFT_S_LISTING_FETCH_ERROR';

// ITC Campaigns Listings
export const ITC_FLIX_DROP_LIST_SUCCESS = 'ITC_FLIX_DROP_LIST_SUCCESS';
export const ITC_FLIX_DROP_LIST_IN_PROGRESS = 'ITC_FLIX_DROP_LIST_IN_PROGRESS';
export const ITC_FLIX_DROP_LIST_ERROR = 'ITC_FLIX_DROP_LIST_ERROR';

export const ITC_CURATED_LIST_IN_PROGRESS = 'ITC_CURATED_LIST_IN_PROGRESS';
export const ITC_CURATED_LIST_SUCCESS = 'ITC_CURATED_LIST_SUCCESS';
export const ITC_CURATED_LIST_ERROR = 'ITC_CURATED_LIST_ERROR';

export const ITC_ELIGIBLE_LIST_IN_PROGRESS = 'ITC_ELIGIBLE_LIST_IN_PROGRESS';
export const ITC_ELIGIBLE_LIST_SUCCESS = 'ITC_ELIGIBLE_LIST_SUCCESS';
export const ITC_ELIGIBLE_LIST_ERROR = 'ITC_ELIGIBLE_LIST_ERROR';

export const ITC_CREATED_LIST_SUCCESS = 'ITC_CREATED_LIST_SUCCESS';
export const ITC_CREATED_LIST_IN_PROGRESS = 'ITC_CREATED_LIST_IN_PROGRESS';
export const ITC_CREATED_LIST_ERROR = 'ITC_CREATED_LIST_ERROR';

export const ITC_ALL_LIST_SUCCESS = 'ITC_ALL_LIST_SUCCESS';
export const ITC_ALL_LIST_IN_PROGRESS = 'ITC_ALL_LIST_IN_PROGRESS';
export const ITC_ALL_LIST_ERROR = 'ITC_ALL_LIST_ERROR';

export const STREAM_PAY_CLAIMABLE_TOKENS_FETCH_IN_PROGRESS = 'STREAM_PAY_CLAIMABLE_TOKENS_FETCH_IN_PROGRESS';
export const STREAM_PAY_CLAIMABLE_TOKENS_FETCH_SUCCESS = 'STREAM_PAY_CLAIMABLE_TOKENS_FETCH_SUCCESS';
export const STREAM_PAY_CLAIMABLE_TOKENS_FETCH_ERROR = 'STREAM_PAY_CLAIMABLE_TOKENS_FETCH_ERROR';
