import React from 'react';
import SkeletonLoader from '../SkeletonLoader';
import '../../containers/Collections/index.css';
import '../../containers/MyAccount/index.css';
import Skeleton from '@mui/material/Skeleton';

const Collections = () => {
    return (
        <div className="explore_main collections_main">
            <div className="explore scroll_bar">
                <div className="collections collection_header_skeleton">
                    <div className="name_section">
                        <Skeleton
                            animation="wave"
                            className="skeleton_loader"
                            height={40}
                            variant="circular"
                            width={200}/>
                    </div>
                    <div className="tabs_section">
                        <div className="tabs_section1">
                            <Skeleton
                                animation="wave"
                                className="skeleton_loader"
                                height={60}
                                width={200}/>
                        </div>
                        <div className="tabs_section2">
                            <Skeleton
                                animation="wave"
                                className="skeleton_loader"
                                height={60}
                                width={200}/>
                        </div>
                    </div>
                </div>
                <div className="lists collection_list">
                    <SkeletonLoader type="collection-card"/>
                </div>
            </div>
        </div>
    );
};

export default Collections;
