import React from 'react';
import './index.css';
import { AppBar, IconButton, Tab, Tooltip, tooltipClasses } from '@mui/material';
import logo from '../../assets/logo.svg';
import { Button } from '@material-ui/core';
import ExpansionButton from '../../containers/NavBar/ExpansionButton';
import Icon from '../../components/Icon';
import AppsPopover from '../../containers/NavBar/AppsPopover';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import ProfileSkeletonLoader from '../SkeletonLoader/ProfileSkeletonLoader';
import withRouter from '../WithRouter';

const CustomWidthTooltip = styled(({
    className,
    ...props
}) => (
    <Tooltip arrow {...props} classes={{ popper: className }}/>
))({
    [`& .${tooltipClasses.tooltip}`]: {
        width: 100,
        background: '#000000',
        borderRadius: '8px',
        padding: '12px 10px',
        textAlign: 'enter',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#000000',
    },
});

const NavBar = (props) => {
    const route = props.router && props.router.location && props.router.location.pathname &&
        props.router.location.pathname.split('/') &&
        props.router.location.pathname.split('/')[1];

    return (
        <div className="nav_bar">
            <Button
                className="logo_button"
                href="/home">
                <img alt="logo" height="64" src={logo} width="150"/>
            </Button>
            <div className="expansion_div">
                <AppsPopover/>
                <ExpansionButton/>
            </div>
            <div className="right_nav">
                <div className="back_button">
                    <IconButton>
                        <Icon className="close" icon="close"/>
                    </IconButton>
                </div>
                <AppBar className="horizontal_tabs" position="static">
                    <div className="tabs_content">
                        <Tab
                            disableRipple
                            className={'tab ' + (route === 'home' ? 'active_tab' : '')}
                            component="a"
                            href="/home"
                            label={<p className="text">{variables[props.lang].home}</p>}
                            role="button"
                            value="home"/>
                        <Tab
                            disableRipple
                            className={'tab ' + (route === 'collectNow' ? 'active_tab' : '')}
                            component="a"
                            href="/marketplace/collectNow"
                            label={<p className="text">{variables[props.lang]['market_place']}</p>}
                            role="button"
                            value="collectNow"/>
                        <Tab
                            disableRipple
                            className={'tab ' + (route === 'collections' ? 'active_tab' : '')}
                            component="a"
                            href="/collections"
                            label={<p className="text">{variables[props.lang].collections}</p>}
                            role="button"
                            value="collections"/>
                        {props.campaigns && props.campaigns.length
                            ? <Tab
                                disableRipple
                                className={'tab ' + (route === 'curations' ? 'active_tab' : '')}
                                component="a"
                                href="/curations"
                                label={<p className="text">{variables[props.lang].curations}</p>}
                                role="button"
                                value="curations"/> : null}
                        <Tab
                            disableRipple
                            className={'tab ' + (route === 'campaigns' ? 'active_tab' : '')}
                            component="a"
                            href="/campaigns"
                            label={<p className="text">{variables[props.lang].campaigns}</p>}
                            role="button"
                            value="campaigns"/>
                    </div>
                </AppBar>
                <AppsPopover/>
                <Button className="create_button">
                    Create
                </Button>
                <div className="price_mode">
                    <Button
                        className="active_mode">
                        {variables[props.lang].token}
                    </Button>
                    <CustomWidthTooltip title="Coming Soon">
                        <Button>
                            {variables[props.lang].usd}
                        </Button>
                    </CustomWidthTooltip>
                </div>
                <ProfileSkeletonLoader type="profile"/>
            </div>
        </div>
    );
};

NavBar.propTypes = {
    campaigns: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        campaigns: state.home.campaigns.result,
        lang: state.language,
    };
};

export default withRouter(connect(stateToProps)(NavBar));
