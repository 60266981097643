export const config = {
    API_URL: 'https://api.omniflix.studio',
    RPC_URL: 'https://rpc.omniflix.network',
    REST_URL: 'https://rest.omniflix.network',
    DATA_LAYER: 'https://data-api.omniflix.studio',
    LAUNCH_PAD: 'https://api-launchpad.omniflix.market',
    CHAIN_ID: 'omniflixhub-1',
    CHAIN_NAME: 'OmniFlix Hub',
    COIN_DENOM: 'FLIX',
    COIN_MINIMAL_DENOM: 'uflix',
    COIN_DECIMALS: 6,
    PREFIX: 'omniflix',
    AVG_GAS_STEP: 0.010,
};

export const MINT_ACCESS = 'https://forms.gle/Aj3oc2fbcoCTX9KF7';
export const ACTIVITY_URL = 'https://activity-api.omniflix.studio';
export const EXPLORER_URL = 'https://www.mintscan.io/omniflix';
export const IPFS_URL = 'https://ipfs.omniflix.studio/ipfs';
export const STUDIO_URL = 'https://app.omniflix.studio';
export const MARKETPLACE_URL = 'https://omniflix.market';
export const IPFS_REFERENCE_PATH = 'ipfs://';
export const STREAM_PAY_URL = 'https://streampay.me';

export const DEFAULT_SKIP = 0;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_TABLE_LIMIT = 50;
export const DEFAULT_TOTAL = 20;
export const DEFAULT_SORT_BY = 'created_at';
export const DEFAULT_ORDER = 'desc';
export const DEFAULT_LAZY_FETCH_HEIGHT = 256;
export const TRANSACTION_SET_TIME_OUT = 3000;

export const GAS_PRICE_STEP_LOW = 0.001;
export const GAS_PRICE_STEP_AVERAGE = 0.0025;
export const GAS_PRICE_STEP_HIGH = 0.025;

export const gas = {
    LIST_NFT: 200000,
    MINT_NFT: 300000,
    ITC_CLAIM: 500000,
};

export const chainId = config.CHAIN_ID;
export const chainName = config.CHAIN_NAME;
export const coinDenom = config.COIN_DENOM;
export const coinMinimalDenom = config.COIN_MINIMAL_DENOM;
export const coinDecimals = config.COIN_DECIMALS;
export const prefix = config.PREFIX;

export const walletExtensions = {
    LEAP: 'https://chrome.google.com/webstore/detail/leap-cosmos-wallet/fcfcfllfndlomdhbehjjcoimbgofdncg',
    COSMO_STATION: 'https://chrome.google.com/webstore/detail/cosmostation-wallet/fpkhgmpbidmiogeglndfbkegfdlnajnf',
    KEPLR: 'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap',
    META_MASK: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
};

export const socialLinks = {
    TWITTER: 'https://twitter.com/OmniFlixNetwork',
    TELEGRAM: 'https://t.me/OmniFlixChat',
    DISCORD: 'https://discord.com/invite/6gdQ4yZSTC',
    GITHUB: 'https://github.com/OmniFlix',
    WEBSITE: 'https://omniflix.network/',
    YOUTUBE: 'https://www.youtube.com/OmniFlixNetwork',
};

export const appsLinks = {
    STUDIO: STUDIO_URL,
    IN_SYNC: 'https://omniflix.co',
    TV: 'https://omniflix.tv',
};

export const chainConfig = {
    chainId: chainId,
    chainName,
    rpc: config.RPC_URL,
    rest: config.REST_URL,
    stakeCurrency: {
        coinDenom,
        coinMinimalDenom,
        coinDecimals,
    },
    bip44: {
        coinType: 118,
    },
    bech32Config: {
        bech32PrefixAccAddr: `${prefix}`,
        bech32PrefixAccPub: `${prefix}pub`,
        bech32PrefixValAddr: `${prefix}valoper`,
        bech32PrefixValPub: `${prefix}valoperpub`,
        bech32PrefixConsAddr: `${prefix}valcons`,
        bech32PrefixConsPub: `${prefix}valconspub`,
    },
    currencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
        },
    ],
    feeCurrencies: [
        {
            coinDenom,
            coinMinimalDenom,
            coinDecimals,
            gasPriceStep: {
                low: 0.001,
                average: 0.0025,
                high: 0.025,
            },
        },
    ],
    coinType: 118,
    features: ['ibc-transfer'],
};

export const cosmoStationChainConfig = {
    chainId: chainId,
    chainName: chainName,
    addressPrefix: prefix,
    baseDenom: coinMinimalDenom,
    displayDenom: coinDenom,
    restURL: config.REST_URL,
    coinType: '118', // optional (default: '118')
    decimals: coinDecimals, // optional (default: 6)
    gasRate: {
        average: '0.0025',
        low: '0.001',
        tiny: '0.025',
    },
};
