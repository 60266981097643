import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import variables from '../../../utils/variables';
import { Button } from '@mui/material';
import './index.css';
import { ReactComponent as ExploreMore } from '../../../assets/right-arrow.svg';
import { fetchBidsAuctions, fetchUpcomingAuctions, setHomeAuctionsTab } from '../../../actions/home';
import { setMarketPlaceTab } from '../../../actions/marketplace';
import withRouter from '../../../components/WithRouter';

class Tabs extends React.Component {
    constructor (props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleExplore = this.handleExplore.bind(this);
    }

    handleClick (value) {
        if (value === this.props.tabValue) {
            return;
        }

        this.props.setHomeAuctionsTab(value);
        if (value === 'bids' && this.props.bidsAuctions && !this.props.bidsAuctions.length) {
            this.props.fetchBidsAuctions();
        }
        if (value === 'upcoming' && this.props.upcomingAuctions && !this.props.upcomingAuctions.length) {
            this.props.fetchUpcomingAuctions();
        }
    }

    handleExplore () {
        this.props.setMarketPlaceTab('auctions');
        this.props.router.navigate('/marketplace/auctions');
    }

    render () {
        return (
            <div className="auctions_header">
                <div className="auctions_tabs">
                    <Button
                        className={this.props.tabValue === 'recent' ? 'active_tab' : ''}
                        onClick={() => this.handleClick('recent')}>
                        <p>{variables[this.props.lang].recent}</p>
                    </Button>
                    <Button
                        className={this.props.tabValue === 'bids' ? 'active_tab' : ''}
                        onClick={() => this.handleClick('bids')}>
                        <p>{variables[this.props.lang].bids}</p>
                    </Button>
                    <Button
                        className={this.props.tabValue === 'upcoming' ? 'active_tab' : ''}
                        onClick={() => this.handleClick('upcoming')}>
                        <p>{variables[this.props.lang].upcoming}</p>
                    </Button>
                </div>
                <div className="explore_more">
                    <Button onClick={this.handleExplore}>
                        <p>{variables[this.props.lang]['explore_more']}</p>
                        <ExploreMore/>
                    </Button>
                </div>
            </div>
        );
    }
}

Tabs.propTypes = {
    bidsAuctions: PropTypes.array.isRequired,
    fetchBidsAuctions: PropTypes.func.isRequired,
    fetchUpcomingAuctions: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    setHomeAuctionsTab: PropTypes.func.isRequired,
    setMarketPlaceTab: PropTypes.func.isRequired,
    tabValue: PropTypes.string.isRequired,
    upcomingAuctions: PropTypes.array.isRequired,
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        bidsAuctions: state.home.auctions.bids,
        lang: state.language,
        tabValue: state.home.auctions.tabValue,
        upcomingAuctions: state.home.auctions.upcoming,
    };
};

const actionsToProps = {
    fetchBidsAuctions,
    fetchUpcomingAuctions,
    setHomeAuctionsTab,
    setMarketPlaceTab,
};

export default withRouter(connect(stateToProps, actionsToProps)(Tabs));
