import { chainConfig, chainId, walletExtensions } from '../../config';
import {
    CONNECT_META_MASK_ERROR,
    CONNECT_META_MASK_IN_PROGRESS,
    CONNECT_META_MASK_SUCCESS,
} from '../../constants/wallet';
import { CosmjsOfflineSigner, getSnap } from '@leapwallet/cosmos-snap-provider';
import { setKeplrAccountKeys } from './wallet';
import { showMessage } from '../snackbar';

const connectMetaMaskInProgress = () => {
    return {
        type: CONNECT_META_MASK_IN_PROGRESS,
    };
};

const connectMetaMaskSuccess = (value) => {
    return {
        type: CONNECT_META_MASK_SUCCESS,
        value,
    };
};

const connectMetaMaskError = (message) => {
    return {
        type: CONNECT_META_MASK_ERROR,
        message,
        variant: 'error',
    };
};

export const initializeMetaMask = (cb) => (dispatch) => {
    dispatch(connectMetaMaskInProgress());
    (async () => {
        const snapInstalled = await getSnap();
        if (snapInstalled) {
            try {
                await getKey(chainId);
            } catch (error) {
                dispatch(showMessage('chain id is not added please uninstall snaps and try again'));
                connectSnap((error) => {
                    if (error) {
                        if (error && error['npm:@leapwallet/metamask-cosmos-snap'] && error['npm:@leapwallet/metamask-cosmos-snap'].enabled) {
                            cb(null, true);
                            return;
                        }
                        cb(null);
                        dispatch(connectMetaMaskError(error.toString()));
                    }
                }).then((result) => {
                }).catch((error) => {
                    dispatch(connectMetaMaskError(error.toString()));
                });

                return;
            }
        }
        if (!snapInstalled) {
            connectSnap((error) => {
                if (error) {
                    if (error && error['npm:@leapwallet/metamask-cosmos-snap'] && error['npm:@leapwallet/metamask-cosmos-snap'].enabled) {
                        cb(null, true);
                        return;
                    }
                    cb(null);
                    dispatch(connectMetaMaskError(error.toString()));
                }
            }).then((result) => {
            }).catch((error) => {
                dispatch(connectMetaMaskError(error.toString()));
            });
        }
        if (!window.ethereum) {
            const error = 'Please install MetaMask extension';
            if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn');
            } else {
                window.open(walletExtensions.META_MASK);
            }
            dispatch(connectMetaMaskError(error));
        }

        if (window.ethereum) {
            window.ethereum.enable(chainId)
                .then(async () => {
                    const offlineSigner = new CosmjsOfflineSigner(chainId);
                    const accounts = await offlineSigner.getAccounts();
                    dispatch(connectMetaMaskSuccess(accounts));
                    cb(accounts);

                    const key = await getKey(chainId);
                    dispatch(setKeplrAccountKeys(key));
                }).catch((error) => {
                    if (error && error.code === 4100) {
                        if (error.data && error.data.method && error.data.method === 'wallet_snap') {
                            return;
                        }
                    } else if (error && error.message === 'Invalid chainId') {
                        dispatch(showMessage('chain id is not added please uninstall snaps and try again'));
                    }

                    cb(null);
                    dispatch(connectMetaMaskError(error.message));
                });
        } else {
            return null;
        }
    })();
};

const connectSnap = async (cb) => {
    try {
        await window.ethereum.request({
            method: 'wallet_requestSnaps',
            params: {
                'npm:@leapwallet/metamask-cosmos-snap': {},
            },
        }).then(async (result) => {
            window.ethereum.enable(chainId);
            await window.ethereum.request({
                method: 'wallet_invokeSnap',
                params: {
                    snapId: 'npm:@leapwallet/metamask-cosmos-snap',
                    request: {
                        method: 'suggestChain',
                        params: {
                            chainInfo: chainConfig,
                        },
                    },
                },
            });
            cb(result);
        }).catch(() => {
            cb(null);
        });
    } catch (error) {
        cb(null);
    }
};

const getKey = async (chainId) => {
    return await window.ethereum.request({
        method: 'wallet_invokeSnap',
        params: {
            snapId: 'npm:@leapwallet/metamask-cosmos-snap',
            request: {
                method: 'getKey',
                params: {
                    chainId,
                },
            },
        },
    });
};
