import { config } from '../config';

const variables = {
    en: {
        connect_keplr: 'Connect with Keplr',
        crypto: 'Crypto',
        usd: 'USD',
        token: 'Tokens',

        // navbar
        search: 'Search',
        home: 'Home',
        watch: 'Watch',
        explore: 'Explore',
        market_place: 'Marketplace',
        activity: 'Activity',
        create: 'Create',
        connect: 'Connect',
        connecting: 'Connecting',
        items: 'Items',

        // Side Panel
        my_account: 'My Account',
        account_overview: 'Account Overview',
        goto_account: 'Go to Account',
        connected_with_keplr: 'Connected with Keplr',
        disconnect: 'Disconnect',
        recent_offers: 'Recent Offers',
        see_all: 'See All',
        offer_made: 'Offer Made',
        recently_collected: 'Recently Collected',
        recently_minted: 'Recently Minted',
        recent_activity: 'Recent Activity',
        recent_collections: 'Recent Collections',
        recent_nfts: 'Recent NFTs',
        sent: 'Sent',
        buy: 'Buy',
        claim_tokens: 'Claim Tokens',
        claim: 'Claim',
        clear_all: 'Clear All',
        claim_all: 'Claim All',
        claimable_tokens: 'Claimable Tokens',

        // faucet
        faucet: 'FAUCET',
        connect_account: 'Connect Account',
        congrats: 'Congratulations!',
        claimed_tokens: 'You have claimed all your test tokens',
        deposit_ibc: 'Deposit via IBC',
        chain: 'Chain',
        claimed: 'Claimed',
        claim_test_tokens: 'Claim test Tokens',
        processing: 'Processing',

        // MarketPlace
        results: 'Results',
        creator: 'Creator',
        bid_price: 'Bid Price',
        sort_by: 'Sort By',
        owner: 'Owner',
        buy_now: 'Buy Now',
        collect: 'Collect',
        total_nfts: 'Total NFTs',

        // Filters
        filters: 'Filters',
        status: 'Status',
        price_range: 'Price Range',
        select_token: '-Select Coin-',
        min_range: 'Min(00)',
        to: 'To',
        max_range: 'Min(50k)',
        genre: 'Genre',
        collections: 'Collections',
        chains: 'Chains',
        on_sale_in: 'On-Sale In',
        types: 'Types',
        collection_name: 'Collection Name',

        // My Account
        copied: 'copied!',
        copy_to_clipboard: 'copy to clipboard',
        collected: 'Collected',
        nfts: 'NFTs',
        minted: 'Minted',
        offers: 'Offers',
        ibc_tokens: 'IBC Tokens',
        tokens: 'Tokens',
        ibc_tokens_content: 'Deposit and withdraw your IBC tokens',
        flix: 'FLIX',
        not_listed: 'Not Listed',
        collection: 'Collection',
        listed: 'Listed',
        channel_closed: 'Currently not accepting deposits from this channel',

        // Watch
        continue_watching: 'Continue Watching',
        trending: 'Trending',
        most_viewed: 'Most Viewed',
        suggested: 'Suggested',
        top_categories: 'Top Categories',
        button: 'Button',
        top_collections: 'Top Collections',
        live_auctions: 'Live auctions',
        top_selling: 'Top Selling',
        price: 'Price',
        hot_collections: 'Hot Collections',

        deposit_ibc_asset: 'Deposit IBC Asset',
        ibc_transfer: 'IBC Transfer',
        from: 'From',
        amount_deposit: 'Amount to Deposit',
        available: 'Available',
        max: 'Max',
        deposit: 'Deposit',

        withdraw_ibc_asset: 'Withdraw IBC Asset',
        ibc_withdraw: 'IBC WithDraw',
        amount_withdraw: 'Amount to Withdraw',
        withdraw: 'Withdraw',
        approval_pending: 'Approval Pending',
        transfer: 'Transfer',
        sell: 'Sell',
        list: 'List',
        delist: 'Delist',
        de_list: 'De-List',
        enter_recipent_address: 'Enter your recipent\'s account address',
        cancel: 'Cancel',
        confirm: 'Confirm',
        asset_transfer_success: 'Asset Transfer Successful',
        asset_transfer_fail: 'Asset Transfer Failed',
        close_window: 'Close Window',
        transaction_hash: 'Transaction Hash',

        listing_type: 'Listing Type',
        listing_id: 'Listing ID',
        timed_auction: 'Timed Auction',
        coming_soon: 'coming soon',
        fixed_price: 'Fixed Price',
        listing_price: 'Listing Price',
        listing_price_helper_text: 'Select Token & Listing Price to list asset',
        asset_listed_successfully: 'Asset Listed Successfully',
        cancel_listing: 'Cancel Listing',
        asset_not_listed: 'Asset not Listed',
        id: 'ID',
        type: 'Type',
        split_info: 'Split Info',
        split_info_helper_text: 'Enter OmniFlix address and Split % of users who will receive the proceeds.',
        split_info_info: 'Sum of all splits cannot exceed 100%',
        enter_split_address: 'Enter Account Address',
        enter_split_percentage: 'Enter split share %',
        total_sale_breakdown: 'Total Sale Breakdown',
        royalty_share: 'Royalty Share',
        splits: 'Splits',
        market_place_share: 'Marketplace Share',
        invalid_address: 'Invalid Address',
        creators_royalty: 'Creators Royalty',
        sale_breakdown: 'Sale Breakdown',

        listed_price: 'Listed Price',
        selling_type: 'Selling Type',
        selling_id: 'Selling ID',
        sell_price: 'Sell Price',
        asset_successfully_removed_from_list: 'Asset successfully DeListed',
        auctions_cancelled_successfully: 'Auctions Cancelled Successfully',
        failed_to_remove_asset: 'Failed to Remove Asset from Listing',

        view_schema_json: 'View JSON Schema',
        json_schema_properties: 'JSON Schema Properties',
        properties: 'Properties',
        view_json_file: 'View JSON File',

        traded_volume: 'Traded Volume',
        total_traded_value: 'Total Traded Volume',
        my_total_traded_value: 'My Total Traded Volume (Buy Activity)',
        download_file: 'Download file',

        nft_purchase_success: 'Congrats on your successful NFT purchase!',
        view_nft: 'View NFT',
        check_later: 'Transaction is in process. check after some time.',
        category: 'Category',
        tags: 'Tags',

        create_listing: 'Create Listing',
        create_auction: 'Create Auction',
        confirm_listing: 'Confirm Listing',
        confirm_auction: 'Confirm Auction',
        list_nft_confirmation: 'List NFT Confirmation',
        auction_confirmation: 'Timed Auction Confirmation',
        message_type: 'Message Type',
        nft_id: 'NFT ID',
        denom_id: 'Denom ID',
        listed_type: 'Listed Type',
        de_listing_nft_confirmation: 'De-Listing NFT Confirmation',
        confirm_de_listing: 'Confirm De-Listing',
        transfer_nft_confirmation: 'Transfer NFT Confirmation',
        confirm_transfer: 'Confirm Transfer',
        nft_image_url: 'NFT Image URL',
        royalties: 'Royalties',
        extensibility: 'Extensibility',
        schema: 'Schema',
        denom_symbol: 'Denom Symbol',
        nsfw: 'NSFW',
        transferability: 'Transferability',
        confirm_ibc_deposit_details: 'Confirm IBC Deposit Details',
        confirm_ibc_withdraw_details: 'Confirm IBC Withdraw Details',
        selected_token: 'Selected Token',

        explore_head: 'Explore & collect from Genesis Creators',
        explore_launchpad: 'Collections up for minting',
        recently_listed: 'Recently Listed',
        explore_more: 'Explore more',
        list_price: 'List Price',
        footer_content1: 'want to manage, distribute & monetize your work of art?',
        footer_content2: 'Interoperable NFT network & infrastructure for creators, sovereign communities & DAOs. Do more with your NFTs!',
        get_in_touch: 'Get in Touch',
        genesis_creator: 'Genesis Creator Drop',
        view_details: 'View Details',
        close: 'Close',
        floor_price: 'Floor Price',

        done: 'Done',
        fee_grant_allowance: 'Fee Grant Allowance',
        request_fee_grant: 'Request Fee Grant',
        allowance_received: 'Fee Allowance Received',
        allowance: 'Allowance',
        fee_grant_content: 'Collectors, request for a fee grant allowance on OmniFlix Market to collect, transfer, list & burn NFTs. FLIX tokens will not be visible in your account but your transaction fees will be paid by the app.',

        ledger_note: 'Note: Ledger is not supported. Please do not initiate transactions using Ledger. Support will roll out soon',
        congratulations: 'Congratulations',

        // Campaigns
        verify_twitter_account: 'Verify Twitter Account',
        verify_twitter_account_to_request: 'Verify Twitter Account To Request fee grant',
        verify_twitter: 'Verify Twitter',
        verify_twitter_info: 'Twitter verification is needed to Mint Tweet as NFTs. You can verify Twitter account from the account page.',
        verify_twitter_error: 'Twitter Account is not verified',
        verified: 'Verified',
        next: 'Next',
        expiry: 'Expiry',
        fee_grant_expired: 'Fee Grant allowance Expired',

        share: 'Share',
        share_tweet: 'Share Tweet',
        share_tweet_info: 'Copy & Paste the below text and tweet from your account. You will have to enter the Tweet URL in next step',
        verify_tweet: 'Verify Tweet',
        enter_twitter_username: 'Enter your Twitter username',
        enter_tweet_url: 'Enter tweet URL for verification',
        submit: 'Submit',
        copy_text: 'Copy Text',
        twitter_verified: 'Your Twitter Account has been verified.',
        copy_right: 'This collection is flagged for IP or copyright infringement as reported\n' +
            'by the majority in the community. Please collect at your own risk.',
        cr_collection_card: 'flagged for IP or copyright infringement',
        ip_tooltip: 'IP or copyright infringement',
        ibc_deprecated_text: 'will soon be deprecated. Do withdraw your funds and De-list your NFTs from',
        delist_ibc_deprecated: 'Delist your NFTs from “ATOM/CH-0" as "ATOM/CH-0" will soon be deprecated. You can List NFT again on "ATOM" right away.',
        mint: 'Mint',
        min_bid_price: 'Min Bid Price',
        min_bid_price_helper_text: 'Select Token & Minimum bid price to auction asset\n',
        bid_increment_per: 'Bids Increment Percentage',
        bid_increment_per_helper_text: 'Set the % by which the next bid price will increment',
        whitelist: 'Whitelist',
        whitelist_helper_text: 'Enter OmniFlix address of users who have been whitelisted for this auction.',
        auction_start_time: 'Auction Start Time',
        auction_end_time: 'Auction End Time',
        optional: 'Optional',
        start_time: 'Start Time',
        end_time: 'End Time',
        helper_text: 'Helper Text',
        my_nfts: 'My NFTs',
        tweet: 'Tweet',

        claim_now: 'Claim now',
        banner_claim_text: 'You have Unclaimed Tokens on StreamPay. Claim',

        // place bid
        place_bid: 'Place Bid',
        enter_bid_price: 'Enter Bid Price',
        bid: 'Bid',
        bid_requested_successfully: 'Bid Requested Successfully',
        bid_requested_failed: 'Failed to Bid Requested',
        bid_amount: 'BID Amount',

        auctions: 'Auctions',
        launchpad: 'Launchpad',
        un_verified: 'Un Verified',
        pending_curation: 'Pending Curation',
        last_bid_price: 'Last Bid Price',
        min_bid: 'Min Bid',
        ends_in: 'Ends In',
        starts_in: 'Starts In',
        live_in: 'Live in',
        live: 'Live',
        collect_now: 'Collect Now',
        auction_go_live: 'Auction Live in',
        auction_ends_in: 'Auction ends in',
        auction_is_not_live: 'Auction is not Live',
        owner_of_nft: 'You are the owner of this NFT',
        account_not_whitelisted: 'Your Account is Not WhiteListed',
        cancel_auction_confirmation: 'Cancel Auction Confirmation',
        cancel_auction: 'Cancel Auction',
        auction_types: 'Auction Types',
        not_whitelisted: 'Your account is not whitelisted',

        burn: 'Burn',
        more_options: 'More options',
        agree_to_delete: 'Do you agree to delete the NFT?',
        agree_to_delete_info: 'burning will NOT give users their Flix Drop and that has to be done via the Campaigns UI',
        enter_last_characters: 'Enter last 4 characters of the NFT ID',
        asset_deleted: 'Asset Deleted',
        asset_burn_failed: 'Asset Burn Failed',
        okay: 'Okay',

        // connect dialog
        connect_wallet: 'Connect Your Wallet',
        keplr_wallet: 'Keplr Wallet',
        chain_not_supported: `${config.CHAIN_ID} are not currently supported chain`,

        // Home Page
        welcome_to_of_market: 'Welcome to OmniFlix Market',
        explore_marketplace: 'Explore marketplace',
        featured_collection: 'Recently Listed',
        request_mint_access: 'Request mint access (for creators)',
        request_mint_access_info: 'Apply for fee grant and take your collection live on OmniFlix Market',
        recent: 'Recent',
        bids: 'Bids',
        upcoming: 'Upcoming',
        explore_header: 'One stop solution for world of NFTs',
        explore_launchpad_text: 'Explore Launchpad',
        explore_nft_listings: 'Explore NFT Listings',
        explore_nft_auctions: 'Explore NFT Auctions',
        explore_collections: 'Explore Collections',
        mint_manage: 'Mint, manage, distribute & monetize your art work?',
        mint_price: 'Mint Price',

        campaigns: 'Campaigns',
        campaign: 'Campaign',
        view_all: 'View All',
        participate_now: 'Participate Now',
        participate_in_campaign: 'Participate in the campaign',
        collection_participating: 'Collections participating',
        completed: 'Completed',
        active: 'Active',
        flixdrop: 'FlixDrop',

        do_more_with_your_nfts: 'Do more with your NFTs on OmniFlix Hub!',
        highest_bids_in_last_24hrs: 'Highest bids in last 24hrs',
        listed_for_auction: 'Listed for auction',
        total_bids: 'Total Bids',

        //     Collections
        all_collections: 'All Collections',
        launchpad_collections: 'Launchpad Collections',
        mint_now: 'Mint Now',
        curated: 'Curated',
        all: 'All',

        send_tokens: 'Send Tokens',
        account_address: 'Account Address',
        amount_send: 'Amount to Send',
        send: 'Send',
        confirm_send_tokens_details: 'Confirm Send Tokens Details',
        enter_address: 'Enter Address',
        nft_title: 'NFT Title',
        nft_type: 'NFT Type',
        actions: 'Actions',
        bids_count: 'Bids Count',
        current_bid: 'Current Bid',
        minimum_bid: 'Minimum Bid',
        owners: 'Owners',

        // recap
        recap: 'Roundup',
        recap_content: 'Generate your personalized media with on-chain data',
        connectkeplr: 'Connect keplr',
        generate_video: 'Generate Video',
        connect_keplr_account: 'Connect with Your Keplr Account',
        mint_nft_confirm: 'Mint NFT Confirmation',
        get_your_nft: 'Claim NFT',
        click_to_generate: 'Click to generate your\n' +
            'Interchain Roundup video NFT\n',
        generating_please_wait: 'Please wait.. This will take a few mins..',
        generating1: 'Looking back to the past...',
        generating2: 'Gathering data...',
        generating3: 'Generating personalized video...',
        generating4: 'Just few more secs to go...',
        generating5: 'This may take a while,',
        generating6: 'you can leave this page and come back later',
        play_video: 'Play Video',
        tweet_text: 'Cosmonauts! Got my #InterchainRoundupNFT on OmniFlix Market 🔥\n\n' +
            'Generate your personalized video NFT with on-chain data from:\n\n',
        tweet_tags: '- @osmosiszone powered by @NumiaData\n' +
            '- @OmniFlixNetwork &\n' +
            '- @StargazeZone \n\n' +
            'My NFT - ',
        tweet_url: 'Get yours on👇\n',
        requesting_queue: 'Minting NFTs',
        nft_minted: 'NFT being minted',
        download_video: 'Download',

        fetching_on_chain_data: 'Fetching on-chain data...',
        swap_data: 'Swap data',
        created_collections: 'Created Collections',
        owned_nfts: 'Owned NFTs',
        cosmostation: 'Cosmostation',
        leap: 'Leap',
        metamask: 'MetaMask',

        bulk_select: 'Bulk Select',
        bulk_selected: 'Bulk Selected',
        select_all: 'Select All',
        selected_nfts: 'Selected NFTs',
        listing: 'Listing',
        nft_s: 'NFTs',
        bulk_listing: 'Bulk Listing',
        bulk_listing_confirmation: 'Bulk Listing Confirmation',
        de_list_nft_s: 'De-LIST NFTs',
        bulk_de_listing: 'Bulk DeList',
        bulk_de_listing_confirmation: 'Bulk De-Listings Confirmation',
        enter_recipient_address: 'Enter recipient address',
        transfer_selected_nft_s: 'Transfer Selected NFTs',
        transfer_note: '*Note: To transfer, enter recipient\'s Omniflix account address',
        no_nft_preview: 'No NFT preview',
        invalid_id: 'Invalid ID',
        burn_selected_nft_s: 'Burn Selected NFTs',
        burn_note: 'Enter last 4 characters of the Account address',

        //    ITC Module
        interact_with_nft_claim: 'Interact with NFTs to Claim FLIX',
        burn_to_claim: 'Burn to claim',
        transfer_to_claim: 'Transfer to claim',
        start_date: 'Start Date',
        end_date: 'End Date',
        interaction: 'Interaction',
        claim_type: 'Claim Type',
        rewards: 'Rewards',
        hold: 'Hold',
        stats_participants: 'Stats of Participants',
        verify: 'Verify',
        itc: 'ITC',
        my_campaigns: 'My Campaigns',
        eligible_campaigns: 'Eligible Campaigns',
        stop: 'Stop',
        deposit_tokens_to_campaign: 'Deposit tokens to the campaign',
        confirm_stop_campaign: 'Confirm to stop campaign',
        curations: 'Curations',
        campaign_stopped: 'Campaign Stopped',
        max_claims: 'Max Claims',
        input_collection: 'Input Collection',
        remaining_claims: 'Remaining Claims',

        your_nfts: 'Your NFTs',
        tardi_grades: 'TARDIGRADES',
        burn_to_claim1: 'Burn-to-Claim',
        transfer_to_claim1: 'Transfer-to-Claim',
        eligible: 'Eligible',
        view_more: 'View More',
        non_listed: 'Non Listed',
        de_list_nfts: 'De-List NFTs',
        eligible_collection: 'Eligible Collection',
        time_left: 'Time Left',
        campaign_status: 'Campaign Status',
        claimable_token_details: 'Claimable token details',
        tokens_per_claim: 'Tokens per Claim',
        distribution_type: 'Distribution Type',
        duration: 'Duration',
        deposited_tokens: 'Deposited Tokens',
        remaining_tokens: 'Remaining Tokens',
        claimable_nft_details: 'Claimable NFT details',
        created: 'Created',

        interaction_type_tooltip: ' NFT from Eligible Collection to Claim Reward',
        campaign_id: 'Campaign ID',

        campaign_tweet_text: 'Cosmonauts, just claimed my rewards by interacting with NFTs on OmniFlix Market\n\n',
        campaign_tweet_text1: 'Enter a world of web3 media with OmniFlix.market to: \n\n',
        campaign_tweet_text2: '✅ List NFTs for fixed-price or auctions\n',
        campaign_tweet_text3: '✅ Create NFT campaigns (Interact to Claim) to engage your community\n',
        flixdrop_tweet_text: 'Cosmonauts, just claimed $FLIX as a part of the #FlixDrop interacting with my NFTs on OmniFlix Market\n\n',
        flixdrop_tweet_text1: 'Join us on this web3 media voyage today! \n\n',
        flixdrop_tweet_text2: 'Claim your $FLIX on - \n',

        non_transferable: 'non transferable',
    },
};

export default variables;
