import React from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeChain, initializeLeap } from '../../actions/account/wallet';
import { setDisconnect } from '../../actions/account';
import { fetchAllowances, fetchBalance } from '../../actions/account/BCDetails';
import DotsLoading from '../../components/DotsLoading';
import SidePanel from './SidePanel';
import { fetchIBCTokensList } from '../../actions/marketplace';
import { config } from '../../config';
import { connectIBCAccount } from '../../actions/account/IBCTokens';
import { fetchIBCBalanceList } from '../../actions/faucet';
import { hideSideBar, setPriceMode } from '../../actions/navbar';
import ProfileSkeletonLoader from '../../components/SkeletonLoader/ProfileSkeletonLoader';
import ConnectButton from './ConnectButton';
import { initializeCosmoStation } from '../../actions/account/cosmostation';
import { fetchMyAccountStats } from '../../actions/myAccount';
import { initializeMetaMask } from '../../actions/account/metaMask';
import { getWrapAddress } from '../../utils/strings';
import withRouter from '../../components/WithRouter';

class ProfilePopover extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
            tokens: true,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.initializeKeplr = this.initializeKeplr.bind(this);
        this.initKeplr = this.initKeplr.bind(this);
        this.initCosmoStation = this.initCosmoStation.bind(this);
        this.initializeCosmoStation = this.initializeCosmoStation.bind(this);
        this.initializeMetaMask = this.initializeMetaMask.bind(this);
    }

    componentDidMount () {
        if (this.props.ibcTokensList.length === 0 && !this.props.ibcTokensListInProgress && this.state.tokens) {
            this.setState({
                tokens: false,
            });
            this.props.fetchIBCTokensList();
        }

        if (this.props.address === '' && localStorage.getItem('of_market_address')) {
            if (localStorage.getItem('of_market_cosmostation')) {
                setTimeout(() => {
                    this.initializeCosmoStation();
                }, 600);

                return;
            }
            if (localStorage.getItem('of_market_leap')) {
                setTimeout(() => {
                    this.initializeLeap();
                }, 600);

                return;
            }
            if (localStorage.getItem('of_market_metamask')) {
                setTimeout(() => {
                    this.initializeMetaMask();
                }, 600);

                return;
            }

            // used set time out to omit the image loading issue with window.onload
            setTimeout(() => {
                this.initializeKeplr();
            }, 600);
        } else {
            if ((this.props.address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(this.props.address);
            }
            if (this.props.address && this.props.allowances &&
                (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(this.props.address);
            }
        }

        if (localStorage.getItem('of_market_currency')) {
            this.props.setPriceMode(localStorage.getItem('of_market_currency'));
        }

        if (localStorage.getItem('of_market_cosmostation')) {
            window.cosmostation.cosmos.on('accountChanged', () => {
                this.props.setDisconnect();
                this.initCosmoStation();
                localStorage.removeItem('acToken_of_market');
                localStorage.removeItem('of_market_address');
            });
        } else if (localStorage.getItem('of_market_leap')) {
            window.addEventListener('leap_keystorechange', () => {
                this.props.setDisconnect();
                this.initLeap();
                localStorage.removeItem('acToken_of_market');
                localStorage.removeItem('of_market_address');
            });
        } else if (localStorage.getItem('of_market_metamask')) {
            return null;
        } else {
            window.addEventListener('keplr_keystorechange', () => {
                this.props.setDisconnect();
                this.initKeplr();
                localStorage.removeItem('acToken_of_market');
                localStorage.removeItem('of_market_address');
            });
        }
    }

    componentWillUnmount () {
        window.removeEventListener('keplr_keystorechange', this.initKeplr);
        window.removeEventListener('leap_keystorechange', this.initLeap);
    }

    componentDidUpdate (pp, ps, ss) {
        if ((pp.address !== this.props.address) && !this.props.addressInProgress && this.props.address) {
            this.props.fetchMyAccountStats(this.props.address);
        }
    }

    initKeplr () {
        this.props.initializeChain((address) => {
            localStorage.setItem('of_market_address', address && address.length && address[0] && address[0].address);
            this.props.fetchBalance(address[0].address);
            this.props.fetchAllowances(address[0].address);
        });
    }

    initializeKeplr () {
        this.props.initializeChain((address) => {
            if (!address) {
                window.onload = () => this.initializeKeplr();
                return;
            }

            localStorage.setItem('of_market_address', address && address.length && address[0] && address[0].address);
            if ((address && address.length && address[0] && address[0].address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address[0].address);
            }
            if ((address && address.length && address[0] && address[0].address) &&
                this.props.allowances && (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(address[0].address);
            }
        });
    }

    initCosmoStation () {
        this.props.initializeCosmoStation((error, account) => {
            if (error) {
                return;
            }

            localStorage.setItem('of_market_address', account && account.address);
            this.props.fetchBalance(account.address);
            this.props.fetchAllowances(account.address);
        });
    }

    initializeCosmoStation () {
        this.props.initializeCosmoStation((error, account) => {
            if (error) {
                return;
            }

            if (!account) {
                window.onload = () => this.initializeCosmoStation();
                return;
            }

            localStorage.setItem('of_market_address', account && account.address);
            if ((account && account.address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(account.address);
            }
            if ((account && account.address) &&
                this.props.allowances && (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(account.address);
            }
        });
    }

    initLeap () {
        this.props.initializeLeap((address) => {
            localStorage.setItem('of_market_address', address && address.length && address[0] && address[0].address);
            this.props.fetchBalance(address[0].address);
            this.props.fetchAllowances(address[0].address);
        });
    }

    initializeLeap () {
        this.props.initializeLeap((address) => {
            if (!address) {
                window.onload = () => this.initializeLeap();
                return;
            }

            localStorage.setItem('of_market_address', address && address.length && address[0] && address[0].address);
            if ((address && address.length && address[0] && address[0].address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address[0].address);
            }
            if ((address && address.length && address[0] && address[0].address) &&
                this.props.allowances && (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(address[0].address);
            }
            localStorage.setItem('of_market_leap', 'leap');
        });
    }

    initializeMetaMask () {
        this.props.initializeMetaMask((address) => {
            if (!address) {
                window.onload = () => this.initializeMetaMask();
                return;
            }

            localStorage.setItem('acToken_of_market', address[0].address);
            if ((address && address.length && address[0] && address[0].address) &&
                (this.props.balance.length === 0) && !this.props.balanceInProgress) {
                this.props.fetchBalance(address[0].address);
            }
            if ((address && address.length && address[0] && address[0].address) &&
                this.props.allowances && (this.props.allowances.length === 0) && !this.props.allowancesInProgress) {
                this.props.fetchAllowances(address[0].address);
            }
            localStorage.setItem('of_market_metamask', 'metamask');
        });
    }

    handleOpen () {
        const address = localStorage.getItem('of_market_address') || this.props.address;
        if (!this.props.myAccountStatsInProgress && this.props.myAccountStatsData &&
            !Object.keys(this.props.myAccountStatsData).length && address) {
            this.props.fetchMyAccountStats(address);
        }
        this.setState({
            open: true,
        });
        if (this.props.show) {
            const path = this.props.router && this.props.router.location && this.props.router.location.pathname &&
                this.props.router.location.pathname.split('/')[1];
            const nftPath = this.props.router && this.props.router.location && this.props.router.location.pathname &&
                this.props.router.location.pathname.split('/')[3];
            if ((path === 'account') || (path === 'c' && (!nftPath))) {
                document.body.style.overflow = null;
            }

            this.props.hideSideBar();
        }
    }

    handleClose () {
        this.setState({
            open: false,
        });
    }

    render () {
        const {
            address,
        } = this.props;
        const denom = this.props.chain && this.props.chain.ibc_denom_hash
            ? this.props.chain.ibc_denom_hash
            : config.COIN_MINIMAL_DENOM;
        const decimals = this.props.chain && this.props.chain.network && this.props.chain.network.decimals
            ? this.props.chain.network.decimals
            : config.COIN_DECIMALS;
        const ibcToken = denom && this.props.ibcTokensList && this.props.ibcTokensList.length &&
            this.props.ibcTokensList.find((val) => val && val.ibc_denom_hash && (val.ibc_denom_hash === denom));

        let balance = this.props.balance && this.props.balance.length && this.props.balance.find((val) => val.denom === denom);
        balance = balance && balance.amount && balance.amount / (10 ** decimals);

        return (
            <div className="profile">
                {this.props.address === '' && !localStorage.getItem('of_market_address')
                    ? <ConnectButton/>
                    : (this.props.inProgress || this.props.balanceInProgress) &&
                    (this.props.address === '' && !localStorage.getItem('of_market_address'))
                        ? <ProfileSkeletonLoader type="profile"/>
                        : <Button
                            className="profile_section"
                            variant="contained"
                            onClick={this.handleOpen}>
                            <div className="account">
                                {this.props.balanceInProgress
                                    ? <DotsLoading/>
                                    : balance
                                        ? <p className="balance">
                                            {balance} {(ibcToken && ibcToken.network && ibcToken.network.display_denom) || config.COIN_DENOM}
                                        </p>
                                        : <p className="balance">
                                            0 {(ibcToken && ibcToken.network && ibcToken.network.display_denom) || config.COIN_DENOM}
                                        </p>}
                                {this.props.inProgress && this.props.address === ''
                                    ? <DotsLoading/>
                                    : <div className="hash_text" title={address}>
                                        {getWrapAddress(address, 10, 6)}
                                    </div>}
                            </div>
                            <span className="profile_img"/>
                        </Button>}
                <SidePanel open={this.state.open} onClose={this.handleClose}/>
            </div>
        );
    }
}

ProfilePopover.propTypes = {
    address: PropTypes.string.isRequired,
    addressInProgress: PropTypes.bool.isRequired,
    allowances: PropTypes.array.isRequired,
    allowancesInProgress: PropTypes.bool.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    chain: PropTypes.object.isRequired,
    connectIBCAccount: PropTypes.func.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    fetchIBCBalanceList: PropTypes.func.isRequired,
    fetchIBCTokensList: PropTypes.func.isRequired,
    fetchMyAccountStats: PropTypes.func.isRequired,
    hideSideBar: PropTypes.func.isRequired,
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeChain: PropTypes.func.isRequired,
    initializeCosmoStation: PropTypes.func.isRequired,
    initializeLeap: PropTypes.func.isRequired,
    initializeMetaMask: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    myAccountStatsData: PropTypes.object.isRequired,
    myAccountStatsInProgress: PropTypes.bool.isRequired,
    setDisconnect: PropTypes.func.isRequired,
    setPriceMode: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    router: PropTypes.shape({
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

const stateToProps = (state) => {
    return {
        address: state.account.wallet.connection.address,
        addressInProgress: state.account.wallet.connection.addressInProgress,
        allowances: state.account.bc.allowances.value,
        allowancesInProgress: state.account.bc.allowances.inProgress,
        inProgress: state.account.wallet.connection.inProgress,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        chain: state.account.chainID.value,
        lang: state.language,
        ibcTokensList: state.marketplace.ibcTokensList.value,
        ibcTokensListInProgress: state.marketplace.ibcTokensList.inProgress,
        show: state.navbar.show,
        myAccountStatsData: state.myAccount.stats.result,
        myAccountStatsInProgress: state.myAccount.stats.inProgress,
    };
};

const actionToProps = {
    setDisconnect,
    setPriceMode,
    initializeChain,
    initializeCosmoStation,
    fetchAllowances,
    fetchBalance,
    fetchIBCTokensList,
    connectIBCAccount,
    fetchIBCBalanceList,
    hideSideBar,
    fetchMyAccountStats,
    initializeLeap,
    initializeMetaMask,
};

export default withRouter(connect(stateToProps, actionToProps)(ProfilePopover));
