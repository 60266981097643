import {
    CONNECT_COSMO_STATION_ERROR,
    CONNECT_COSMO_STATION_IN_PROGRESS,
    CONNECT_COSMO_STATION_SUCCESS,
} from '../../constants/wallet';
import { config, cosmoStationChainConfig, walletExtensions } from '../../config';
import { cosmos, InstallError } from '@cosmostation/extension-client';

const connectCosmoStationInProgress = () => {
    return {
        type: CONNECT_COSMO_STATION_IN_PROGRESS,
    };
};

const connectCosmoStationSuccess = (value) => {
    return {
        type: CONNECT_COSMO_STATION_SUCCESS,
        value,
    };
};

const connectCosmoStationError = (message) => {
    return {
        type: CONNECT_COSMO_STATION_ERROR,
        message,
        variant: 'error',
    };
};

export const initializeCosmoStation = (cb) => (dispatch) => {
    dispatch(connectCosmoStationInProgress());
    (async () => {
        if (!window.getOfflineSigner || !window.cosmostation) {
            const error = 'Please install Cosmostation extension';
            dispatch(connectCosmoStationError(error));
        } else {
            const provider = await cosmos();
            const supportedChains = await provider.getSupportedChains();
            const official = supportedChains && supportedChains.official.find((val) => val === config.CHAIN_NAME.toLocaleLowerCase());
            const unOfficial = supportedChains && supportedChains.unofficial.find((val) => val === config.CHAIN_NAME.toLocaleLowerCase());
            if (!official && !unOfficial) {
                try {
                    await provider.addChain(cosmoStationChainConfig);
                } catch (error) {
                    const chainError = 'Failed to suggest the chain';
                    dispatch(connectCosmoStationError(chainError));
                }
            }
        }
        try {
            const provider = await cosmos();
            const account = await provider.requestAccount(config.CHAIN_ID);
            dispatch(connectCosmoStationSuccess(account));
            cb(null, account);
        } catch (error) {
            if (error instanceof InstallError) {
                const error = 'Download the Cosmostation Extension';
                window.open(walletExtensions.COSMO_STATION);
                dispatch(connectCosmoStationError(error));
                cb(error);
            } else if (error.code === 4001) {
                const error = 'user rejected request';
                dispatch(connectCosmoStationError(error));
                cb(error);
            } else {
                dispatch(connectCosmoStationError(error.message));
                cb(error.message);
            }
        }
    })();
};
