import React from 'react';
import '../index.css';
import './index.css';
import Skeleton from '@mui/material/Skeleton';

const CollectionInfoSkeletonLoader = () => {
    return (
        <div className="collections collection_skeleton">
            <div className="collection_profile">
                <div className="image_section">
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={250}
                        variant="circular"
                        width={250}/>
                </div>
                <div className="text_section">
                    <div className="text_section1">
                        <Skeleton
                            animation="wave"
                            className="skeleton_loader"
                            height={60}
                            width="100%"/>
                    </div>
                    <div className="text_section2">
                        <Skeleton
                            animation="wave"
                            className="skeleton_loader"
                            height={34}
                            width="100%"/>
                    </div>
                    <div className="text_section3">
                        <Skeleton
                            animation="wave"
                            className="skeleton_loader"
                            height={50}
                            width="100%"/>
                    </div>
                    <div className="text_section4">
                        <Skeleton
                            animation="wave"
                            className="skeleton_loader"
                            height={100}
                            width="100%"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CollectionInfoSkeletonLoader;
