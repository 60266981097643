import { combineReducers } from 'redux';
import { ACTIVE_CARD_SET } from '../../constants/explore';
import { coinsList, onSaleList } from '../../utils/filters';
import { tokensList } from '../../utils/defaultOptions';
import { PLACE_BID_DIALOG_HIDE } from '../../constants/auctions';
import { DELIST_DIALOG_HIDE, SELL_DIALOG_HIDE } from '../../constants/marketplace';
import {
    CAMPAIGN_AUCTIONS_AUCTION_TYPE_SET,
    CAMPAIGN_AUCTIONS_FILTERS_HIDE,
    CAMPAIGN_AUCTIONS_FILTERS_SHOW,
    CAMPAIGN_AUCTIONS_ON_SALE_IN_SEARCH_SET,
    CAMPAIGN_AUCTIONS_ON_SALE_IN_SET,
    CAMPAIGN_AUCTIONS_PRICE_RANGE_COIN_SET,
    CAMPAIGN_AUCTIONS_PRICE_RANGE_SET,
    CAMPAIGN_LISTINGS_AUCTION_TYPE_SET,
    CAMPAIGN_LISTINGS_FILTERS_HIDE,
    CAMPAIGN_LISTINGS_FILTERS_SHOW,
    CAMPAIGN_LISTINGS_ON_SALE_IN_SEARCH_SET,
    CAMPAIGN_LISTINGS_ON_SALE_IN_SET,
    CAMPAIGN_LISTINGS_PRICE_RANGE_COIN_SET,
    CAMPAIGN_LISTINGS_PRICE_RANGE_SET,
} from '../../constants/campaign';

const show = (state = false, action) => {
    switch (action.type) {
    case CAMPAIGN_LISTINGS_FILTERS_SHOW:
        return true;
    case CAMPAIGN_LISTINGS_FILTERS_HIDE:
    case ACTIVE_CARD_SET:
        return false;
    default:
        return state;
    }
};

const priceRange = (state = {
    list: coinsList,
    value: tokensList && tokensList.length && tokensList[0],
    range: [0, 50000],
    min: 0,
    max: 50000,
}, action) => {
    switch (action.type) {
    case CAMPAIGN_LISTINGS_PRICE_RANGE_COIN_SET:
        return {
            ...state,
            value: action.value,
        };
    case CAMPAIGN_LISTINGS_PRICE_RANGE_SET:
        return {
            ...state,
            range: action.value,
        };
    default:
        return state;
    }
};

const onSaleIn = (state = {
    list: onSaleList,
    value: new Set(),
    search: '',
}, action) => {
    switch (action.type) {
    case CAMPAIGN_LISTINGS_ON_SALE_IN_SET:
        return {
            ...state,
            value: action.value,
        };
    case CAMPAIGN_LISTINGS_ON_SALE_IN_SEARCH_SET:
        return {
            ...state,
            search: action.value,
        };
    default:
        return state;
    }
};

const auctionsType = (state = '', action) => {
    switch (action.type) {
    case CAMPAIGN_LISTINGS_AUCTION_TYPE_SET:
        return action.value;
    case PLACE_BID_DIALOG_HIDE:
    case DELIST_DIALOG_HIDE:
    case SELL_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const showAuctions = (state = false, action) => {
    switch (action.type) {
    case CAMPAIGN_AUCTIONS_FILTERS_SHOW:
        return true;
    case CAMPAIGN_AUCTIONS_FILTERS_HIDE:
    case ACTIVE_CARD_SET:
        return false;
    default:
        return state;
    }
};

const priceRangeAuctions = (state = {
    list: coinsList,
    value: tokensList && tokensList.length && tokensList[0],
    range: [0, 50000],
    min: 0,
    max: 50000,
}, action) => {
    switch (action.type) {
    case CAMPAIGN_AUCTIONS_PRICE_RANGE_COIN_SET:
        return {
            ...state,
            value: action.value,
        };
    case CAMPAIGN_AUCTIONS_PRICE_RANGE_SET:
        return {
            ...state,
            range: action.value,
        };
    default:
        return state;
    }
};

const onSaleInAuctions = (state = {
    list: onSaleList,
    value: new Set(),
    search: '',
}, action) => {
    switch (action.type) {
    case CAMPAIGN_AUCTIONS_ON_SALE_IN_SET:
        return {
            ...state,
            value: action.value,
        };
    case CAMPAIGN_AUCTIONS_ON_SALE_IN_SEARCH_SET:
        return {
            ...state,
            search: action.value,
        };
    default:
        return state;
    }
};

const auctionsTypeAuctions = (state = '', action) => {
    switch (action.type) {
    case CAMPAIGN_AUCTIONS_AUCTION_TYPE_SET:
        return action.value;
    case PLACE_BID_DIALOG_HIDE:
    case DELIST_DIALOG_HIDE:
    case SELL_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

export default combineReducers({
    show,
    priceRange,
    onSaleIn,
    auctionsType,

    showAuctions,
    priceRangeAuctions,
    onSaleInAuctions,
    auctionsTypeAuctions,
});
