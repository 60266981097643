import React from 'react';
import '../../containers/ITCCampaigns/index.css';
import '../../containers/ITCCampaigns/Curated/index.css';
import '../../containers/ITCCampaigns/GridView/index.css';
import { Button } from '@mui/material';
import variables from '../../utils/variables';
import { ReactComponent as GridView } from '../../assets/filters/gridView.svg';
import { ReactComponent as ListView } from '../../assets/filters/listView.svg';
import SkeletonLoader from '../SkeletonLoader';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';

const ITCCampaigns = (props) => {
    return (
        <div className="explore_main itc_page">
            <div className="explore scroll_bar itc_section">
                <div className="header">
                    <h2>Campaigns</h2>
                </div>
                <div className="itc_filters">
                    <div className="itc_filters_inner_div">
                        <div className="left_section">
                            <div className="tabs_section">
                                <Button className="all active_tab">
                                    {variables[props.lang].flixdrop}
                                    {props.stats && props.stats.flixdrop_campaigns
                                        ? ` (${props.stats.flixdrop_campaigns})` : ''}
                                </Button>
                                <Button>
                                    {variables[props.lang].curated}
                                    {props.stats && props.stats.verified_campaigns
                                        ? ` (${props.stats.verified_campaigns})` : ''}
                                </Button>
                                {localStorage.getItem('of_market_address') && <Button>
                                    {variables[props.lang].eligible}
                                    {props.stats && props.stats.eligible_campaigns
                                        ? ` (${props.stats.eligible_campaigns})` : ''}
                                </Button>}
                                {localStorage.getItem('of_market_address') &&
                                props.stats && props.stats.my_campaigns
                                    ? <Button>
                                        {variables[props.lang].created}
                                        {props.stats && props.stats.my_campaigns
                                            ? ` (${props.stats.my_campaigns})` : ''}
                                    </Button> : null}
                                <Button>
                                    {variables[props.lang].all}
                                    {props.stats && props.stats.total_campaigns
                                        ? ` (${props.stats.total_campaigns})` : ''}
                                </Button>
                            </div>
                        </div>
                        <div className="grid_list_view">
                            <Button className="active_view">
                                <GridView/>
                            </Button>
                            <Button>
                                <ListView/>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="campaigns_list_section">
                    <div className="camp_listing">
                        <SkeletonLoader type="itc-campaign"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

ITCCampaigns.propTypes = {
    lang: PropTypes.string.isRequired,
    stats: PropTypes.object.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        stats: state.itcModule.itcStats.value,
    };
};

export default connect(stateToProps)(ITCCampaigns);
