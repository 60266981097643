import { combineReducers } from 'redux';
import {
    ACTIVE_CAMPAIGNS_FETCH_ERROR,
    ACTIVE_CAMPAIGNS_FETCH_IN_PROGRESS,
    ACTIVE_CAMPAIGNS_FETCH_SUCCESS,
    CAMPAIGN_AUCTIONS_FETCH_ERROR,
    CAMPAIGN_AUCTIONS_FETCH_IN_PROGRESS,
    CAMPAIGN_AUCTIONS_FETCH_SUCCESS,
    CAMPAIGN_AUCTIONS_SEARCH_SET,
    CAMPAIGN_AUCTIONS_SORT_SET,
    CAMPAIGN_FETCH_ERROR,
    CAMPAIGN_FETCH_IN_PROGRESS,
    CAMPAIGN_FETCH_SUCCESS,
    CAMPAIGN_LISTINGS_FETCH_ERROR,
    CAMPAIGN_LISTINGS_FETCH_IN_PROGRESS,
    CAMPAIGN_LISTINGS_FETCH_SUCCESS,
    CAMPAIGN_LISTINGS_SEARCH_SET,
    CAMPAIGN_LISTINGS_SORT_SET,
    CAMPAIGN_NFT_TAB_SET,
    CAMPAIGN_TAB_SET,
    CAMPAIGNS_TAB_SET,
    COMPLETED_CAMPAIGNS_FETCH_ERROR,
    COMPLETED_CAMPAIGNS_FETCH_IN_PROGRESS,
    COMPLETED_CAMPAIGNS_FETCH_SUCCESS,
    FEE_GRANT_ALLOWANCE_ERROR,
    FEE_GRANT_ALLOWANCE_IN_PROGRESS,
    FEE_GRANT_ALLOWANCE_SUCCESS,
    UPCOMING_CAMPAIGNS_FETCH_ERROR,
    UPCOMING_CAMPAIGNS_FETCH_IN_PROGRESS,
    UPCOMING_CAMPAIGNS_FETCH_SUCCESS,
} from '../../constants/campaign';
import { DEFAULT_LIMIT, DEFAULT_SKIP } from '../../config';
import filters from './filters';

const feeGrant = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case FEE_GRANT_ALLOWANCE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FEE_GRANT_ALLOWANCE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FEE_GRANT_ALLOWANCE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const activeCampaigns = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case ACTIVE_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ACTIVE_CAMPAIGNS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case ACTIVE_CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const upcomingCampaigns = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case UPCOMING_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPCOMING_CAMPAIGNS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case UPCOMING_CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const completedCampaigns = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
}, action) => {
    switch (action.type) {
    case COMPLETED_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case COMPLETED_CAMPAIGNS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case COMPLETED_CAMPAIGNS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const campaign = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case CAMPAIGN_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGN_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case CAMPAIGN_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const campaignListings = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    search: '',
}, action) => {
    switch (action.type) {
    case CAMPAIGN_LISTINGS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGN_LISTINGS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case CAMPAIGN_LISTINGS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CAMPAIGN_LISTINGS_SEARCH_SET:
        return {
            ...state,
            search: action.value,
        };
    default:
        return state;
    }
};

const listingSort = (state = {
    sortBy: 'created_at',
    order: '',
}, action) => {
    if (action.type === CAMPAIGN_LISTINGS_SORT_SET) {
        return {
            ...state,
            sortBy: action.sortBy,
            order: action.order,
        };
    }

    return state;
};

const campaignAuctions = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: null,
    search: '',
}, action) => {
    switch (action.type) {
    case CAMPAIGN_AUCTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case CAMPAIGN_AUCTIONS_FETCH_SUCCESS: {
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.value,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
                total: action.total,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.value],
                skip: action.skip,
                limit: action.limit,
                total: action.total,
            };
        }
    }
    case CAMPAIGN_AUCTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CAMPAIGN_AUCTIONS_SEARCH_SET:
        return {
            ...state,
            search: action.value,
        };
    default:
        return state;
    }
};

const auctionsSort = (state = {
    sortBy: 'created_at',
    order: '',
}, action) => {
    if (action.type === CAMPAIGN_AUCTIONS_SORT_SET) {
        return {
            ...state,
            sortBy: action.sortBy,
            order: action.order,
        };
    }

    return state;
};

const campaignsTab = (state = 'active', action) => {
    switch (action.type) {
    case CAMPAIGNS_TAB_SET:
        return action.value;
    default:
        return state;
    }
};

const campaignTab = (state = 'nfts', action) => {
    switch (action.type) {
    case CAMPAIGN_TAB_SET:
        return action.value;
    default:
        return state;
    }
};

const campaignNFTTab = (state = 'collectNow', action) => {
    switch (action.type) {
    case CAMPAIGN_NFT_TAB_SET:
        return action.value;
    default:
        return state;
    }
};

export default combineReducers({
    feeGrant,
    activeCampaigns,
    upcomingCampaigns,
    completedCampaigns,
    campaign,
    campaignListings,
    listingSort,
    campaignAuctions,
    auctionsSort,
    campaignsTab,
    campaignTab,
    campaignNFTTab,
    filters,
});
