import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { initializeCosmoStation } from '../../../actions/account/cosmostation';
import { showMessage } from '../../../actions/snackbar';
import logo from '../../../assets/wallets/cosmostation.svg';
import { hideConnectDialog } from '../../../actions/navbar';
import variables from '../../../utils/variables';
import { fetchAllowances, fetchBalance } from '../../../actions/account/BCDetails';

const CosmostationConnectButton = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const login = () => {
        setInProgress(true);
        props.initializeCosmoStation((error, account) => {
            setInProgress(false);
            if (error) {
                localStorage.removeItem('of_market_address');
                props.showMessage(error);
                setInProgress(false);

                return;
            }

            localStorage.setItem('of_market_address', account && account.address);
            if ((account && account.address) &&
                (props.balance.length === 0) && !props.balanceInProgress) {
                props.fetchBalance(account.address);
            }
            if ((account && account.address) &&
                props.allowances && (props.allowances.length === 0) && !props.allowancesInProgress) {
                props.fetchAllowances(account.address);
            }
            props.hideConnectDialog();
            localStorage.setItem('of_market_cosmostation', 'cosmostation');
        });
    };

    return (
        <Button
            className="cosmostation_button"
            disabled={inProgress}
            variant="contained"
            onClick={login}>
            <img alt="logo" src={logo}/>
            {inProgress ? variables[props.lang].connecting + '...' : variables[props.lang].cosmostation}
        </Button>
    );
};

CosmostationConnectButton.propTypes = {
    allowances: PropTypes.array.isRequired,
    allowancesInProgress: PropTypes.bool.isRequired,
    balance: PropTypes.array.isRequired,
    balanceInProgress: PropTypes.bool.isRequired,
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    hideConnectDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeCosmoStation: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    showMessage: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        allowances: state.account.bc.allowances.value,
        allowancesInProgress: state.account.bc.allowances.inProgress,
        inProgress: state.account.wallet.connection.inProgress,
        balance: state.account.bc.balance.value,
        balanceInProgress: state.account.bc.balance.inProgress,
        lang: state.language,
    };
};

const actionsToProps = {
    fetchAllowances,
    fetchBalance,
    initializeCosmoStation,
    showMessage,
    hideConnectDialog,
};

export default connect(stateToProps, actionsToProps)(CosmostationConnectButton);
