import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TOTAL } from '../config';
import { combineReducers } from 'redux';
import {
    BIDS_AUCTIONS_FETCH_ERROR,
    BIDS_AUCTIONS_FETCH_IN_PROGRESS,
    BIDS_AUCTIONS_FETCH_SUCCESS,
    FEATURED_COLLECTIONS_FETCH_ERROR,
    FEATURED_COLLECTIONS_FETCH_IN_PROGRESS,
    FEATURED_COLLECTIONS_FETCH_SUCCESS,
    FETCH_LAUNCHPAD_COLLECTIONS_ERROR,
    FETCH_LAUNCHPAD_COLLECTIONS_IN_PROGRESS,
    FETCH_LAUNCHPAD_COLLECTIONS_SUCCESS,
    FETCH_STATS_ERROR,
    FETCH_STATS_IN_PROGRESS,
    FETCH_STATS_SUCCESS,
    HOME_AUCTIONS_TAB_SET,
    LAUNCHPAD_COLLECTION_SEARCH_SET,
    LAUNCHPAD_TAB_SET,
    LIVE_LAUNCHPAD_COLLECTIONS_FETCH_ERROR,
    LIVE_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS,
    LIVE_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS,
    RECENT_AUCTIONS_FETCH_ERROR,
    RECENT_AUCTIONS_FETCH_IN_PROGRESS,
    RECENT_AUCTIONS_FETCH_SUCCESS,
    TOP_CAMPAIGNS_FETCH_ERROR,
    TOP_CAMPAIGNS_FETCH_IN_PROGRESS,
    TOP_CAMPAIGNS_FETCH_SUCCESS,
    TOP_LISTINGS_FETCH_ERROR,
    TOP_LISTINGS_FETCH_IN_PROGRESS,
    TOP_LISTINGS_FETCH_SUCCESS,
    UN_VERIFIED_COLLECTION_SEARCH_SET,
    UPCOMING_AUCTIONS_FETCH_ERROR,
    UPCOMING_AUCTIONS_FETCH_IN_PROGRESS,
    UPCOMING_AUCTIONS_FETCH_SUCCESS,
    UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_ERROR,
    UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS,
    UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS,
    FLIX_FRIDAY_BANNER_FETCH_IN_PROGRESS,
    FLIX_FRIDAY_BANNER_FETCH_SUCCESS,
    FLIX_FRIDAY_BANNER_FETCH_ERROR,
    FETCH_MARKET_STATS_IN_PROGRESS,
    FETCH_MARKET_STATS_SUCCESS,
    FETCH_MARKET_STATS_ERROR,
} from '../constants/home';

const featuredCollections = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case FEATURED_COLLECTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FEATURED_COLLECTIONS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    }
    case FEATURED_COLLECTIONS_FETCH_ERROR: {
        return {
            ...state,
            inProgress: false,
        };
    }

    default:
        return state;
    }
};

const launchpadCollections = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_LAUNCHPAD_COLLECTIONS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_LAUNCHPAD_COLLECTIONS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            skip: action.skip,
            limit: action.limit,
            total: action.total,
        };
    }
    case FETCH_LAUNCHPAD_COLLECTIONS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const launchpadCollectionSearch = (state = {
    value: '',
}, action) => {
    if (action.type === LAUNCHPAD_COLLECTION_SEARCH_SET) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

const unVerifiedCollectionSearch = (state = {
    value: '',
}, action) => {
    if (action.type === UN_VERIFIED_COLLECTION_SEARCH_SET) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

const stats = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_STATS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    }
    case FETCH_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const marketStats = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_MARKET_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_MARKET_STATS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    }
    case FETCH_MARKET_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const auctions = (state = {
    tabValue: 'recent',
    inProgress: false,
    recent: [],
    bids: [],
    upcoming: [],
}, action) => {
    switch (action.type) {
    case HOME_AUCTIONS_TAB_SET:
        return {
            ...state,
            tabValue: action.value,
        };
    case RECENT_AUCTIONS_FETCH_IN_PROGRESS:
    case BIDS_AUCTIONS_FETCH_IN_PROGRESS:
    case UPCOMING_AUCTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case RECENT_AUCTIONS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            recent: action.result,
        };
    }
    case BIDS_AUCTIONS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            bids: action.result,
        };
    }
    case UPCOMING_AUCTIONS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            upcoming: action.result,
        };
    }
    case RECENT_AUCTIONS_FETCH_ERROR:
    case BIDS_AUCTIONS_FETCH_ERROR:
    case UPCOMING_AUCTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const listings = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case TOP_LISTINGS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TOP_LISTINGS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    }
    case TOP_LISTINGS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const campaigns = (state = {
    inProgress: false,
    result: [],
}, action) => {
    switch (action.type) {
    case TOP_CAMPAIGNS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TOP_CAMPAIGNS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    }
    case TOP_CAMPAIGNS_FETCH_ERROR: {
        return {
            ...state,
            inProgress: false,
        };
    }

    default:
        return state;
    }
};

const launchpadTab = (state = {
    value: 'live',
}, action) => {
    if (action.type === LAUNCHPAD_TAB_SET) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

const liveLaunchpad = (state = {
    inProgress: false,
    result: [],
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case LIVE_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case LIVE_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            total: action.total,
        };
    }
    case LIVE_LAUNCHPAD_COLLECTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const upcomingLaunchpad = (state = {
    inProgress: false,
    result: [],
    total: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.value,
            total: action.total,
        };
    }
    case UPCOMING_LAUNCHPAD_COLLECTIONS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const flixFridayBannersList = (state = {
    list: [],
    inProgress: false,
}, action) => {
    switch (action.type) {
    case FLIX_FRIDAY_BANNER_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FLIX_FRIDAY_BANNER_FETCH_SUCCESS:
        return {
            ...state,
            list: action.value,
            inProgress: false,
        };
    case FLIX_FRIDAY_BANNER_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    featuredCollections,
    launchpadCollections,
    launchpadCollectionSearch,
    unVerifiedCollectionSearch,
    stats,
    marketStats,
    auctions,
    listings,
    campaigns,
    launchpadTab,
    liveLaunchpad,
    upcomingLaunchpad,
    flixFridayBannersList,
});
