export const PLAYER_SET = 'PLAYER_SET';
export const LISTING_ACTIVE_SORT_SET = 'LISTING_ACTIVE_SORT_SET';
export const LISTING_SEARCH_SET = 'LISTING_SEARCH_SET';
export const COLLECTIONS_SEARCH_SET = 'COLLECTIONS_SEARCH_SET';

export const FETCH_LISTINGS_SUCCESS = 'FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_IN_PROGRESS = 'FETCH_LISTINGS_IN_PROGRESS';
export const FETCH_LISTINGS_ERROR = 'FETCH_LISTINGS_ERROR';
export const CANCEL_FETCH_LISTINGS = 'CANCEL_FETCH_LISTINGS';

export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTIONS_IN_PROGRESS = 'FETCH_COLLECTIONS_IN_PROGRESS';
export const FETCH_COLLECTIONS_ERROR = 'FETCH_COLLECTIONS_ERROR';

export const FETCH_UN_VERIFIED_COLLECTIONS_SUCCESS = 'FETCH_UN_VERIFIED_COLLECTIONS_SUCCESS';
export const FETCH_UN_VERIFIED_COLLECTIONS_IN_PROGRESS = 'FETCH_UN_VERIFIED_COLLECTIONS_IN_PROGRESS';
export const FETCH_UN_VERIFIED_COLLECTIONS_ERROR = 'FETCH_UN_VERIFIED_COLLECTIONS_ERROR';

export const FETCH_COLLECTIONS_TABLE_SUCCESS = 'FETCH_COLLECTIONS_TABLE_SUCCESS';
export const FETCH_COLLECTIONS_TABLE_IN_PROGRESS = 'FETCH_COLLECTIONS_TABLE_IN_PROGRESS';
export const FETCH_COLLECTIONS_TABLE_ERROR = 'FETCH_COLLECTIONS_TABLE_ERROR';

export const FETCH_NFT_SUCCESS = 'FETCH_NFT_SUCCESS';
export const FETCH_NFT_ERROR = 'FETCH_NFT_ERROR';
export const FETCH_NFT_IN_PROGRESS = 'FETCH_NFT_IN_PROGRESS';

export const FETCH_COLLECTION_NFT_SUCCESS = 'FETCH_COLLECTION_NFT_SUCCESS';
export const FETCH_COLLECTION_NFT_ERROR = 'FETCH_COLLECTION_NFT_ERROR';
export const FETCH_COLLECTION_NFT_IN_PROGRESS = 'FETCH_COLLECTION_NFT_IN_PROGRESS';

export const FETCH_NFT_ACTIVITY_SUCCESS = 'FETCH_NFT_ACTIVITY_SUCCESS';
export const FETCH_NFT_ACTIVITY_ERROR = 'FETCH_NFT_ACTIVITY_ERROR';
export const FETCH_NFT_ACTIVITY_IN_PROGRESS = 'FETCH_NFT_ACTIVITY_IN_PROGRESS';

export const FETCH_AUCTION_BID_HISTORY_SUCCESS = 'FETCH_AUCTION_BID_HISTORY_SUCCESS';
export const FETCH_AUCTION_BID_HISTORY_ERROR = 'FETCH_AUCTION_BID_HISTORY_ERROR';
export const FETCH_AUCTION_BID_HISTORY_IN_PROGRESS = 'FETCH_AUCTION_BID_HISTORY_IN_PROGRESS';

export const FETCH_COLLECTION_SUCCESS = 'FETCH_COLLECTION_SUCCESS';
export const FETCH_COLLECTION_ERROR = 'FETCH_COLLECTION_ERROR';
export const FETCH_COLLECTION_IN_PROGRESS = 'FETCH_COLLECTION_IN_PROGRESS';

export const FETCH_ACCOUNT_TOKENS_TRADED_SUCCESS = 'FETCH_ACCOUNT_TOKENS_TRADED_SUCCESS';
export const FETCH_ACCOUNT_TOKENS_TRADED_ERROR = 'FETCH_ACCOUNT_TOKENS_TRADED_ERROR';
export const FETCH_ACCOUNT_TOKENS_TRADED_IN_PROGRESS = 'FETCH_ACCOUNT_TOKENS_TRADED_IN_PROGRESS';

export const IBC_TOKENS_LIST_FETCH_IN_PROGRESS = 'IBC_TOKENS_LIST_FETCH_IN_PROGRESS';
export const IBC_TOKENS_LIST_FETCH_SUCCESS = 'IBC_TOKENS_LIST_FETCH_SUCCESS';
export const IBC_TOKENS_LIST_FETCH_ERROR = 'IBC_TOKENS_LIST_FETCH_ERROR';

export const EMPTY_SINGLE_ASSET_SET = 'EMPTY_SINGLE_ASSET_SET';

export const TRANSFER_DIALOG_SHOW = 'TRANSFER_DIALOG_SHOW';
export const TRANSFER_DIALOG_HIDE = 'TRANSFER_DIALOG_HIDE';
export const RECIPENT_ACCOUNT_ADDRESS_SET = 'RECIPENT_ACCOUNT_ADDRESS_SET';

export const SELL_DIALOG_SHOW = 'SELL_DIALOG_SHOW';
export const SELL_DIALOG_HIDE = 'SELL_DIALOG_HIDE';
export const SELL_TOKEN_PRICE_SET = 'SELL_TOKEN_PRICE_SET';
export const SELL_TOKEN_VALUE_SET = 'SELL_TOKEN_VALUE_SET';
export const BID_PERCENTAGE_SET = 'BID_PERCENTAGE_SET';

export const DELIST_DIALOG_SHOW = 'DELIST_DIALOG_SHOW';
export const DELIST_DIALOG_HIDE = 'DELIST_DIALOG_HIDE';

export const JSON_SCHEMA_DIALOG_SHOW = 'JSON_SCHEMA_DIALOG_SHOW';
export const JSON_SCHEMA_DIALOG_HIDE = 'JSON_SCHEMA_DIALOG_HIDE';

export const BUY_NFT_SUCCESS_DIALOG_SHOW = 'BUY_NFT_SUCCESS_DIALOG_SHOW';
export const BUY_NFT_SUCCESS_DIALOG_HIDE = 'BUY_NFT_SUCCESS_DIALOG_HIDE';

export const SPLIT_INFO_SET = 'SPLIT_INFO_SET';
export const SPLIT_INFO_STATUS_SET = 'SPLIT_INFO_STATUS_SET';
export const WHITELIST_SWITCH_SET = 'WHITELIST_SWITCH_SET';
export const WHITELIST_VALUE_SET = 'WHITELIST_VALUE_SET';
export const START_DATE_SET = 'START_DATE_SET';
export const END_DATE_SET = 'END_DATE_SET';
export const LIST_TYPE_SET = 'LIST_TYPE_SET';

export const CONFIRM_LISTING_SET = 'CONFIRM_LISTING_SET';
export const CONFIRM_DE_LIST_SET = 'CONFIRM_DE_LIST_SET';
export const CONFIRM_TRANSFER_SET = 'CONFIRM_TRANSFER_SET';

export const MARKETPLACE_TABS_SET = 'MARKETPLACE_TABS_SET';

export const BURN_DIALOG_SHOW = 'BURN_DIALOG_SHOW';
export const BURN_DIALOG_HIDE = 'BURN_DIALOG_HIDE';
export const BURN_DIALOG_SUCCESS_SET = 'BURN_DIALOG_SUCCESS_SET';
export const BURN_DIALOG_FAILED_SET = 'BURN_DIALOG_FAILED_SET';
export const MENU_POPOVER_SHOW = 'MENU_POPOVER_SHOW';
export const MENU_POPOVER_HIDE = 'MENU_POPOVER_HIDE';
export const BURN_ID_SET = 'BURN_ID_SET';

export const FETCH_LAUNCHPAD_COLLECTIONS_LIST_IN_PROGRESS = 'FETCH_LAUNCHPAD_COLLECTIONS_LIST_IN_PROGRESS';
export const FETCH_LAUNCHPAD_COLLECTIONS_LIST_SUCCESS = 'FETCH_LAUNCHPAD_COLLECTIONS_LIST_SUCCESS';
export const FETCH_LAUNCHPAD_COLLECTIONS_LIST_ERROR = 'FETCH_LAUNCHPAD_COLLECTIONS_LIST_ERROR';

export const MARKETPLACE_NFT_S_VIEW_SET = 'MARKETPLACE_NFT_S_VIEW_SET';
export const MARKETPLACE_FILTER_SET = 'MARKETPLACE_FILTER_SET';
export const MARKETPLACE_FILTER_SEARCH_SET = 'MARKETPLACE_FILTER_SEARCH_SET';
export const MARKETPLACE_FILTER_CLEAR_SET = 'MARKETPLACE_FILTER_CLEAR_SET';

export const MARKETPLACE_ON_SALE_IN_FILTER_SET = 'MARKETPLACE_ON_SALE_IN_FILTER_SET';
