import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import logo from '../../../assets/wallets/metaMask.svg';
import variables from '../../../utils/variables';
import { hideConnectDialog } from '../../../actions/navbar';
import { Button } from '@mui/material';
import { initializeMetaMask } from '../../../actions/account/metaMask';
import { fetchAllowances, fetchBalance } from '../../../actions/account/BCDetails';
import { showMessage } from '../../../actions/snackbar';

const MetaMaskButton = (props) => {
    const [inProgress, setInProgress] = useState(false);

    const initializeWallet = () => {
        setInProgress(true);
        props.initializeMetaMask((address, retry) => {
            setInProgress(false);
            if (retry) {
                window.onload = () => initializeWallet();
                return;
            }

            localStorage.setItem('of_market_address', address && address.length && address[0] && address[0].address);
            if (address && address.length && address[0] && address[0].address) {
                props.fetchBalance(address[0].address);
            }
            if (address && address.length && address[0] && address[0].address) {
                props.fetchAllowances(address[0].address);
            }
            props.hideConnectDialog();
            localStorage.setItem('of_market_metamask', 'metamask');
        });
    };

    const connectInProgress = inProgress;
    return (
        <Button
            className="leap_button"
            disabled={connectInProgress}
            variant="contained"
            onClick={initializeWallet}>
            <img alt="logo" src={logo}/>
            {connectInProgress ? variables[props.lang].connecting + '...' : variables[props.lang].metamask}
        </Button>
    );
};

MetaMaskButton.propTypes = {
    fetchAllowances: PropTypes.func.isRequired,
    fetchBalance: PropTypes.func.isRequired,
    hideConnectDialog: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    initializeMetaMask: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    showMessage: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        inProgress: state.account.wallet.connection.inProgress,
        lang: state.language,
    };
};

const actionsToProps = {
    fetchAllowances,
    fetchBalance,
    hideConnectDialog,
    showMessage,
    initializeMetaMask,
};

export default connect(stateToProps, actionsToProps)(MetaMaskButton);
