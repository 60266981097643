import { useEffect } from 'react';
import withRouter from '../WithRouter';
import * as PropTypes from 'prop-types';

const SingleCollectionActivity = (props) => {
    useEffect(() => {
        const id = props.router && props.router.params && props.router.params.id;
        props.router.navigate('/c/' + id + '/activity');
    }, []);

    return null;
};

SingleCollectionActivity.propTypes = {
    router: PropTypes.shape({
        navigate: PropTypes.func.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

export default withRouter(SingleCollectionActivity);
