import { combineReducers } from 'redux';
import {
    MARKETPLACE_PARAMS_FETCH_ERROR,
    MARKETPLACE_PARAMS_FETCH_IN_PROGRESS,
    MARKETPLACE_PARAMS_FETCH_SUCCESS,
} from '../constants/params';

const marketPlaceParams = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case MARKETPLACE_PARAMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case MARKETPLACE_PARAMS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case MARKETPLACE_PARAMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

export default combineReducers({
    marketPlaceParams,
});
