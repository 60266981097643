import React from 'react';
import './index.css';
import * as PropTypes from 'prop-types';

const NoData = (props) => {
    return (
        <div className="no_data">
            {props.text
                ? <p>{props.text}</p>
                : <p>No Data</p>}
        </div>
    );
};

NoData.propTypes = {
    text: PropTypes.string,
};

export default NoData;
