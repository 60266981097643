import React from 'react';
import { Button } from '@mui/material';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import walletIcon from '../../assets/navbar/wallet.svg';
// import keplrIcon from '../../assets/wallets/keplr.png';
import { showConnectDialog } from '../../actions/navbar';

const ConnectButton = (props) => {
    return (
        <Button className="connect_button" onClick={props.showConnectDialog}>
            {/* {props.fullText */}
            {/*    ? <> */}
            {/*        {variables[props.lang]['connect_wallet']} */}
            {/*    </> */}
            {/*    : <> */}
            <img alt="wallet" height="20" src={walletIcon} width="20"/>
            <p>{variables[props.lang].connect}</p>
            {/* </>} */}
        </Button>
    );
};

ConnectButton.propTypes = {
    lang: PropTypes.string.isRequired,
    showConnectDialog: PropTypes.func.isRequired,
    fullText: PropTypes.bool,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

const actionToProps = {
    showConnectDialog,
};

export default connect(stateToProps, actionToProps)(ConnectButton);
