import React from 'react';
import './index.css';
import Skeleton from '@mui/material/Skeleton';

const FeaturedNFTSkeletonLoader = () => {
    return (
        <div className="auto_play">
            <Skeleton
                animation="wave"
                className="skeleton_loader"/>
        </div>
    );
};

export default FeaturedNFTSkeletonLoader;
