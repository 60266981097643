/* eslint-disable */
import Long from "long";
import * as _m0 from "protobufjs/minimal";
import {Coin} from "../../cosmos/base/v1beta1/coin";
import {Duration} from "../../google/protobuf/duration";
import {Timestamp} from "../../google/protobuf/timestamp";

export const protobufPackage = "OmniFlix.itc.v1";

export enum DistributionType {
    DISTRIBUTION_TYPE_INSTANT = 0,
    DISTRIBUTION_TYPE_STREAM = 1,
    UNRECOGNIZED = -1,
}

export function distributionTypeFromJSON(object: any): DistributionType {
    switch (object) {
        case 0:
        case "DISTRIBUTION_TYPE_INSTANT":
            return DistributionType.DISTRIBUTION_TYPE_INSTANT;
        case 1:
        case "DISTRIBUTION_TYPE_STREAM":
            return DistributionType.DISTRIBUTION_TYPE_STREAM;
        case -1:
        case "UNRECOGNIZED":
        default:
            return DistributionType.UNRECOGNIZED;
    }
}

export function distributionTypeToJSON(object: DistributionType): string {
    switch (object) {
        case DistributionType.DISTRIBUTION_TYPE_INSTANT:
            return "DISTRIBUTION_TYPE_INSTANT";
        case DistributionType.DISTRIBUTION_TYPE_STREAM:
            return "DISTRIBUTION_TYPE_STREAM";
        case DistributionType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}

export enum InteractionType {
    INTERACTION_TYPE_BURN = 0,
    INTERACTION_TYPE_TRANSFER = 1,
    INTERACTION_TYPE_HOLD = 2,
    UNRECOGNIZED = -1,
}

export function interactionTypeFromJSON(object: any): InteractionType {
    switch (object) {
        case 0:
        case "INTERACTION_TYPE_BURN":
            return InteractionType.INTERACTION_TYPE_BURN;
        case 1:
        case "INTERACTION_TYPE_TRANSFER":
            return InteractionType.INTERACTION_TYPE_TRANSFER;
        case 2:
        case "INTERACTION_TYPE_HOLD":
            return InteractionType.INTERACTION_TYPE_HOLD;
        case -1:
        case "UNRECOGNIZED":
        default:
            return InteractionType.UNRECOGNIZED;
    }
}

export function interactionTypeToJSON(object: InteractionType): string {
    switch (object) {
        case InteractionType.INTERACTION_TYPE_BURN:
            return "INTERACTION_TYPE_BURN";
        case InteractionType.INTERACTION_TYPE_TRANSFER:
            return "INTERACTION_TYPE_TRANSFER";
        case InteractionType.INTERACTION_TYPE_HOLD:
            return "INTERACTION_TYPE_HOLD";
        case InteractionType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}

export enum CampaignStatus {
    CAMPAIGN_STATUS_UNSPECIFIED = 0,
    CAMPAIGN_STATUS_INACTIVE = 1,
    CAMPAIGN_STATUS_ACTIVE = 2,
    UNRECOGNIZED = -1,
}

export function campaignStatusFromJSON(object: any): CampaignStatus {
    switch (object) {
        case 0:
        case "CAMPAIGN_STATUS_UNSPECIFIED":
            return CampaignStatus.CAMPAIGN_STATUS_UNSPECIFIED;
        case 1:
        case "CAMPAIGN_STATUS_INACTIVE":
            return CampaignStatus.CAMPAIGN_STATUS_INACTIVE;
        case 2:
        case "CAMPAIGN_STATUS_ACTIVE":
            return CampaignStatus.CAMPAIGN_STATUS_ACTIVE;
        case -1:
        case "UNRECOGNIZED":
        default:
            return CampaignStatus.UNRECOGNIZED;
    }
}

export function campaignStatusToJSON(object: CampaignStatus): string {
    switch (object) {
        case CampaignStatus.CAMPAIGN_STATUS_UNSPECIFIED:
            return "CAMPAIGN_STATUS_UNSPECIFIED";
        case CampaignStatus.CAMPAIGN_STATUS_INACTIVE:
            return "CAMPAIGN_STATUS_INACTIVE";
        case CampaignStatus.CAMPAIGN_STATUS_ACTIVE:
            return "CAMPAIGN_STATUS_ACTIVE";
        case CampaignStatus.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}

export enum ClaimType {
    CLAIM_TYPE_FT = 0,
    CLAIM_TYPE_NFT = 1,
    CLAIM_TYPE_FT_AND_NFT = 2,
    UNRECOGNIZED = -1,
}

export function claimTypeFromJSON(object: any): ClaimType {
    switch (object) {
        case 0:
        case "CLAIM_TYPE_FT":
            return ClaimType.CLAIM_TYPE_FT;
        case 1:
        case "CLAIM_TYPE_NFT":
            return ClaimType.CLAIM_TYPE_NFT;
        case 2:
        case "CLAIM_TYPE_FT_AND_NFT":
            return ClaimType.CLAIM_TYPE_FT_AND_NFT;
        case -1:
        case "UNRECOGNIZED":
        default:
            return ClaimType.UNRECOGNIZED;
    }
}

export function claimTypeToJSON(object: ClaimType): string {
    switch (object) {
        case ClaimType.CLAIM_TYPE_FT:
            return "CLAIM_TYPE_FT";
        case ClaimType.CLAIM_TYPE_NFT:
            return "CLAIM_TYPE_NFT";
        case ClaimType.CLAIM_TYPE_FT_AND_NFT:
            return "CLAIM_TYPE_FT_AND_NFT";
        case ClaimType.UNRECOGNIZED:
        default:
            return "UNRECOGNIZED";
    }
}

export interface Campaign {
    id: number;
    name: string;
    description: string;
    startTime: Date | undefined;
    endTime: Date | undefined;
    creator: string;
    nftDenomId: string;
    maxAllowedClaims: number;
    interaction: InteractionType;
    claimType: ClaimType;
    tokensPerClaim: Coin | undefined;
    totalTokens: Coin | undefined;
    availableTokens: Coin | undefined;
    receivedNftIds: string[];
    nftMintDetails: NFTDetails | undefined;
    distribution: Distribution | undefined;
    mintCount: number;
}

export interface Distribution {
    type: DistributionType;
    streamDuration: Duration | undefined;
}

export interface NFTDetails {
    denomId: string;
    name: string;
    description: string;
    mediaUri: string;
    previewUri: string;
    royaltyShare: string;
    transferable: boolean;
    extensible: boolean;
    nsfw: boolean;
    data: string;
}

export interface Claim {
    campaignId: number;
    address: string;
    nftId: string;
    interaction: InteractionType;
}

function createBaseCampaign(): Campaign {
    return {
        id: 0,
        name: "",
        description: "",
        startTime: undefined,
        endTime: undefined,
        creator: "",
        nftDenomId: "",
        maxAllowedClaims: 0,
        interaction: 0,
        claimType: 0,
        tokensPerClaim: undefined,
        totalTokens: undefined,
        availableTokens: undefined,
        receivedNftIds: [],
        nftMintDetails: undefined,
        distribution: undefined,
        mintCount: 0,
    };
}

export const Campaign = {
    encode(message: Campaign, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.id !== 0) {
            writer.uint32(8).uint64(message.id);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.description !== "") {
            writer.uint32(26).string(message.description);
        }
        if (message.startTime !== undefined) {
            Timestamp.encode(toTimestamp(message.startTime), writer.uint32(34).fork()).ldelim();
        }
        if (message.endTime !== undefined) {
            Timestamp.encode(toTimestamp(message.endTime), writer.uint32(42).fork()).ldelim();
        }
        if (message.creator !== "") {
            writer.uint32(50).string(message.creator);
        }
        if (message.nftDenomId !== "") {
            writer.uint32(58).string(message.nftDenomId);
        }
        if (message.maxAllowedClaims !== 0) {
            writer.uint32(64).uint64(message.maxAllowedClaims);
        }
        if (message.interaction !== 0) {
            writer.uint32(72).int32(message.interaction);
        }
        if (message.claimType !== 0) {
            writer.uint32(80).int32(message.claimType);
        }
        if (message.tokensPerClaim !== undefined) {
            Coin.encode(message.tokensPerClaim, writer.uint32(90).fork()).ldelim();
        }
        if (message.totalTokens !== undefined) {
            Coin.encode(message.totalTokens, writer.uint32(98).fork()).ldelim();
        }
        if (message.availableTokens !== undefined) {
            Coin.encode(message.availableTokens, writer.uint32(106).fork()).ldelim();
        }
        for (const v of message.receivedNftIds) {
            writer.uint32(114).string(v!);
        }
        if (message.nftMintDetails !== undefined) {
            NFTDetails.encode(message.nftMintDetails, writer.uint32(122).fork()).ldelim();
        }
        if (message.distribution !== undefined) {
            Distribution.encode(message.distribution, writer.uint32(130).fork()).ldelim();
        }
        if (message.mintCount !== 0) {
            writer.uint32(136).uint64(message.mintCount);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Campaign {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseCampaign();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }

                    message.id = longToNumber(reader.uint64() as Long);
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.name = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }

                    message.description = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }

                    message.startTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }

                    message.endTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }

                    message.creator = reader.string();
                    continue;
                case 7:
                    if (tag !== 58) {
                        break;
                    }

                    message.nftDenomId = reader.string();
                    continue;
                case 8:
                    if (tag !== 64) {
                        break;
                    }

                    message.maxAllowedClaims = longToNumber(reader.uint64() as Long);
                    continue;
                case 9:
                    if (tag !== 72) {
                        break;
                    }

                    message.interaction = reader.int32() as any;
                    continue;
                case 10:
                    if (tag !== 80) {
                        break;
                    }

                    message.claimType = reader.int32() as any;
                    continue;
                case 11:
                    if (tag !== 90) {
                        break;
                    }

                    message.tokensPerClaim = Coin.decode(reader, reader.uint32());
                    continue;
                case 12:
                    if (tag !== 98) {
                        break;
                    }

                    message.totalTokens = Coin.decode(reader, reader.uint32());
                    continue;
                case 13:
                    if (tag !== 106) {
                        break;
                    }

                    message.availableTokens = Coin.decode(reader, reader.uint32());
                    continue;
                case 14:
                    if (tag !== 114) {
                        break;
                    }

                    message.receivedNftIds.push(reader.string());
                    continue;
                case 15:
                    if (tag !== 122) {
                        break;
                    }

                    message.nftMintDetails = NFTDetails.decode(reader, reader.uint32());
                    continue;
                case 16:
                    if (tag !== 130) {
                        break;
                    }

                    message.distribution = Distribution.decode(reader, reader.uint32());
                    continue;
                case 17:
                    if (tag !== 136) {
                        break;
                    }

                    message.mintCount = longToNumber(reader.uint64() as Long);
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): Campaign {
        return {
            id: isSet(object.id) ? Number(object.id) : 0,
            name: isSet(object.name) ? String(object.name) : "",
            description: isSet(object.description) ? String(object.description) : "",
            startTime: isSet(object.startTime) ? fromJsonTimestamp(object.startTime) : undefined,
            endTime: isSet(object.endTime) ? fromJsonTimestamp(object.endTime) : undefined,
            creator: isSet(object.creator) ? String(object.creator) : "",
            nftDenomId: isSet(object.nftDenomId) ? String(object.nftDenomId) : "",
            maxAllowedClaims: isSet(object.maxAllowedClaims) ? Number(object.maxAllowedClaims) : 0,
            interaction: isSet(object.interaction) ? interactionTypeFromJSON(object.interaction) : 0,
            claimType: isSet(object.claimType) ? claimTypeFromJSON(object.claimType) : 0,
            tokensPerClaim: isSet(object.tokensPerClaim) ? Coin.fromJSON(object.tokensPerClaim) : undefined,
            totalTokens: isSet(object.totalTokens) ? Coin.fromJSON(object.totalTokens) : undefined,
            availableTokens: isSet(object.availableTokens) ? Coin.fromJSON(object.availableTokens) : undefined,
            receivedNftIds: Array.isArray(object?.receivedNftIds) ? object.receivedNftIds.map((e: any) => String(e)) : [],
            nftMintDetails: isSet(object.nftMintDetails) ? NFTDetails.fromJSON(object.nftMintDetails) : undefined,
            distribution: isSet(object.distribution) ? Distribution.fromJSON(object.distribution) : undefined,
            mintCount: isSet(object.mintCount) ? Number(object.mintCount) : 0,
        };
    },

    toJSON(message: Campaign): unknown {
        const obj: any = {};
        message.id !== undefined && (obj.id = Math.round(message.id));
        message.name !== undefined && (obj.name = message.name);
        message.description !== undefined && (obj.description = message.description);
        message.startTime !== undefined && (obj.startTime = message.startTime.toISOString());
        message.endTime !== undefined && (obj.endTime = message.endTime.toISOString());
        message.creator !== undefined && (obj.creator = message.creator);
        message.nftDenomId !== undefined && (obj.nftDenomId = message.nftDenomId);
        message.maxAllowedClaims !== undefined && (obj.maxAllowedClaims = Math.round(message.maxAllowedClaims));
        message.interaction !== undefined && (obj.interaction = interactionTypeToJSON(message.interaction));
        message.claimType !== undefined && (obj.claimType = claimTypeToJSON(message.claimType));
        message.tokensPerClaim !== undefined &&
        (obj.tokensPerClaim = message.tokensPerClaim ? Coin.toJSON(message.tokensPerClaim) : undefined);
        message.totalTokens !== undefined &&
        (obj.totalTokens = message.totalTokens ? Coin.toJSON(message.totalTokens) : undefined);
        message.availableTokens !== undefined &&
        (obj.availableTokens = message.availableTokens ? Coin.toJSON(message.availableTokens) : undefined);
        if (message.receivedNftIds) {
            obj.receivedNftIds = message.receivedNftIds.map((e) => e);
        } else {
            obj.receivedNftIds = [];
        }
        message.nftMintDetails !== undefined &&
        (obj.nftMintDetails = message.nftMintDetails ? NFTDetails.toJSON(message.nftMintDetails) : undefined);
        message.distribution !== undefined &&
        (obj.distribution = message.distribution ? Distribution.toJSON(message.distribution) : undefined);
        message.mintCount !== undefined && (obj.mintCount = Math.round(message.mintCount));
        return obj;
    },

    create<I extends Exact<DeepPartial<Campaign>, I>>(base?: I): Campaign {
        return Campaign.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<Campaign>, I>>(object: I): Campaign {
        const message = createBaseCampaign();
        message.id = object.id ?? 0;
        message.name = object.name ?? "";
        message.description = object.description ?? "";
        message.startTime = object.startTime ?? undefined;
        message.endTime = object.endTime ?? undefined;
        message.creator = object.creator ?? "";
        message.nftDenomId = object.nftDenomId ?? "";
        message.maxAllowedClaims = object.maxAllowedClaims ?? 0;
        message.interaction = object.interaction ?? 0;
        message.claimType = object.claimType ?? 0;
        message.tokensPerClaim = (object.tokensPerClaim !== undefined && object.tokensPerClaim !== null)
            ? Coin.fromPartial(object.tokensPerClaim)
            : undefined;
        message.totalTokens = (object.totalTokens !== undefined && object.totalTokens !== null)
            ? Coin.fromPartial(object.totalTokens)
            : undefined;
        message.availableTokens = (object.availableTokens !== undefined && object.availableTokens !== null)
            ? Coin.fromPartial(object.availableTokens)
            : undefined;
        message.receivedNftIds = object.receivedNftIds?.map((e) => e) || [];
        message.nftMintDetails = (object.nftMintDetails !== undefined && object.nftMintDetails !== null)
            ? NFTDetails.fromPartial(object.nftMintDetails)
            : undefined;
        message.distribution = (object.distribution !== undefined && object.distribution !== null)
            ? Distribution.fromPartial(object.distribution)
            : undefined;
        message.mintCount = object.mintCount ?? 0;
        return message;
    },
};

function createBaseDistribution(): Distribution {
    return {type: 0, streamDuration: undefined};
}

export const Distribution = {
    encode(message: Distribution, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.type !== 0) {
            writer.uint32(8).int32(message.type);
        }
        if (message.streamDuration !== undefined) {
            Duration.encode(message.streamDuration, writer.uint32(18).fork()).ldelim();
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Distribution {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseDistribution();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }

                    message.type = reader.int32() as any;
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.streamDuration = Duration.decode(reader, reader.uint32());
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): Distribution {
        return {
            type: isSet(object.type) ? distributionTypeFromJSON(object.type) : 0,
            streamDuration: isSet(object.streamDuration) ? Duration.fromJSON(object.streamDuration) : undefined,
        };
    },

    toJSON(message: Distribution): unknown {
        const obj: any = {};
        message.type !== undefined && (obj.type = distributionTypeToJSON(message.type));
        message.streamDuration !== undefined &&
        (obj.streamDuration = message.streamDuration ? Duration.toJSON(message.streamDuration) : undefined);
        return obj;
    },

    create<I extends Exact<DeepPartial<Distribution>, I>>(base?: I): Distribution {
        return Distribution.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<Distribution>, I>>(object: I): Distribution {
        const message = createBaseDistribution();
        message.type = object.type ?? 0;
        message.streamDuration = (object.streamDuration !== undefined && object.streamDuration !== null)
            ? Duration.fromPartial(object.streamDuration)
            : undefined;
        return message;
    },
};

function createBaseNFTDetails(): NFTDetails {
    return {
        denomId: "",
        name: "",
        description: "",
        mediaUri: "",
        previewUri: "",
        royaltyShare: "",
        transferable: false,
        extensible: false,
        nsfw: false,
        data: "",
    };
}

export const NFTDetails = {
    encode(message: NFTDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.denomId !== "") {
            writer.uint32(10).string(message.denomId);
        }
        if (message.name !== "") {
            writer.uint32(18).string(message.name);
        }
        if (message.description !== "") {
            writer.uint32(26).string(message.description);
        }
        if (message.mediaUri !== "") {
            writer.uint32(34).string(message.mediaUri);
        }
        if (message.previewUri !== "") {
            writer.uint32(42).string(message.previewUri);
        }
        if (message.royaltyShare !== "") {
            writer.uint32(50).string(message.royaltyShare);
        }
        if (message.transferable === true) {
            writer.uint32(56).bool(message.transferable);
        }
        if (message.extensible === true) {
            writer.uint32(64).bool(message.extensible);
        }
        if (message.nsfw === true) {
            writer.uint32(72).bool(message.nsfw);
        }
        if (message.data !== "") {
            writer.uint32(82).string(message.data);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): NFTDetails {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseNFTDetails();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 10) {
                        break;
                    }

                    message.denomId = reader.string();
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.name = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }

                    message.description = reader.string();
                    continue;
                case 4:
                    if (tag !== 34) {
                        break;
                    }

                    message.mediaUri = reader.string();
                    continue;
                case 5:
                    if (tag !== 42) {
                        break;
                    }

                    message.previewUri = reader.string();
                    continue;
                case 6:
                    if (tag !== 50) {
                        break;
                    }

                    message.royaltyShare = reader.string();
                    continue;
                case 7:
                    if (tag !== 56) {
                        break;
                    }

                    message.transferable = reader.bool();
                    continue;
                case 8:
                    if (tag !== 64) {
                        break;
                    }

                    message.extensible = reader.bool();
                    continue;
                case 9:
                    if (tag !== 72) {
                        break;
                    }

                    message.nsfw = reader.bool();
                    continue;
                case 10:
                    if (tag !== 82) {
                        break;
                    }

                    message.data = reader.string();
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): NFTDetails {
        return {
            denomId: isSet(object.denomId) ? String(object.denomId) : "",
            name: isSet(object.name) ? String(object.name) : "",
            description: isSet(object.description) ? String(object.description) : "",
            mediaUri: isSet(object.mediaUri) ? String(object.mediaUri) : "",
            previewUri: isSet(object.previewUri) ? String(object.previewUri) : "",
            royaltyShare: isSet(object.royaltyShare) ? String(object.royaltyShare) : "",
            transferable: isSet(object.transferable) ? Boolean(object.transferable) : false,
            extensible: isSet(object.extensible) ? Boolean(object.extensible) : false,
            nsfw: isSet(object.nsfw) ? Boolean(object.nsfw) : false,
            data: isSet(object.data) ? String(object.data) : "",
        };
    },

    toJSON(message: NFTDetails): unknown {
        const obj: any = {};
        message.denomId !== undefined && (obj.denomId = message.denomId);
        message.name !== undefined && (obj.name = message.name);
        message.description !== undefined && (obj.description = message.description);
        message.mediaUri !== undefined && (obj.mediaUri = message.mediaUri);
        message.previewUri !== undefined && (obj.previewUri = message.previewUri);
        message.royaltyShare !== undefined && (obj.royaltyShare = message.royaltyShare);
        message.transferable !== undefined && (obj.transferable = message.transferable);
        message.extensible !== undefined && (obj.extensible = message.extensible);
        message.nsfw !== undefined && (obj.nsfw = message.nsfw);
        message.data !== undefined && (obj.data = message.data);
        return obj;
    },

    create<I extends Exact<DeepPartial<NFTDetails>, I>>(base?: I): NFTDetails {
        return NFTDetails.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<NFTDetails>, I>>(object: I): NFTDetails {
        const message = createBaseNFTDetails();
        message.denomId = object.denomId ?? "";
        message.name = object.name ?? "";
        message.description = object.description ?? "";
        message.mediaUri = object.mediaUri ?? "";
        message.previewUri = object.previewUri ?? "";
        message.royaltyShare = object.royaltyShare ?? "";
        message.transferable = object.transferable ?? false;
        message.extensible = object.extensible ?? false;
        message.nsfw = object.nsfw ?? false;
        message.data = object.data ?? "";
        return message;
    },
};

function createBaseClaim(): Claim {
    return {campaignId: 0, address: "", nftId: "", interaction: 0};
}

export const Claim = {
    encode(message: Claim, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
        if (message.campaignId !== 0) {
            writer.uint32(8).uint64(message.campaignId);
        }
        if (message.address !== "") {
            writer.uint32(18).string(message.address);
        }
        if (message.nftId !== "") {
            writer.uint32(26).string(message.nftId);
        }
        if (message.interaction !== 0) {
            writer.uint32(32).int32(message.interaction);
        }
        return writer;
    },

    decode(input: _m0.Reader | Uint8Array, length?: number): Claim {
        const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
        let end = length === undefined ? reader.len : reader.pos + length;
        const message = createBaseClaim();
        while (reader.pos < end) {
            const tag = reader.uint32();
            switch (tag >>> 3) {
                case 1:
                    if (tag !== 8) {
                        break;
                    }

                    message.campaignId = longToNumber(reader.uint64() as Long);
                    continue;
                case 2:
                    if (tag !== 18) {
                        break;
                    }

                    message.address = reader.string();
                    continue;
                case 3:
                    if (tag !== 26) {
                        break;
                    }

                    message.nftId = reader.string();
                    continue;
                case 4:
                    if (tag !== 32) {
                        break;
                    }

                    message.interaction = reader.int32() as any;
                    continue;
            }
            if ((tag & 7) === 4 || tag === 0) {
                break;
            }
            reader.skipType(tag & 7);
        }
        return message;
    },

    fromJSON(object: any): Claim {
        return {
            campaignId: isSet(object.campaignId) ? Number(object.campaignId) : 0,
            address: isSet(object.address) ? String(object.address) : "",
            nftId: isSet(object.nftId) ? String(object.nftId) : "",
            interaction: isSet(object.interaction) ? interactionTypeFromJSON(object.interaction) : 0,
        };
    },

    toJSON(message: Claim): unknown {
        const obj: any = {};
        message.campaignId !== undefined && (obj.campaignId = Math.round(message.campaignId));
        message.address !== undefined && (obj.address = message.address);
        message.nftId !== undefined && (obj.nftId = message.nftId);
        message.interaction !== undefined && (obj.interaction = interactionTypeToJSON(message.interaction));
        return obj;
    },

    create<I extends Exact<DeepPartial<Claim>, I>>(base?: I): Claim {
        return Claim.fromPartial(base ?? {});
    },

    fromPartial<I extends Exact<DeepPartial<Claim>, I>>(object: I): Claim {
        const message = createBaseClaim();
        message.campaignId = object.campaignId ?? 0;
        message.address = object.address ?? "";
        message.nftId = object.nftId ?? "";
        message.interaction = object.interaction ?? 0;
        return message;
    },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
    if (typeof globalThis !== "undefined") {
        return globalThis;
    }
    if (typeof self !== "undefined") {
        return self;
    }
    if (typeof window !== "undefined") {
        return window;
    }
    if (typeof global !== "undefined") {
        return global;
    }
    throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
    : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
        : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
            : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
    : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
    const seconds = date.getTime() / 1_000;
    const nanos = (date.getTime() % 1_000) * 1_000_000;
    return {seconds, nanos};
}

function fromTimestamp(t: Timestamp): Date {
    let millis = (t.seconds || 0) * 1_000;
    millis += (t.nanos || 0) / 1_000_000;
    return new Date(millis);
}

function fromJsonTimestamp(o: any): Date {
    if (o instanceof Date) {
        return o;
    } else if (typeof o === "string") {
        return new Date(o);
    } else {
        return fromTimestamp(Timestamp.fromJSON(o));
    }
}

function longToNumber(long: Long): number {
    if (long.gt(Number.MAX_SAFE_INTEGER)) {
        throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
    }
    return long.toNumber();
}

// If you get a compile-error about 'Constructor<Long> and ... have no overlap',
// add '--ts_proto_opt=esModuleInterop=true' as a flag when calling 'protoc'.
if (_m0.util.Long !== Long) {
    _m0.util.Long = Long as any;
    _m0.configure();
}

function isSet(value: any): boolean {
    return value !== null && value !== undefined;
}
