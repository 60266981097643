import React from 'react';
import SkeletonLoader from '../SkeletonLoader';
import '../../containers/MarketPlace/index.css';
import '../../containers/SingleCollection/index.css';
import CollectionInfoSkeletonLoader from '../SkeletonLoader/CollectionInfo';
import Skeleton from '@mui/material/Skeleton';

const SingleCollection = () => {
    return (
        <div className="collection_page">
            <CollectionInfoSkeletonLoader/>
            <div className="horizontal_tabs" style={{ background: 'linear-gradient(180deg,#0e151b,#000)' }}>
                <Skeleton
                    animation="wave"
                    className="skeleton_loader"
                    height={63}
                    style={{ margin: 'auto' }}
                    width="90%"/>
            </div>
            <div className="explore_main">
                <div className="explore scroll_bar" style={{ overflow: 'unset' }}>
                    <div className="explore_header">
                        <Skeleton
                            animation="wave"
                            className="skeleton_loader"
                            height={49.88}
                            width="30%"/>
                    </div>
                    <div className="collection_nfts lists">
                        <SkeletonLoader type="nft-card"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleCollection;
