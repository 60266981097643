export const COLLECTION_NFT_S_SORT_SET = 'COLLECTION_NFT_S_SORT_SET';
export const COLLECTION_AUCTIONS_SORT_SET = 'COLLECTION_AUCTIONS_SORT_SET';

export const FETCH_LISTED_COLLECTION_NFT_S_SUCCESS = 'FETCH_LISTED_COLLECTION_NFT_S_SUCCESS';
export const FETCH_LISTED_COLLECTION_NFT_S_IN_PROGRESS = 'FETCH_LISTED_COLLECTION_NFT_S_IN_PROGRESS';
export const FETCH_LISTED_COLLECTION_NFT_S_ERROR = 'FETCH_LISTED_COLLECTION_NFT_S_ERROR';
export const CANCEL_FETCH_LISTED_COLLECTION_NFT_S = 'CANCEL_FETCH_LISTED_COLLECTION_NFT_S';

export const FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS = 'FETCH_NON_LISTED_COLLECTION_NFT_S_SUCCESS';
export const FETCH_NON_LISTED_COLLECTION_NFT_S_IN_PROGRESS = 'FETCH_NON_LISTED_COLLECTION_NFT_S_IN_PROGRESS';
export const FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR = 'FETCH_NON_LISTED_COLLECTION_NFT_S_ERROR';

export const FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_SUCCESS = 'FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_SUCCESS';
export const FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_IN_PROGRESS = 'FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_IN_PROGRESS';
export const FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_ERROR = 'FETCH_NON_TRANSFERABLE_COLLECTION_NFT_S_ERROR';

export const LISTED_SEARCH_SET = 'LISTED_SEARCH_SET';
export const NON_LISTED_SEARCH_SET = 'NON_LISTED_SEARCH_SET';
export const COLLECTION_AUCTION_SEARCH_SET = 'COLLECTION_AUCTION_SEARCH_SET';
export const NON_TRANSFERABLE_SEARCH_SET = 'NON_TRANSFERABLE_SEARCH_SET';

export const COLLECTION_MY_NFT_S_FETCH_SUCCESS = 'COLLECTION_MY_NFT_S_FETCH_SUCCESS';
export const COLLECTION_MY_NFT_S_FETCH_IN_PROGRESS = 'COLLECTION_MY_NFT_S_FETCH_IN_PROGRESS';
export const COLLECTION_MY_NFT_S_FETCH_ERROR = 'COLLECTION_MY_NFT_S_FETCH_ERROR';

export const FETCH_COLLECTION_AUCTIONS_SUCCESS = 'FETCH_COLLECTION_AUCTIONS_SUCCESS';
export const FETCH_COLLECTION_AUCTIONS_IN_PROGRESS = 'FETCH_COLLECTION_AUCTIONS_IN_PROGRESS';
export const FETCH_COLLECTION_AUCTIONS_ERROR = 'FETCH_COLLECTION_AUCTIONS_ERROR';
export const CANCEL_FETCH_COLLECTION_AUCTIONS = 'CANCEL_FETCH_COLLECTION_AUCTIONS';

export const COLLECTION_FILTERS_SHOW = 'COLLECTION_FILTERS_SHOW';
export const COLLECTION_FILTERS_HIDE = 'COLLECTION_FILTERS_HIDE';
export const COLLECTION_PRICE_RANGE_COIN_SET = 'COLLECTION_PRICE_RANGE_COIN_SET';
export const COLLECTION_PRICE_RANGE_SET = 'COLLECTION_PRICE_RANGE_SET';
export const COLLECTION_ON_SALE_IN_SET = 'COLLECTION_ON_SALE_IN_SET';
export const COLLECTION_ON_SALE_IN_SEARCH_SET = 'COLLECTION_ON_SALE_IN_SEARCH_SET';

export const COLLECTION_TAB_SET = 'COLLECTION_TAB_SET';
export const COLLECTIONS_VIEW_SET = 'COLLECTIONS_VIEW_SET';
export const COLLECTIONS_FILTER_SET = 'COLLECTIONS_FILTER_SET';
export const COLLECTIONS_FILTER_SEARCH_SET = 'COLLECTIONS_FILTER_SEARCH_SET';

export const COLLECTIONS_ON_SALE_IN_FILTER_SET = 'COLLECTIONS_ON_SALE_IN_FILTER_SET';
