export const getAssetType = (value) => {
    if (!value) {
        return null;
    }
    switch (value) {
    case 'application/x-shockwave-flash':
        return 'video';
    case 'application/doc':
    case 'application/ms-doc':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/pdf':
    case 'application/epub+zip':
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/rtf':
    case 'application/vnd.ms-excel':
    case 'application/xml':
        return 'document';
    default:
        break;
    }

    const array = value.split('/');

    return array[0];
};

export const getAssetTypeExtension = (value) => {
    if (!value) {
        return null;
    }

    const array = value.split('/');

    if (array && array.length && array[1]) {
        return array[1];
    }

    return null;
};

export const removeUnderScrolls = (value) => {
    return value && value.split('_').join(' ');
};

export const removeSpaces = (value) => {
    return value && value.split(' ').join('');
};

export const convertToCamelCase = (value) => {
    const object = {};
    if (Object.keys(value) && Object.keys(value).length) {
        Object.keys(value).map((val) => {
            let string = val && val.split('_');
            if (string.length > 1) {
                string.map((str, index) => {
                    if (index > 0) {
                        string[index] = str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
                    }

                    return null;
                });

                string = string.join('');
            }

            let object2 = value[val];
            if (object2 && typeof (object2) === 'object' && !Array.isArray(object2)) {
                object2 = convertToCamelCase(object2);
            }
            object[string] = object2;

            return null;
        });

        return object;
    }
};

export const MsgTypes = (value, address) => {
    if (value.module === 'ClaimNFT') {
        switch (value.type) {
        case 'BurnNft':
            return 'ITC Burn NFT';
        case 'VerifyNft':
            return 'ITC Verify NFT';
        case 'TransferNft':
            return 'ITC Transfer NFT';
        default:
            return value.type;
        }
    }

    switch (value.type) {
    case 'MsgListNFT':
        return 'Listed NFT';
    case 'MsgMintONFT':
        return 'Mint NFT';
    case 'MsgBuyNFT':
        return 'Buy NFT';
    case 'MsgDeListNFT':
        return 'Delist NFT';
    case 'MsgBurnONFT':
        return 'Burn NFT';
    case 'MsgCreateDenom':
        return 'Collections created';
    case 'MsgUpdateDenom':
        return 'Collections updated';
    case 'MsgTransferONFT':
        return 'Transferred NFT';
    case 'MsgSend':
        if (value.recipient && value.recipient === address) {
            return 'Received';
        } else {
            return 'Sent';
        }
    case 'MsgSubmitProposal':
        return 'Submit proposal';
    case 'MsgVote':
        return 'Vote';
    case 'MsgDelegate':
        return 'Delegate';
    case 'MsgCreateAuction':
        return 'Auction created';
    case 'MsgPlaceBid':
        return 'Bid placed';
    case 'ProcessBid':
        return 'Bid processed';
    case 'RemoveAuction':
        return 'Auction removed';
    case 'MsgCancelAuction':
        return 'Canceled Auction';
    case 'MsgCreateCampaign':
        return 'Campaign Created';
    case 'MsgCancelCampaign':
        return 'Campaign Stopped';
    case 'MsgClaim':
        return 'Claim Campaign';
    case 'MsgDepositCampaign':
        return 'Campaign Deposit';
    case 'MsgStreamSend':
        return 'Stream Created';
    case 'MsgStopStream':
        return 'Stream Stopped';
    case 'MsgClaimStreamedAmount':
    case 'CLAIM':
        return 'Claim Stream Amount';
    case 'STREAM_PAYMENT_TYPE_PERIODIC':
        return 'Periodic Stream Created';
    case 'STREAM_PAYMENT_TYPE_DELAYED':
        return 'Delayed Stream Created';
    case 'STREAM_PAYMENT_TYPE_CONTINUOUS':
        return 'Continuous Stream Created';
    case 'EndCampaign':
        return 'Campaign Ended';
    case 'MsgExecuteContractMintONFT':
        return 'Mint NFT From Contract';
    case 'MsgExecuteContractCreateDenom':
        return 'Collections created From Contract';
    case 'MsgExecuteContractUpdateDenom':
        return 'Collections updated From Contract';
    case 'MsgCreateMinterContractDrop':
        return 'Minter Contract Drop Created';
    case 'MsgExecuteContractMinterContractMint':
        return 'Collections created From Minter Contract';
    case 'MsgExecuteContractUpdateMinterContractDrop':
        return 'Contract updated From Minter Contract Drop';
    case 'MsgInstantiateContract':
        return 'Contract Instantiated';
    case 'MsgPurgeDenom':
        return 'Collections Purged';
    case 'MsgSaveMinterContract':
        return 'Minter Contract Saved';
    case 'MsgStoreCode':
        return 'Code Stored';
    case 'MsgTransferDenom':
        return 'Collections Transferred';
    case 'StreamCreated':
        return 'Stream Created';
    default:
        return value.type;
    }
};

export const divideNumbersLetters = (value) => {
    const patt1 = /[0-9]/g;
    const patt2 = /[a-zA-Z]/g;
    const letters = value.match(patt2);
    const digits = value.match(patt1);

    return {
        digits: digits.join(''),
        letters: letters.join(''),
    };
};

export const getWrapAddress = (string, limit, endLimit = 4) => {
    const dots = '...';

    if (string && string.length > limit) {
        string = string.substring(0, limit) + dots + string.slice(string.length - endLimit, string.length);
    }

    return string;
};
