export const FEE_GRANT_ALLOWANCE_IN_PROGRESS = 'FEE_GRANT_ALLOWANCE_IN_PROGRESS';
export const FEE_GRANT_ALLOWANCE_SUCCESS = 'FEE_GRANT_ALLOWANCE_SUCCESS';
export const FEE_GRANT_ALLOWANCE_ERROR = 'FEE_GRANT_ALLOWANCE_ERROR';

export const ACTIVE_CAMPAIGNS_FETCH_IN_PROGRESS = 'ACTIVE_CAMPAIGNS_FETCH_IN_PROGRESS';
export const ACTIVE_CAMPAIGNS_FETCH_SUCCESS = 'ACTIVE_CAMPAIGNS_FETCH_SUCCESS';
export const ACTIVE_CAMPAIGNS_FETCH_ERROR = 'ACTIVE_CAMPAIGNS_FETCH_ERROR';

export const UPCOMING_CAMPAIGNS_FETCH_IN_PROGRESS = 'UPCOMING_CAMPAIGNS_FETCH_IN_PROGRESS';
export const UPCOMING_CAMPAIGNS_FETCH_SUCCESS = 'UPCOMING_CAMPAIGNS_FETCH_SUCCESS';
export const UPCOMING_CAMPAIGNS_FETCH_ERROR = 'UPCOMING_CAMPAIGNS_FETCH_ERROR';

export const COMPLETED_CAMPAIGNS_FETCH_IN_PROGRESS = 'COMPLETED_CAMPAIGNS_FETCH_IN_PROGRESS';
export const COMPLETED_CAMPAIGNS_FETCH_SUCCESS = 'COMPLETED_CAMPAIGNS_FETCH_SUCCESS';
export const COMPLETED_CAMPAIGNS_FETCH_ERROR = 'COMPLETED_CAMPAIGNS_FETCH_ERROR';

export const CAMPAIGN_FETCH_IN_PROGRESS = 'CAMPAIGN_FETCH_IN_PROGRESS';
export const CAMPAIGN_FETCH_SUCCESS = 'CAMPAIGN_FETCH_SUCCESS';
export const CAMPAIGN_FETCH_ERROR = 'CAMPAIGN_FETCH_ERROR';

export const CAMPAIGN_LISTINGS_FETCH_IN_PROGRESS = 'CAMPAIGN_LISTINGS_FETCH_IN_PROGRESS';
export const CAMPAIGN_LISTINGS_FETCH_SUCCESS = 'CAMPAIGN_LISTINGS_FETCH_SUCCESS';
export const CAMPAIGN_LISTINGS_FETCH_ERROR = 'CAMPAIGN_LISTINGS_FETCH_ERROR';
export const CAMPAIGN_LISTINGS_SEARCH_SET = 'CAMPAIGN_LISTINGS_SEARCH_SET';
export const CAMPAIGN_LISTINGS_SORT_SET = 'CAMPAIGN_LISTINGS_SORT_SET';

export const CAMPAIGN_AUCTIONS_FETCH_IN_PROGRESS = 'CAMPAIGN_AUCTIONS_FETCH_IN_PROGRESS';
export const CAMPAIGN_AUCTIONS_FETCH_SUCCESS = 'CAMPAIGN_AUCTIONS_FETCH_SUCCESS';
export const CAMPAIGN_AUCTIONS_FETCH_ERROR = 'CAMPAIGN_AUCTIONS_FETCH_ERROR';
export const CAMPAIGN_AUCTIONS_SEARCH_SET = 'CAMPAIGN_AUCTIONS_SEARCH_SET';
export const CAMPAIGN_AUCTIONS_SORT_SET = 'CAMPAIGN_AUCTIONS_SORT_SET';

export const CAMPAIGNS_TAB_SET = 'CAMPAIGNS_TAB_SET';
export const CAMPAIGN_TAB_SET = 'CAMPAIGN_TAB_SET';
export const CAMPAIGN_NFT_TAB_SET = 'CAMPAIGN_NFT_TAB_SET';

// Filters
export const CAMPAIGN_LISTINGS_FILTERS_SHOW = 'CAMPAIGN_LISTINGS_FILTERS_SHOW';
export const CAMPAIGN_LISTINGS_FILTERS_HIDE = 'CAMPAIGN_LISTINGS_FILTERS_HIDE';
export const CAMPAIGN_LISTINGS_PRICE_RANGE_COIN_SET = 'CAMPAIGN_LISTINGS_PRICE_RANGE_COIN_SET';
export const CAMPAIGN_LISTINGS_PRICE_RANGE_SET = 'CAMPAIGN_LISTINGS_PRICE_RANGE_SET';
export const CAMPAIGN_LISTINGS_ON_SALE_IN_SET = 'CAMPAIGN_LISTINGS_ON_SALE_IN_SET';
export const CAMPAIGN_LISTINGS_ON_SALE_IN_SEARCH_SET = 'CAMPAIGN_LISTINGS_ON_SALE_IN_SEARCH_SET';
export const CAMPAIGN_LISTINGS_AUCTION_TYPE_SET = 'CAMPAIGN_LISTINGS_AUCTION_TYPE_SET';

export const CAMPAIGN_AUCTIONS_FILTERS_SHOW = 'CAMPAIGN_AUCTIONS_FILTERS_SHOW';
export const CAMPAIGN_AUCTIONS_FILTERS_HIDE = 'CAMPAIGN_AUCTIONS_FILTERS_HIDE';
export const CAMPAIGN_AUCTIONS_PRICE_RANGE_COIN_SET = 'CAMPAIGN_AUCTIONS_PRICE_RANGE_COIN_SET';
export const CAMPAIGN_AUCTIONS_PRICE_RANGE_SET = 'CAMPAIGN_AUCTIONS_PRICE_RANGE_SET';
export const CAMPAIGN_AUCTIONS_ON_SALE_IN_SET = 'CAMPAIGN_AUCTIONS_ON_SALE_IN_SET';
export const CAMPAIGN_AUCTIONS_ON_SALE_IN_SEARCH_SET = 'CAMPAIGN_AUCTIONS_ON_SALE_IN_SEARCH_SET';
export const CAMPAIGN_AUCTIONS_AUCTION_TYPE_SET = 'CAMPAIGN_AUCTIONS_AUCTION_TYPE_SET';
