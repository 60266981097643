import {
    ACCOUNT_ADDRESS_SET,
    AMOUNT_VALUE_SET,
    BULK_BURN_DIALOG_HIDE,
    BULK_BURN_DIALOG_SHOW,
    BULK_BURN_ID_SET,
    BULK_DE_LIST_DIALOG_HIDE,
    BULK_DE_LIST_DIALOG_SHOW,
    BULK_LIST_DIALOG_HIDE,
    BULK_LIST_DIALOG_SHOW,
    BULK_SELECT_ALL_SET,
    BULK_SELECT_HIDE,
    BULK_SELECT_LIST_SET,
    BULK_SELECT_SHOW,
    BULK_SELECT_TYPE_SET,
    BULK_TRANSFER_ADDRESS_SET,
    BULK_TRANSFER_DIALOG_HIDE,
    BULK_TRANSFER_DIALOG_SHOW,
    CLEAR_MY_ACCOUNT_DATA,
    DEPOSITE_DIALOG_HIDE,
    DEPOSITE_DIALOG_SHOW,
    FETCH_MY_ACCOUNT_STATS_ERROR,
    FETCH_MY_ACCOUNT_STATS_IN_PROGRESS,
    FETCH_MY_ACCOUNT_STATS_SUCCESS,
    FETCH_OWNER_COLLECTIONS_ERROR,
    FETCH_OWNER_COLLECTIONS_IN_PROGRESS,
    FETCH_OWNER_COLLECTIONS_SUCCESS,
    FETCH_OWNER_NFT_S_ERROR,
    FETCH_OWNER_NFT_S_IN_PROGRESS,
    FETCH_OWNER_NFT_S_SUCCESS,
    FETCH_USER_ACCOUNT_STATS_ERROR,
    FETCH_USER_ACCOUNT_STATS_IN_PROGRESS,
    FETCH_USER_ACCOUNT_STATS_SUCCESS,
    FETCH_USER_BALANCE_ERROR,
    FETCH_USER_BALANCE_IN_PROGRESS,
    FETCH_USER_BALANCE_SUCCESS,
    FETCH_USER_COLLECTIONS_ERROR,
    FETCH_USER_COLLECTIONS_IN_PROGRESS,
    FETCH_USER_COLLECTIONS_SUCCESS,
    FETCH_USER_NFT_S_ERROR,
    FETCH_USER_NFT_S_IN_PROGRESS,
    FETCH_USER_NFT_S_LISTED_ERROR,
    FETCH_USER_NFT_S_LISTED_IN_PROGRESS,
    FETCH_USER_NFT_S_LISTED_SUCCESS, FETCH_USER_NFT_S_NON_TRANSFERABLE_ERROR,
    FETCH_USER_NFT_S_NON_TRANSFERABLE_IN_PROGRESS, FETCH_USER_NFT_S_NON_TRANSFERABLE_SUCCESS,
    FETCH_USER_NFT_S_NOT_LISTED_ERROR, FETCH_USER_NFT_S_NOT_LISTED_IN_PROGRESS,
    FETCH_USER_NFT_S_NOT_LISTED_SUCCESS,
    FETCH_USER_NFT_S_SUCCESS,
    MY_ACCOUNT_NFT_S_VIEW_SET,
    MY_ACCOUNT_NFT_TABS_SET,
    SEND_TOKENS_DIALOG_HIDE,
    SEND_TOKENS_DIALOG_SHOW,
    TRANSFER_TOKENS_DIALOG_HIDE,
    TRANSFER_TOKENS_DIALOG_SHOW,
    WITHDRAW_DIALOG_HIDE,
    WITHDRAW_DIALOG_SHOW,
} from '../constants/myAccount';
import {
    urlMyAccountStats,
    urlOwnerCollections,
    urlOwnerNFTs,
    urlUserCollections, urlUserListed,
    urlUserNFTs, urlUserNonTransferable, urlUserNotListed,
} from '../constants/url';
import Axios from 'axios';
import { urlFetchBalance } from '../constants/restURL';

// Bulk Select
export const showBulkSelect = () => {
    return {
        type: BULK_SELECT_SHOW,
    };
};

export const hideBulkSelect = () => {
    return {
        type: BULK_SELECT_HIDE,
    };
};

export const setBulkSelectList = (value) => {
    return {
        type: BULK_SELECT_LIST_SET,
        value,
    };
};

export const showBulkListDialog = (value) => {
    return {
        type: BULK_LIST_DIALOG_SHOW,
        value,
    };
};

export const hideBulkListDialog = () => {
    return {
        type: BULK_LIST_DIALOG_HIDE,
    };
};

export const showBulkDeListDialog = (value) => {
    return {
        type: BULK_DE_LIST_DIALOG_SHOW,
        value,
    };
};

export const hideBulkDeListDialog = () => {
    return {
        type: BULK_DE_LIST_DIALOG_HIDE,
    };
};

export const showBulkTransferDialog = (value) => {
    return {
        type: BULK_TRANSFER_DIALOG_SHOW,
        value,
    };
};

export const hideBulkTransferDialog = () => {
    return {
        type: BULK_TRANSFER_DIALOG_HIDE,
    };
};

export const setBulkTransferAddress = (value) => {
    return {
        type: BULK_TRANSFER_ADDRESS_SET,
        value,
    };
};

export const showBulkBurnDialog = (value) => {
    return {
        type: BULK_BURN_DIALOG_SHOW,
        value,
    };
};

export const hideBulkBurnDialog = () => {
    return {
        type: BULK_BURN_DIALOG_HIDE,
    };
};

export const setBulkBurnID = (value) => {
    return {
        type: BULK_BURN_ID_SET,
        value,
    };
};

export const setBulkSelectType = (value) => {
    return {
        type: BULK_SELECT_TYPE_SET,
        value,
    };
};

export const setBulkSelectAll = (value) => {
    return {
        type: BULK_SELECT_ALL_SET,
        value,
    };
};
// Bulk Select

export const setMyAccountNFTsView = (value) => {
    return {
        type: MY_ACCOUNT_NFT_S_VIEW_SET,
        value,
    };
};

export const setMyAccountNFTTabs = (value) => {
    return {
        type: MY_ACCOUNT_NFT_TABS_SET,
        value,
    };
};

const fetchOwnerCollectionsSuccess = (result, count) => {
    return {
        type: FETCH_OWNER_COLLECTIONS_SUCCESS,
        result,
        count,
    };
};

const fetchOwnerCollectionsError = (message) => {
    return {
        type: FETCH_OWNER_COLLECTIONS_ERROR,
        message,
    };
};

const fetchOwnerCollectionsInProgress = () => {
    return {
        type: FETCH_OWNER_COLLECTIONS_IN_PROGRESS,
    };
};

export const fetchOwnerCollections = (address) => (dispatch) => {
    dispatch(fetchOwnerCollectionsInProgress());

    const url = urlOwnerCollections(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchOwnerCollectionsSuccess(res.data && res.data.result.list, res.data && res.data.result.count));
        }
    }).catch((error) => {
        dispatch(fetchOwnerCollectionsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchOwnerNFTsSuccess = (result, count) => {
    return {
        type: FETCH_OWNER_NFT_S_SUCCESS,
        result,
        count,
    };
};

const fetchOwnerNFTsError = (message) => {
    return {
        type: FETCH_OWNER_NFT_S_ERROR,
        message,
    };
};

const fetchOwnerNFTsInProgress = () => {
    return {
        type: FETCH_OWNER_NFT_S_IN_PROGRESS,
    };
};

export const fetchOwnerNFTs = (address) => (dispatch) => {
    dispatch(fetchOwnerNFTsInProgress());

    const url = urlOwnerNFTs(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchOwnerNFTsSuccess(res.data && res.data.result.list, res.data && res.data.result.count));
        }
    }).catch((error) => {
        dispatch(fetchOwnerNFTsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchUserCollectionsSuccess = (result, skip, limit, count) => {
    return {
        type: FETCH_USER_COLLECTIONS_SUCCESS,
        result,
        skip,
        limit,
        count,
    };
};

const fetchUserCollectionsError = (message) => {
    return {
        type: FETCH_USER_COLLECTIONS_ERROR,
        message,
    };
};

const fetchUserCollectionsInProgress = () => {
    return {
        type: FETCH_USER_COLLECTIONS_IN_PROGRESS,
    };
};

export const fetchUserCollections = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchUserCollectionsInProgress());

    const url = urlUserCollections(address, skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchUserCollectionsSuccess(res.data && res.data.result.list,
                skip, limit, res.data && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result.list, res.data && res.data.result.count);
            }
        }
    }).catch((error) => {
        dispatch(fetchUserCollectionsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
        if (cb) {
            cb(null);
        }
    });
};

const fetchUserNFTsSuccess = (result, skip, limit, count) => {
    return {
        type: FETCH_USER_NFT_S_SUCCESS,
        result,
        skip,
        limit,
        count,
    };
};

const fetchUserNFTsError = (message) => {
    return {
        type: FETCH_USER_NFT_S_ERROR,
        message,
    };
};

const fetchUserNFTsInProgress = () => {
    return {
        type: FETCH_USER_NFT_S_IN_PROGRESS,
    };
};

export const fetchUserNFTs = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchUserNFTsInProgress());

    const url = urlUserNFTs(address, skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchUserNFTsSuccess(res.data && res.data.result.list,
                skip, limit, res.data && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result.list, res.data && res.data.result.count);
            }
        }
    }).catch((error) => {
        dispatch(fetchUserNFTsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
        if (cb) {
            cb(null);
        }
    });
};

const fetchUserBalanceInProgress = () => {
    return {
        type: FETCH_USER_BALANCE_IN_PROGRESS,
    };
};

const fetchUserBalanceSuccess = (value) => {
    return {
        type: FETCH_USER_BALANCE_SUCCESS,
        value,
    };
};

const fetchUserBalanceError = (message) => {
    return {
        type: FETCH_USER_BALANCE_ERROR,
        message,
    };
};

export const fetchUserBalance = (address, cb) => (dispatch) => {
    dispatch(fetchUserBalanceInProgress());

    const url = urlFetchBalance(address);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    })
        .then((res) => {
            dispatch(fetchUserBalanceSuccess(res.data && res.data.balances));
            if (cb) {
                cb(res.data && res.data.balances);
            }
        })
        .catch((error) => {
            dispatch(fetchUserBalanceError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
            if (cb) {
                cb(null);
            }
        });
};

export const showDepositeDialog = (value) => {
    return {
        type: DEPOSITE_DIALOG_SHOW,
        value,
    };
};

export const hideDepositeDialog = () => {
    return {
        type: DEPOSITE_DIALOG_HIDE,
    };
};

export const showWithdrawDialog = (value) => {
    return {
        type: WITHDRAW_DIALOG_SHOW,
        value,
    };
};

export const hideWithdrawDialog = () => {
    return {
        type: WITHDRAW_DIALOG_HIDE,
    };
};

export const showSendTokensDialog = (value) => {
    return {
        type: SEND_TOKENS_DIALOG_SHOW,
        value,
    };
};

export const hideSendTokensDialog = () => {
    return {
        type: SEND_TOKENS_DIALOG_HIDE,
    };
};

export const showTransferTokensDialog = (value) => {
    return {
        type: TRANSFER_TOKENS_DIALOG_SHOW,
        value,
    };
};

export const hideTransferTokensDialog = () => {
    return {
        type: TRANSFER_TOKENS_DIALOG_HIDE,
    };
};

export const setAccountAddress = (value) => {
    return {
        type: ACCOUNT_ADDRESS_SET,
        value,
    };
};

export const setAmountValue = (value) => {
    return {
        type: AMOUNT_VALUE_SET,
        value,
    };
};

export const clearMyAccountData = () => {
    return {
        type: CLEAR_MY_ACCOUNT_DATA,
    };
};

const fetchMyAccountStatsSuccess = (result) => {
    return {
        type: FETCH_MY_ACCOUNT_STATS_SUCCESS,
        result,
    };
};

const fetchMyAccountStatsError = (message) => {
    return {
        type: FETCH_MY_ACCOUNT_STATS_ERROR,
        message,
    };
};

const fetchMyAccountStatsInProgress = () => {
    return {
        type: FETCH_MY_ACCOUNT_STATS_IN_PROGRESS,
    };
};

export const fetchMyAccountStats = (address) => (dispatch) => {
    dispatch(fetchMyAccountStatsInProgress());

    const STATS_URL = urlMyAccountStats(address);
    Axios.get(STATS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchMyAccountStatsSuccess(res.data && res.data.result));
        }
    }).catch((error) => {
        dispatch(fetchMyAccountStatsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchUserAccountStatsSuccess = (result) => {
    return {
        type: FETCH_USER_ACCOUNT_STATS_SUCCESS,
        result,
    };
};

const fetchUserAccountStatsError = (message) => {
    return {
        type: FETCH_USER_ACCOUNT_STATS_ERROR,
        message,
    };
};

const fetchUserAccountStatsInProgress = () => {
    return {
        type: FETCH_USER_ACCOUNT_STATS_IN_PROGRESS,
    };
};

export const fetchUserAccountStats = (address) => (dispatch) => {
    dispatch(fetchUserAccountStatsInProgress());

    const STATS_URL = urlMyAccountStats(address);
    Axios.get(STATS_URL, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchUserAccountStatsSuccess(res.data && res.data.result));
        }
    }).catch((error) => {
        dispatch(fetchUserAccountStatsError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
    });
};

const fetchUserNFTsListedSuccess = (result, skip, limit, count) => {
    return {
        type: FETCH_USER_NFT_S_LISTED_SUCCESS,
        result,
        skip,
        limit,
        count,
    };
};

const fetchUserNFTsListedError = (message) => {
    return {
        type: FETCH_USER_NFT_S_LISTED_ERROR,
        message,
    };
};

const fetchUserNFTsListedInProgress = () => {
    return {
        type: FETCH_USER_NFT_S_LISTED_IN_PROGRESS,
    };
};

export const fetchUserNFTsListed = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchUserNFTsListedInProgress());

    const url = urlUserListed(address, skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchUserNFTsListedSuccess(res.data && res.data.result.list,
                skip, limit, res.data && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result.list, res.data && res.data.result.count);
            }
        }
    }).catch((error) => {
        dispatch(fetchUserNFTsListedError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
        if (cb) {
            cb(null);
        }
    });
};

const fetchUserNFTsNotListedSuccess = (result, skip, limit, count) => {
    return {
        type: FETCH_USER_NFT_S_NOT_LISTED_SUCCESS,
        result,
        skip,
        limit,
        count,
    };
};

const fetchUserNFTsNotListedError = (message) => {
    return {
        type: FETCH_USER_NFT_S_NOT_LISTED_ERROR,
        message,
    };
};

const fetchUserNFTsNotListedInProgress = () => {
    return {
        type: FETCH_USER_NFT_S_NOT_LISTED_IN_PROGRESS,
    };
};

export const fetchUserNFTsNotListed = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchUserNFTsNotListedInProgress());

    const url = urlUserNotListed(address, skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchUserNFTsNotListedSuccess(res.data && res.data.result.list,
                skip, limit, res.data && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result.list, res.data && res.data.result.count);
            }
        }
    }).catch((error) => {
        dispatch(fetchUserNFTsNotListedError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
        if (cb) {
            cb(null);
        }
    });
};

const fetchUserNFTsNonTransferableSuccess = (result, skip, limit, count) => {
    return {
        type: FETCH_USER_NFT_S_NON_TRANSFERABLE_SUCCESS,
        result,
        skip,
        limit,
        count,
    };
};

const fetchUserNFTsNonTransferableError = (message) => {
    return {
        type: FETCH_USER_NFT_S_NON_TRANSFERABLE_ERROR,
        message,
    };
};

const fetchUserNFTsNonTransferableInProgress = () => {
    return {
        type: FETCH_USER_NFT_S_NON_TRANSFERABLE_IN_PROGRESS,
    };
};

export const fetchUserNFTsNonTransferable = (address, skip, limit, cb) => (dispatch) => {
    dispatch(fetchUserNFTsNonTransferableInProgress());

    const url = urlUserNonTransferable(address, skip, limit);
    Axios.get(url, {
        headers: {
            Accept: 'application/json, text/plain, */*',
        },
    }).then((res) => {
        if (res && res.data && res.data.result && res.data.result) {
            dispatch(fetchUserNFTsNonTransferableSuccess(res.data && res.data.result.list,
                skip, limit, res.data && res.data.result.count));
            if (cb) {
                cb(res.data && res.data.result.list, res.data && res.data.result.count);
            }
        }
    }).catch((error) => {
        dispatch(fetchUserNFTsNonTransferableError(
            error.response
                ? error.response.data.message
                : error.message,
        ));
        if (cb) {
            cb(null);
        }
    });
};
