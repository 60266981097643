import { combineReducers } from 'redux';
import {
    IBC_BALANCE_LIST_FETCH_ERROR,
    IBC_BALANCE_LIST_FETCH_IN_PROGRESS,
    IBC_BALANCE_LIST_FETCH_SUCCESS,
} from '../constants/faucet';
import { DISCONNECT_SET } from '../constants/account';

const balanceList = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case IBC_BALANCE_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case IBC_BALANCE_LIST_FETCH_SUCCESS: {
        const array = [...state.value, ...action.value];

        return {
            inProgress: false,
            value: array,
        };
    }
    case IBC_BALANCE_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case DISCONNECT_SET:
        return {
            ...state,
            value: [],
        };
    default:
        return state;
    }
};

export default combineReducers({
    balanceList,
});
