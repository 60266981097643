import React from 'react';
import * as PropTypes from 'prop-types';
import flixToken from '../../assets/tokens/flix.svg';
import atomToken from '../../assets/tokens/atom.svg';
import dvpnToken from '../../assets/tokens/dvpn.svg';
import spayToken from '../../assets/tokens/spay.svg';
import junoToken from '../../assets/tokens/juno.svg';
import aktToken from '../../assets/tokens/akash.svg';
import huahuaToken from '../../assets/tokens/chihuahua.svg';
import croToken from '../../assets/tokens/crypto.com.svg';
import gravitonToken from '../../assets/tokens/gravityBridge.svg';
import kiToken from '../../assets/tokens/KI.svg';
import likeToken from '../../assets/tokens/like.svg';
import osmoToken from '../../assets/tokens/osmosis.svg';
import rowanToken from '../../assets/tokens/sifchain.svg';
import starsToken from '../../assets/tokens/stargaze.png';
import istToken from '../../assets/tokens/ist.png';
import lunaToken from '../../assets/tokens/terra.svg';
import { connect } from 'react-redux';
import CircularProgress from '../CircularProgress';

const NetworkImages = (props) => {
    let imageIcon = null;
    if (props.ibcTokensList && props.ibcTokensList.length) {
        props.ibcTokensList.map((value) => {
            if (value && value.network && value.network.display_denom && value.image_URL &&
                (value.network.display_denom === props.name)) {
                imageIcon = value.image_URL;
            }

            return null;
        });
    }

    switch (props.name) {
    case 'AKT':
        imageIcon = aktToken;
        break;
    case 'ATOM':
    case 'ATOM/CH-0':
        imageIcon = atomToken;
        break;
    case 'BLUE':
    case 'BLUE/CH-3':
        imageIcon = dvpnToken;
        break;
    case 'CRO':
        imageIcon = croToken;
        break;
    case 'FLIX':
    case 'uflix':
        imageIcon = flixToken;
        break;
    case 'GRAVITON':
        imageIcon = gravitonToken;
        break;
    case 'HUAHUA':
        imageIcon = huahuaToken;
        break;
    case 'JUNO':
    case 'JUNOX':
        imageIcon = junoToken;
        break;
    case 'LIKE':
        imageIcon = likeToken;
        break;
    case 'OSMO':
        imageIcon = osmoToken;
        break;
    case 'ROWAN':
        imageIcon = rowanToken;
        break;
    case 'SPAY':
        imageIcon = spayToken;
        break;
    case 'STARS':
        imageIcon = starsToken;
        break;
    case 'LUNA':
    case 'USD':
        imageIcon = lunaToken;
        break;
    case 'XKI':
        imageIcon = kiToken;
        break;
    case 'IST':
        imageIcon = istToken;
        break;
    default:
        break;
    }

    if (props.ibcTokensListInProgress) {
        return <CircularProgress className="token_circular_progress"/>;
    } else {
        return props.name !== '' && imageIcon && <img alt={props.alt ? props.alt : 'icon'} src={imageIcon}/>;
    }
};

NetworkImages.propTypes = {
    ibcTokensList: PropTypes.array.isRequired,
    ibcTokensListInProgress: PropTypes.bool.isRequired,
    alt: PropTypes.string,
    name: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        ibcTokensList: state.marketplace.ibcTokensList.value,
        ibcTokensListInProgress: state.marketplace.ibcTokensList.inProgress,
    };
};

export default connect(stateToProps)(NetworkImages);
