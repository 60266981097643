import { combineReducers } from 'redux';
import {
    ACCOUNT_ADDRESS_SET,
    AMOUNT_VALUE_SET,
    BULK_BURN_DIALOG_HIDE,
    BULK_BURN_DIALOG_SHOW,
    BULK_BURN_ID_SET,
    BULK_DE_LIST_DIALOG_HIDE,
    BULK_DE_LIST_DIALOG_SHOW,
    BULK_LIST_DIALOG_HIDE,
    BULK_LIST_DIALOG_SHOW,
    BULK_SELECT_ALL_SET,
    BULK_SELECT_HIDE,
    BULK_SELECT_LIST_SET,
    BULK_SELECT_SHOW,
    BULK_SELECT_TYPE_SET,
    BULK_TRANSFER_ADDRESS_SET,
    BULK_TRANSFER_DIALOG_HIDE,
    BULK_TRANSFER_DIALOG_SHOW,
    CLEAR_MY_ACCOUNT_DATA,
    DEPOSITE_DIALOG_HIDE,
    DEPOSITE_DIALOG_SHOW,
    FETCH_MY_ACCOUNT_STATS_ERROR,
    FETCH_MY_ACCOUNT_STATS_IN_PROGRESS,
    FETCH_MY_ACCOUNT_STATS_SUCCESS,
    FETCH_OWNER_COLLECTIONS_ERROR,
    FETCH_OWNER_COLLECTIONS_IN_PROGRESS,
    FETCH_OWNER_COLLECTIONS_SUCCESS,
    FETCH_OWNER_NFT_S_ERROR,
    FETCH_OWNER_NFT_S_IN_PROGRESS,
    FETCH_OWNER_NFT_S_SUCCESS,
    FETCH_USER_ACCOUNT_STATS_ERROR,
    FETCH_USER_ACCOUNT_STATS_IN_PROGRESS,
    FETCH_USER_ACCOUNT_STATS_SUCCESS,
    FETCH_USER_BALANCE_ERROR,
    FETCH_USER_BALANCE_IN_PROGRESS,
    FETCH_USER_BALANCE_SUCCESS,
    FETCH_USER_COLLECTIONS_ERROR,
    FETCH_USER_COLLECTIONS_IN_PROGRESS,
    FETCH_USER_COLLECTIONS_SUCCESS,
    FETCH_USER_NFT_S_ERROR,
    FETCH_USER_NFT_S_IN_PROGRESS,
    FETCH_USER_NFT_S_LISTED_ERROR,
    FETCH_USER_NFT_S_LISTED_IN_PROGRESS,
    FETCH_USER_NFT_S_LISTED_SUCCESS, FETCH_USER_NFT_S_NON_TRANSFERABLE_ERROR,
    FETCH_USER_NFT_S_NON_TRANSFERABLE_IN_PROGRESS,
    FETCH_USER_NFT_S_NON_TRANSFERABLE_SUCCESS,
    FETCH_USER_NFT_S_NOT_LISTED_ERROR,
    FETCH_USER_NFT_S_NOT_LISTED_IN_PROGRESS,
    FETCH_USER_NFT_S_NOT_LISTED_SUCCESS,
    FETCH_USER_NFT_S_SUCCESS,
    MY_ACCOUNT_NFT_S_VIEW_SET,
    MY_ACCOUNT_NFT_TABS_SET,
    SEND_TOKENS_DIALOG_HIDE,
    SEND_TOKENS_DIALOG_SHOW,
    TRANSFER_TOKENS_DIALOG_HIDE,
    TRANSFER_TOKENS_DIALOG_SHOW,
    WITHDRAW_DIALOG_HIDE,
    WITHDRAW_DIALOG_SHOW,
} from '../constants/myAccount';
import { DEFAULT_LIMIT, DEFAULT_SKIP, DEFAULT_TOTAL } from '../config';
import { PLACE_BID_DIALOG_HIDE } from '../constants/auctions';

const depositeDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DEPOSITE_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DEPOSITE_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const withDrawDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case WITHDRAW_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case WITHDRAW_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const sendTokensDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SEND_TOKENS_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case SEND_TOKENS_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const transferTokensDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case TRANSFER_TOKENS_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case TRANSFER_TOKENS_DIALOG_HIDE:
        return {
            ...state,
            open: false,
        };
    default:
        return state;
    }
};

const accountAddress = (state = '', action) => {
    switch (action.type) {
    case ACCOUNT_ADDRESS_SET:
        return action.value;
    case SEND_TOKENS_DIALOG_HIDE:
        return '';
    default:
        return state;
    }
};

const amountValue = (state = null, action) => {
    switch (action.type) {
    case AMOUNT_VALUE_SET:
        return action.value;
    case DEPOSITE_DIALOG_HIDE:
    case WITHDRAW_DIALOG_HIDE:
    case PLACE_BID_DIALOG_HIDE:
        return null;
    default:
        return state;
    }
};

const userCollections = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_USER_COLLECTIONS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_COLLECTIONS_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.result,
                count: action.count,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.result],
                count: action.count,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case FETCH_USER_COLLECTIONS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_MY_ACCOUNT_DATA:
        return {
            ...state,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            count: DEFAULT_TOTAL,
        };
    default:
        return state;
    }
};

const userNFTs = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_TOTAL,
}, action) => {
    switch (action.type) {
    case FETCH_USER_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_NFT_S_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.result,
                count: action.count,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.result],
                count: action.count,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case FETCH_USER_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_MY_ACCOUNT_DATA:
        return {
            ...state,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            count: DEFAULT_TOTAL,
        };
    default:
        return state;
    }
};

const userBalance = (state = {
    inProgress: false,
    value: [],
}, action) => {
    switch (action.type) {
    case FETCH_USER_BALANCE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_BALANCE_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case FETCH_USER_BALANCE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ownerCollections = (state = {
    inProgress: false,
    result: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_OWNER_COLLECTIONS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_OWNER_COLLECTIONS_SUCCESS:
        return {
            inProgress: false,
            result: action.result,
            count: action.count,
        };
    case FETCH_OWNER_COLLECTIONS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const ownerNFTs = (state = {
    inProgress: false,
    result: [],
    count: 0,
}, action) => {
    switch (action.type) {
    case FETCH_OWNER_NFT_S_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_OWNER_NFT_S_SUCCESS:
        return {
            inProgress: false,
            result: action.result,
            count: action.count,
        };
    case FETCH_OWNER_NFT_S_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const myAccountNFTsView = (state = 'grid', action) => {
    switch (action.type) {
    case MY_ACCOUNT_NFT_S_VIEW_SET:
        return action.value;

    default:
        return state;
    }
};

const stats = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_MY_ACCOUNT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_MY_ACCOUNT_STATS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    }
    case FETCH_MY_ACCOUNT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const userStats = (state = {
    inProgress: false,
    result: {},
}, action) => {
    switch (action.type) {
    case FETCH_USER_ACCOUNT_STATS_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_ACCOUNT_STATS_SUCCESS: {
        return {
            ...state,
            inProgress: false,
            result: action.result,
        };
    }
    case FETCH_USER_ACCOUNT_STATS_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_MY_ACCOUNT_DATA:
        return {
            ...state,
            inProgress: false,
            result: {},
        };

    default:
        return state;
    }
};

const bulkSelect = (state = {
    open: false,
    value: [],
    addresses: {},
    burnIDs: '',
    bulkListDialog: false,
    bulkListDialogValue: [],
    bulkDeListDialog: false,
    bulkDeListDialogValue: [],
    bulkTransferDialog: false,
    bulkTransferDialogValue: [],
    bulkBurnDialog: false,
    bulkBurnDialogValue: [],
    type: null,
    selectAll: false,
}, action) => {
    switch (action.type) {
    case BULK_SELECT_SHOW:
        return {
            ...state,
            open: true,
        };
    case BULK_SELECT_HIDE:
        return {
            ...state,
            open: false,
        };
    case BULK_SELECT_ALL_SET:
        return {
            ...state,
            selectAll: action.value,
        };
    case BULK_SELECT_LIST_SET:
        return {
            ...state,
            value: action.value,
        };
    case BULK_SELECT_TYPE_SET:
        return {
            ...state,
            type: action.value,
        };
    case BULK_LIST_DIALOG_SHOW:
        return {
            ...state,
            bulkListDialog: true,
            bulkListDialogValue: action.value,
        };
    case BULK_LIST_DIALOG_HIDE:
        return {
            ...state,
            bulkListDialog: false,
            bulkListDialogValue: [],
        };
    case BULK_DE_LIST_DIALOG_SHOW:
        return {
            ...state,
            bulkDeListDialog: true,
            bulkDeListDialogValue: action.value,
        };
    case BULK_DE_LIST_DIALOG_HIDE:
        return {
            ...state,
            bulkDeListDialog: false,
            bulkDeListDialogValue: [],
        };
    case BULK_TRANSFER_DIALOG_SHOW:
        return {
            ...state,
            bulkTransferDialog: true,
            bulkTransferDialogValue: action.value,
        };
    case BULK_TRANSFER_DIALOG_HIDE:
        return {
            ...state,
            bulkTransferDialog: false,
            bulkTransferDialogValue: [],
            addresses: {},
        };
    case BULK_TRANSFER_ADDRESS_SET:
        return {
            ...state,
            addresses: action.value,
        };
    case BULK_BURN_DIALOG_SHOW:
        return {
            ...state,
            bulkBurnDialog: true,
            bulkBurnDialogValue: action.value,
        };
    case BULK_BURN_DIALOG_HIDE:
        return {
            ...state,
            bulkBurnDialog: false,
            bulkBurnDialogValue: [],
            burnIDs: '',
        };
    case BULK_BURN_ID_SET:
        return {
            ...state,
            burnIDs: action.value,
        };

    default:
        return state;
    }
};

const myAccountNFTTabs = (state = 'listed', action) => {
    switch (action.type) {
    case MY_ACCOUNT_NFT_TABS_SET:
        return action.value;

    default:
        return state;
    }
};

const userNFTsListed = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: null,
}, action) => {
    switch (action.type) {
    case FETCH_USER_NFT_S_LISTED_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_NFT_S_LISTED_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.result,
                count: action.count,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.result],
                count: action.count,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case FETCH_USER_NFT_S_LISTED_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_MY_ACCOUNT_DATA:
        return {
            ...state,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            count: null,
        };
    default:
        return state;
    }
};

const userNFTsNotListed = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: null,
}, action) => {
    switch (action.type) {
    case FETCH_USER_NFT_S_NOT_LISTED_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_NFT_S_NOT_LISTED_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.result,
                count: action.count,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.result],
                count: action.count,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case FETCH_USER_NFT_S_NOT_LISTED_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_MY_ACCOUNT_DATA:
        return {
            ...state,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            count: null,
        };
    default:
        return state;
    }
};

const userNFTsNonTransferable = (state = {
    inProgress: false,
    result: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: null,
}, action) => {
    switch (action.type) {
    case FETCH_USER_NFT_S_NON_TRANSFERABLE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case FETCH_USER_NFT_S_NON_TRANSFERABLE_SUCCESS:
        if (action.skip === DEFAULT_SKIP) {
            return {
                ...state,
                inProgress: false,
                result: action.result,
                count: action.count,
                skip: action.limit - DEFAULT_LIMIT,
                limit: DEFAULT_LIMIT,
            };
        } else {
            return {
                ...state,
                inProgress: false,
                result: [...state.result, ...action.result],
                count: action.count,
                skip: action.skip,
                limit: action.limit,
            };
        }
    case FETCH_USER_NFT_S_NON_TRANSFERABLE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    case CLEAR_MY_ACCOUNT_DATA:
        return {
            ...state,
            result: [],
            skip: DEFAULT_SKIP,
            limit: DEFAULT_LIMIT,
            count: null,
        };
    default:
        return state;
    }
};

export default combineReducers({
    depositeDialog,
    withDrawDialog,
    sendTokensDialog,
    transferTokensDialog,
    accountAddress,
    amountValue,

    userCollections,
    userNFTs,
    ownerCollections,
    ownerNFTs,
    userBalance,

    myAccountNFTsView,
    stats,
    userStats,

    bulkSelect,
    myAccountNFTTabs,
    userNFTsListed,
    userNFTsNotListed,
    userNFTsNonTransferable,
});
