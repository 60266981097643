export const FETCH_OWNER_COLLECTIONS_SUCCESS = 'FETCH_OWNER_COLLECTIONS_SUCCESS';
export const FETCH_OWNER_COLLECTIONS_IN_PROGRESS = 'FETCH_OWNER_COLLECTIONS_IN_PROGRESS';
export const FETCH_OWNER_COLLECTIONS_ERROR = 'FETCH_OWNER_COLLECTIONS_ERROR';

export const FETCH_OWNER_NFT_S_SUCCESS = 'FETCH_OWNER_NFT_S_SUCCESS';
export const FETCH_OWNER_NFT_S_IN_PROGRESS = 'FETCH_OWNER_NFT_S_IN_PROGRESS';
export const FETCH_OWNER_NFT_S_ERROR = 'FETCH_OWNER_NFT_S_ERROR';

export const FETCH_USER_COLLECTIONS_SUCCESS = 'FETCH_USER_COLLECTIONS_SUCCESS';
export const FETCH_USER_COLLECTIONS_IN_PROGRESS = 'FETCH_USER_COLLECTIONS_IN_PROGRESS';
export const FETCH_USER_COLLECTIONS_ERROR = 'FETCH_USER_COLLECTIONS_ERROR';

export const FETCH_USER_NFT_S_SUCCESS = 'FETCH_USER_NFT_S_SUCCESS';
export const FETCH_USER_NFT_S_IN_PROGRESS = 'FETCH_USER_NFT_S_IN_PROGRESS';
export const FETCH_USER_NFT_S_ERROR = 'FETCH_USER_NFT_S_ERROR';

export const FETCH_USER_BALANCE_SUCCESS = 'FETCH_USER_BALANCE_SUCCESS';
export const FETCH_USER_BALANCE_IN_PROGRESS = 'FETCH_USER_BALANCE_IN_PROGRESS';
export const FETCH_USER_BALANCE_ERROR = 'FETCH_USER_BALANCE_ERROR';

export const DEPOSITE_DIALOG_SHOW = 'DEPOSITE_DIALOG_SHOW';
export const DEPOSITE_DIALOG_HIDE = 'DEPOSITE_DIALOG_HIDE';

export const WITHDRAW_DIALOG_SHOW = 'WITHDRAW_DIALOG_SHOW';
export const WITHDRAW_DIALOG_HIDE = 'WITHDRAW_DIALOG_HIDE';

export const SEND_TOKENS_DIALOG_SHOW = 'SEND_TOKENS_DIALOG_SHOW';
export const SEND_TOKENS_DIALOG_HIDE = 'SEND_TOKENS_DIALOG_HIDE';

export const TRANSFER_TOKENS_DIALOG_SHOW = 'TRANSFER_TOKENS_DIALOG_SHOW';
export const TRANSFER_TOKENS_DIALOG_HIDE = 'TRANSFER_TOKENS_DIALOG_HIDE';

export const ACCOUNT_ADDRESS_SET = 'ACCOUNT_ADDRESS_SET';
export const AMOUNT_VALUE_SET = 'AMOUNT_VALUE_SET';

export const CLEAR_MY_ACCOUNT_DATA = 'CLEAR_MY_ACCOUNT_DATA';
export const MY_ACCOUNT_NFT_S_VIEW_SET = 'MY_ACCOUNT_NFT_S_VIEW_SET';

export const FETCH_MY_ACCOUNT_STATS_IN_PROGRESS = 'FETCH_MY_ACCOUNT_STATS_IN_PROGRESS';
export const FETCH_MY_ACCOUNT_STATS_SUCCESS = 'FETCH_MY_ACCOUNT_STATS_SUCCESS';
export const FETCH_MY_ACCOUNT_STATS_ERROR = 'FETCH_MY_ACCOUNT_STATS_ERROR';

export const FETCH_USER_ACCOUNT_STATS_IN_PROGRESS = 'FETCH_USER_ACCOUNT_STATS_IN_PROGRESS';
export const FETCH_USER_ACCOUNT_STATS_SUCCESS = 'FETCH_USER_ACCOUNT_STATS_SUCCESS';
export const FETCH_USER_ACCOUNT_STATS_ERROR = 'FETCH_USER_ACCOUNT_STATS_ERROR';

export const BULK_SELECT_SHOW = 'BULK_SELECT_SHOW';
export const BULK_SELECT_HIDE = 'BULK_SELECT_HIDE';
export const BULK_SELECT_LIST_SET = 'BULK_SELECT_LIST_SET';
export const BULK_LIST_DIALOG_SHOW = 'BULK_LIST_DIALOG_SHOW';
export const BULK_LIST_DIALOG_HIDE = 'BULK_LIST_DIALOG_HIDE';
export const BULK_DE_LIST_DIALOG_SHOW = 'BULK_DE_LIST_DIALOG_SHOW';
export const BULK_DE_LIST_DIALOG_HIDE = 'BULK_DE_LIST_DIALOG_HIDE';
export const BULK_TRANSFER_DIALOG_SHOW = 'BULK_TRANSFER_DIALOG_SHOW';
export const BULK_TRANSFER_DIALOG_HIDE = 'BULK_TRANSFER_DIALOG_HIDE';
export const BULK_TRANSFER_ADDRESS_SET = 'BULK_TRANSFER_ADDRESS_SET';
export const BULK_BURN_DIALOG_SHOW = 'BULK_BURN_DIALOG_SHOW';
export const BULK_BURN_DIALOG_HIDE = 'BULK_BURN_DIALOG_HIDE';
export const BULK_BURN_ID_SET = 'BULK_BURN_ID_SET';
export const BULK_SELECT_TYPE_SET = 'BULK_SELECT_TYPE_SET';
export const BULK_SELECT_ALL_SET = 'BULK_SELECT_ALL_SET';

export const MY_ACCOUNT_NFT_TABS_SET = 'MY_ACCOUNT_NFT_TABS_SET';

export const FETCH_USER_NFT_S_LISTED_SUCCESS = 'FETCH_USER_NFT_S_LISTED_SUCCESS';
export const FETCH_USER_NFT_S_LISTED_IN_PROGRESS = 'FETCH_USER_NFT_S_LISTED_IN_PROGRESS';
export const FETCH_USER_NFT_S_LISTED_ERROR = 'FETCH_USER_NFT_S_LISTED_ERROR';

export const FETCH_USER_NFT_S_NOT_LISTED_SUCCESS = 'FETCH_USER_NFT_S_NOT_LISTED_SUCCESS';
export const FETCH_USER_NFT_S_NOT_LISTED_IN_PROGRESS = 'FETCH_USER_NFT_S_NOT_LISTED_IN_PROGRESS';
export const FETCH_USER_NFT_S_NOT_LISTED_ERROR = 'FETCH_USER_NFT_S_NOT_LISTED_ERROR';

export const FETCH_USER_NFT_S_NON_TRANSFERABLE_SUCCESS = 'FETCH_USER_NFT_S_NON_TRANSFERABLE_SUCCESS';
export const FETCH_USER_NFT_S_NON_TRANSFERABLE_IN_PROGRESS = 'FETCH_USER_NFT_S_NON_TRANSFERABLE_IN_PROGRESS';
export const FETCH_USER_NFT_S_NON_TRANSFERABLE_ERROR = 'FETCH_USER_NFT_S_NON_TRANSFERABLE_ERROR';
