import React from 'react';
import LeftSection from '../../containers/HomePage/Welcome/LeftSection';
import { Box, Button, IconButton, MobileStepper } from '@mui/material';
import variables from '../../utils/variables';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FeaturedNFTSkeletonLoader from '../SkeletonLoader/FeaturedNFTSkeletonLoader';
import { ReactComponent as ExploreMore } from '../../assets/right-arrow.svg';
import Tabs from '../../containers/HomePage/Launchpad/Tabs';
import AuctionTabs from '../../containers/HomePage/Auctions/Tabs';
import SkeletonLoader from '../SkeletonLoader';
import '../../containers/HomePage/index.css';
import '../../containers/HomePage/Auctions/index.css';
import '../../containers/HomePage/CollectNow/index.css';
import '../../containers/HomePage/Explore/index.css';
import '../../containers/HomePage/MintManage/index.css';
import { ReactComponent as LaunchPadIcon } from '../../assets/home/launchpad.svg';
import { ReactComponent as ListingsIcon } from '../../assets/home/listings.svg';
import { ReactComponent as AuctionsIcon } from '../../assets/home/auctions.svg';
import { ReactComponent as CollectionsIcon } from '../../assets/home/collections.svg';
import { ReactComponent as RightArrow } from '../../assets/home/rightArrow.svg';
import twitterIcon from '../../assets/social/twitter.svg';
import telegramIcon from '../../assets/social/telegram.svg';
import discordIcon from '../../assets/social/discord.svg';
import githubIcon from '../../assets/social/github.svg';
import websiteIcon from '../../assets/social/website.svg';
import youtubeIcon from '../../assets/social/youtube.svg';

const Home = (props) => {
    return (
        <div className="home_page scroll_bar">
            <div className="welcome_page">
                <span className="circle1"/>
                <LeftSection/>
                <div className="right_section">
                    <Box
                        className="carousel_page"
                        sx={{
                            maxWidth: 440,
                            flexGrow: 1,
                        }}>
                        <FeaturedNFTSkeletonLoader/>
                        <div className="slider_info">
                            <h2>{variables[props.lang]['featured_collection']}</h2>
                        </div>
                        <MobileStepper
                            activeStep={0}
                            className="active_stepper0 stepper"
                            position="static"
                            steps={4}/>
                    </Box>
                </div>
                <span className="circle2"/>
            </div>
            <div className="launchpad_page">
                <div className="launchpad_page_header header">
                    <h2>{variables[props.lang]['launchpad_collections']}</h2>
                    <div className="explore_more">
                        <Button>
                            <p>{variables[props.lang]['explore_more']}</p>
                            <ExploreMore/>
                        </Button>
                    </div>
                </div>
                <Tabs/>
                <div className="lists launchpad_list">
                    <SkeletonLoader type="launchpad-home"/>
                </div>
            </div>
            <div className="auctions_page">
                <div className="auctions_page_header">
                    <h2>{variables[props.lang].auctions}</h2>
                    <div className="explore_more">
                        <Button>
                            <p>{variables[props.lang]['explore_more']}</p>
                            <ExploreMore/>
                        </Button>
                    </div>
                </div>
                <AuctionTabs/>
                <div className="lists">
                    <SkeletonLoader type="nft-card"/>
                </div>
            </div>
            <div className="collect_now_page">
                <div className="collect_now_header header">
                    <h2>{variables[props.lang]['collect_now']}</h2>
                    <div className="explore_more">
                        <Button>
                            <p>{variables[props.lang]['explore_more']}</p>
                            <ExploreMore/>
                        </Button>
                    </div>
                </div>
                <div className="lists">
                    <SkeletonLoader type="nft-card"/>
                </div>
            </div>
            <div className="request_mint_access">
                <div className="section1">
                    <h2>{variables[props.lang]['request_mint_access']}</h2>
                    <p>{variables[props.lang]['request_mint_access_info']}</p>
                </div>
                <div className="section2">
                    <Button>
                        <p>{variables[props.lang]['get_in_touch']}</p>
                    </Button>
                </div>
            </div>
            <div className="explore_page">
                <h2>{variables[props.lang]['do_more_with_your_nfts']}</h2>
                <div>
                    <Button>
                        <LaunchPadIcon/>
                        <p>{variables[props.lang]['explore_launchpad_text']}</p>
                    </Button>
                    <Button>
                        <ListingsIcon/>
                        <p>{variables[props.lang]['explore_nft_listings']}</p>
                    </Button>
                    <Button>
                        <AuctionsIcon/>
                        <p>{variables[props.lang]['explore_nft_auctions']}</p>
                    </Button>
                    <Button>
                        <CollectionsIcon/>
                        <p>{variables[props.lang]['explore_collections']}</p>
                    </Button>
                </div>
            </div>
            <div className="mint_manage_page">
                <h2>{variables[props.lang]['mint_manage']}</h2>
                <div className="get_in_touch">
                    <Button>
                        <p>{variables[props.lang]['get_in_touch']}</p>
                        <RightArrow/>
                    </Button>
                </div>
                <div className="social_icons">
                    <IconButton className="twitter">
                        <img alt="twitter" height="30" src={twitterIcon} width="30"/>
                    </IconButton>
                    <IconButton className="telegram">
                        <img alt="telegram" height="30" src={telegramIcon} width="30"/>
                    </IconButton>
                    <IconButton className="discord">
                        <img alt="discord" height="30" src={discordIcon} width="30"/>
                    </IconButton>
                    <IconButton className="github">
                        <img alt="github" height="30" src={githubIcon} width="30"/>
                    </IconButton>
                    <IconButton className="website">
                        <img alt="website" height="30" src={websiteIcon} width="30"/>
                    </IconButton>
                    <IconButton className="youtube">
                        <img alt="youtube" height="30" src={youtubeIcon} width="30"/>
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

Home.propTypes = {
    lang: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
    };
};

export default connect(stateToProps)(Home);
