import React from 'react';
import './index.css';
import '../../containers/HomePage/Launchpad/index.css';
import Skeleton from '@mui/material/Skeleton';

const LaunchpadSkeletonLoader = () => {
    return (
        <div className="list_details">
            <div className="collection_image">
                <Skeleton
                    animation="wave"
                    className="list_section2 skeleton_loader"
                    variant="rectangular"/>
            </div>
            <div className="section1">
                <div className="row1">
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={26}
                        width="100%"/>
                </div>
                <div className="row2 row3">
                    <Skeleton
                        animation="wave"
                        className="skeleton_loader"
                        height={43}
                        width="100%"/>
                </div>
            </div>
        </div>
    );
};

export default LaunchpadSkeletonLoader;
