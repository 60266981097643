import {
    MY_NFT_S_SEARCH_SET,
    SINGLE_COLLECTION_FILTER_SET,
    SINGLE_COLLECTION_NFT_S_VIEW,
    SINGLE_COLLECTION_TAB_SET,
} from '../constants/singleCollection';
import { combineReducers } from 'redux';

const singleCollectionTab = (state = {
    value: 'collectNow',
}, action) => {
    if (action.type === SINGLE_COLLECTION_TAB_SET) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

const singleCollectionFilter = (state = {
    value: false,
}, action) => {
    if (action.type === SINGLE_COLLECTION_FILTER_SET) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

const singleCollectionView = (state = {
    value: 'grid',
}, action) => {
    if (action.type === SINGLE_COLLECTION_NFT_S_VIEW) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

const myNFTsSearch = (state = {
    value: '',
}, action) => {
    if (action.type === MY_NFT_S_SEARCH_SET) {
        return {
            ...state,
            value: action.value,
        };
    }

    return state;
};

export default combineReducers({
    singleCollectionTab,
    singleCollectionFilter,
    singleCollectionView,
    myNFTsSearch,
});
